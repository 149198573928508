// ItemPlaceholder.jsx
import React from 'react';
import PropTypes from 'prop-types';

const Skeleton = ({ width = '100%', height = '1rem', className = '', style = {} }) => (
    <div
        className={`bg-gray-200 rounded animate-pulse ${className}`}
        style={{ width, height, ...style }}
    />
);

const ItemPlaceholder = ({
                             config = {
                                 showImage: true,
                                 imageHeight: 300,
                                 priceCards: 2,
                                 technologies: 3,
                                 categories: 3,
                                 features: 3,
                                 showFileSize: true,
                                 showDemo: true,
                                 tabsCount: 4,
                                 contentLines: 4
                             }
                         }) => {
    return (
        <div className="container content-space-t-4 content-space-b-lg-2">
            <div className="row">
                {/* Sidebar Placeholder */}
                <div className="col-md-4 col-lg-3 mb-9 mb-md-0">
                    <div className="pe-lg-2">
                        {/* Image Placeholder */}
                        {config.showImage && (
                            <div className="card card-body p-5 mb-5">
                                <Skeleton height={`${config.imageHeight}px`}/>
                            </div>
                        )}

                        {/* Price Cards Placeholder */}
                        {Array.from({ length: config.priceCards }).map((_, idx) => (
                            <div key={`price-${idx}`} className="card card-sm card-bordered shadow-none mb-3">
                                <div className="card-body">
                                    <Skeleton height="1.5rem" width="50%" className="mb-2"/>
                                    <Skeleton height="1rem" width="75%" className="mb-3"/>
                                    <Skeleton height="2.5rem" width="100%"/>
                                </div>
                            </div>
                        ))}

                        {/* Technologies Placeholder */}
                        <div className="mb-4">
                            <Skeleton width="120px" height="1.25rem" className="mb-3"/>
                            <div className="d-flex flex-wrap gap-2">
                                {Array.from({ length: config.technologies }).map((_, idx) => (
                                    <Skeleton
                                        key={`tech-${idx}`}
                                        width="80px"
                                        height="1.5rem"
                                        className="rounded-pill"
                                    />
                                ))}
                            </div>
                        </div>

                        {/* Categories Placeholder */}
                        <div className="mb-4">
                            <Skeleton width="120px" height="1.25rem" className="mb-3"/>
                            <div className="d-flex flex-wrap gap-2">
                                {Array.from({ length: config.categories }).map((_, idx) => (
                                    <Skeleton
                                        key={`cat-${idx}`}
                                        width="100px"
                                        height="1.5rem"
                                        className="rounded-pill"
                                    />
                                ))}
                            </div>
                        </div>

                        {/* Features List Placeholder */}
                        <div className="mb-4">
                            <Skeleton width="120px" height="1.25rem" className="mb-3"/>
                            {Array.from({ length: config.features }).map((_, idx) => (
                                <div key={`feature-${idx}`} className="d-flex align-items-center mb-2">
                                    <Skeleton width="16px" height="16px" className="rounded-circle me-2"/>
                                    <Skeleton width="80%" height="1rem"/>
                                </div>
                            ))}
                        </div>

                        {/* File Size Placeholder */}
                        {config.showFileSize && (
                            <div className="card card-sm card-bordered border-primary text-center mb-4">
                                <div className="card-body">
                                    <Skeleton
                                        width="40px"
                                        height="40px"
                                        className="rounded-circle mx-auto mb-2"
                                    />
                                    <Skeleton width="60%" height="1.25rem" className="mx-auto"/>
                                </div>
                            </div>
                        )}

                        {/* Demo Access Placeholder */}
                        {config.showDemo && (
                            <div className="mb-4">
                                <Skeleton width="120px" height="1.25rem" className="mb-3"/>
                                <Skeleton width="100%" height="2rem" className="mb-2"/>
                                <Skeleton width="100%" height="2rem"/>
                            </div>
                        )}
                    </div>
                </div>

                {/* Main Content Placeholder */}
                <div className="col-md-8 col-lg-9">
                    <div className="ps-lg-2">
                        {/* Title and Description */}
                        <div className="mb-5">
                            <Skeleton width="75%" height="2rem" className="mb-3"/>
                            <Skeleton width="100%" className="mb-2"/>
                            <Skeleton width="90%"/>
                        </div>

                        {/* Tabs Placeholder */}
                        <div className="nav nav-segment nav-fill mb-7">
                            {Array.from({ length: config.tabsCount }).map((_, idx) => (
                                <div key={`tab-${idx}`} className="nav-item">
                                    <Skeleton
                                        width="100px"
                                        height="40px"
                                        style={{ margin: '0 auto' }}
                                    />
                                </div>
                            ))}
                        </div>

                        {/* Content Area Placeholder */}
                        <div className="tab-content">
                            {Array.from({ length: config.contentLines }).map((_, idx) => (
                                <Skeleton
                                    key={`content-${idx}`}
                                    width={`${100 - (idx * 5)}%`}
                                    className="mb-2"
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ItemPlaceholder.propTypes = {
    config: PropTypes.shape({
        showImage: PropTypes.bool,
        imageHeight: PropTypes.number,
        priceCards: PropTypes.number,
        technologies: PropTypes.number,
        categories: PropTypes.number,
        features: PropTypes.number,
        showFileSize: PropTypes.bool,
        showDemo: PropTypes.bool,
        tabsCount: PropTypes.number,
        contentLines: PropTypes.number
    })
};

export default ItemPlaceholder;