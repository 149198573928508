import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function ForgotPassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí iría la lógica para enviar el correo de recuperación
        setIsSubmitted(true);
    };

    if (isSubmitted) {
        return (
            <div className="text-center">
                <div className="mb-4">
                    <i className="bi-envelope-check display-4 text-primary"></i>
                </div>
                <h1 className="h3">{t('pages.auth.forgot.success.title')}</h1>
                <p>{t('pages.auth.forgot.success.description', { email })}</p>
                <div className="mt-4">
                    <Link to="/login" className="btn btn-primary">
                        {t('pages.auth.forgot.backToLogin')}
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="text-center mb-5 mb-md-7">
                <h1 className="h2">{t('pages.auth.forgot.title')}</h1>
                <p>{t('pages.auth.forgot.description')}</p>
            </div>

            <form className="js-validate needs-validation" noValidate onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label" htmlFor="forgotEmail">
                        {t('pages.auth.forgot.email.label')}
                    </label>
                    <input
                        type="email"
                        className="form-control form-control-lg"
                        name="email"
                        id="forgotEmail"
                        placeholder={t('pages.auth.forgot.email.placeholder')}
                        aria-label={t('pages.auth.forgot.email.placeholder')}
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="invalid-feedback">
                        {t('pages.auth.forgot.email.error')}
                    </span>
                </div>

                <div className="d-grid gap-3">
                    <button type="submit" className="btn btn-primary btn-lg">
                        {t('pages.auth.forgot.submit')}
                    </button>
                    <Link to="/login" className="btn btn-link">
                        {t('pages.auth.forgot.backToLogin')}
                    </Link>
                </div>
            </form>

            <div className="w-md-85 text-lg-center mx-lg-auto mt-lg-10">
                <p className="text-primary small">
                    &copy; Wecodex Solutions | <Link to='/terms-and-conditions'>{t('layout.footer.copyright')}</Link>
                </p>
            </div>
        </div>
    );
}

export default ForgotPassword;