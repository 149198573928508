import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewsletterSubscribe from "../../components/widgets/NewsletterSubscribe";
import Head from "../../components/layout/Head";


const App = () => {
    const { t } = useTranslation();
    const [slideIndex, setSlideIndex] = useState(1);
    const [projectTitle, setProjectTitle] = useState('');

    const projects = [
        {
            title: "App de Rastreo GPS",
            image: "/assets/img/others/orbytrace.webp"
        },
        {
            title: "App de Cupones y Servicios",
            image: "/assets/img/others/juventud_san_pedro.webp"
        },
        {
            title: "App para Monitoreo GPS",
            image: "/assets/img/others/monitoreo_gps.webp"
        }
    ];

    useEffect(() => {
        setProjectTitle(projects[slideIndex - 1].title);

        const interval = setInterval(() => {
            plusSlides(1);
        }, 5000);

        return () => clearInterval(interval);
    }, [slideIndex]);

    const plusSlides = (n) => {
        let newIndex = slideIndex + n;
        if (newIndex > projects.length) newIndex = 1;
        if (newIndex < 1) newIndex = projects.length;
        setSlideIndex(newIndex);
    };

    return (
        <>

            {/* Head Section */}
            <Head pagePath="services.app" />


            {/* Hero Section */}
            <div className="gradient-y-sm-primary position-relative zi-2 overflow-hidden">
                <div className="container position-relative content-space-t-4 content-space-t-lg-4 content-space-b-1">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <h3 className="display-6 mb-3">{t('pages.services.app.hero.title', 'Desarrollo de Aplicaciones Móviles')}</h3>
                        <p>{t('pages.services.app.hero.description', 'Creamos aplicaciones móviles nativas y multiplataforma que transforman tu visión en realidad.')}</p>
                    </div>
                </div>

            </div>

            {/* Comparison Section */}
            <div className="container content-space-1">
                <div className="row align-items-center">
                    <div className="col-md-6 mb-7 mb-md-0">
                        <div className="w-md-75 mb-5">
                            <h2>{t('pages.services.app.comparison.native.title', 'Apps Nativas')}</h2>
                            <p>{t('pages.services.app.comparison.native.subtitle', 'Rendimiento excepcional y experiencia de usuario premium')}</p>
                        </div>

                        <div className="d-grid gap-3">
                            <div className="d-flex">
                                <span className="svg-icon text-primary">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                <div className="ms-3">
                                    <h6>{t('pages.services.app.comparison.native.feature1.title', 'Alto Rendimiento')}</h6>
                                    <p>{t('pages.services.app.comparison.native.feature1.description', 'Acceso directo a funcionalidades del dispositivo y optimización nativa.')}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <span className="svg-icon text-primary">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                <div className="ms-3">
                                    <h6>{t('pages.services.app.comparison.native.feature2.title', 'UX Premium')}</h6>
                                    <p>{t('pages.services.app.comparison.native.feature2.description', 'Interfaces fluidas y responsivas que siguen las guías de diseño de cada plataforma.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="w-md-75 mb-5">
                            <h2>{t('pages.services.app.comparison.hybrid.title', 'Apps Híbridas')}</h2>
                            <p>{t('pages.services.app.comparison.hybrid.subtitle', 'Desarrollo eficiente para múltiples plataformas')}</p>
                        </div>

                        <div className="d-grid gap-3">
                            <div className="d-flex">
                                <span className="svg-icon text-primary">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                <div className="ms-3">
                                    <h6>{t('pages.services.app.comparison.hybrid.feature1.title', 'Multiplataforma')}</h6>
                                    <p>{t('pages.services.app.comparison.hybrid.feature1.description', 'Un solo código para iOS y Android, reduciendo costos y tiempo de desarrollo.')}</p>
                                </div>
                            </div>

                            <div className="d-flex">
                                <span className="svg-icon text-primary">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                <div className="ms-3">
                                    <h6>{t('pages.services.app.comparison.hybrid.feature2.title', 'Actualizaciones Rápidas')}</h6>
                                    <p>{t('pages.services.app.comparison.hybrid.feature2.description', 'Facilidad de mantenimiento y despliegue de actualizaciones simultáneas.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Technologies Section */}
            <div className="container content-space-2 content-space-lg-3">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <h2>{t('pages.services.app.technologies.title', 'Tecnologías que Utilizamos')}</h2>
                    <p>{t('pages.services.app.technologies.description', 'Herramientas de última generación para crear aplicaciones excepcionales')}</p>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src="/assets/img/others/apps-comparison.png"  className="img-thumbnail img-fluid" alt="Tecnologías móviles" />
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="container content-space-b-2 content-space-b-lg-3">
                <div className="w-lg-75 mx-lg-auto">
                    <div className="d-grid gap-4">
                        <div className="card card-transition">
                            <div className="card-body">
                                <h4 className="card-title">{t('pages.services.app.faq.native.title', '¿Cuándo elegir desarrollo nativo?')}</h4>
                                <p className="card-text">{t('pages.services.app.faq.native.description', 'El desarrollo nativo es ideal para aplicaciones que requieren máximo rendimiento, acceso profundo a características del dispositivo, o una experiencia de usuario premium específica de la plataforma. Perfectas para apps de gaming, realidad aumentada o procesamiento intensivo.')}</p>
                            </div>
                        </div>

                        <div className="card card-transition">
                            <div className="card-body">
                                <h4 className="card-title">{t('pages.services.app.faq.hybrid.title', '¿Cuándo elegir desarrollo híbrido?')}</h4>
                                <p className="card-text">{t('pages.services.app.faq.hybrid.description', 'Las aplicaciones híbridas son la mejor opción cuando necesitas llegar rápidamente a múltiples plataformas, tienes un presupuesto limitado, o tu app no requiere funcionalidades nativas complejas. Ideal para apps empresariales, comercio electrónico o contenido informativo.')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Portfolio Section with Phone Device */}
            <div className="container py-5">
                <div className="tab-content" id="featuresTabContent">
                    <div className="tab-pane fade show active" id="featuresOne" role="tabpanel" aria-labelledby="featuresOne-tab">
                        <div className="device">
                            <div className="notch"></div>
                            <div className="button-right"></div>
                            <div className="button-left"></div>
                            <div className="screen">
                                <div className="app-title" id="project-name">{projectTitle}</div>
                                <div className="slideshow-container">
                                    {projects.map((project, index) => (
                                        <div
                                            key={index}
                                            className="device-browser-img"
                                            style={{
                                                display: index + 1 === slideIndex ? 'block' : 'none'
                                            }}
                                        >
                                            <img
                                                src={project.image}
                                                alt={project.title}
                                                style={{ width: '100%' }}
                                                data-title={project.title}
                                            />
                                        </div>
                                    ))}
                                    <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
                                    <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="container content-space-t-2 content-space-lg-2">
                <div className="w-lg-75 mx-lg-auto">
                    <div className="card card-sm overflow-hidden">
                        <div className="card-body">
                            <div className="row justify-content-md-start align-items-md-center text-center text-md-start">
                                <div className="col-md offset-md-3 mb-3 mb-md-0">
                                    <h4 className="card-title">{t('pages.services.app.cta.question', '¿Tienes una idea o algo en mente?')}</h4>
                                </div>
                                <div className="col-md-auto">
                                    <Link className="btn btn-primary btn-transition" to="/contact">
                                        {t('pages.services.app.cta.button', '¡Contáctanos ahora!')}
                                    </Link>
                                </div>
                            </div>

                            <figure className="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
                                <img
                                    className="card-img"
                                    src="/assets/img/icons/idea.png"
                                    style={{width:'70px', textAlign: 'center'}}
                                    alt="Idea Icon"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>


            <NewsletterSubscribe />
        </>
    );
};

export default App;