import React from 'react';
import {useTranslation} from 'react-i18next';
import NewsletterSubscribe from "../components/widgets/NewsletterSubscribe";
import {Helmet} from "react-helmet-async";
import Head from "../components/layout/Head";



const Contact = () => {
    const {t} = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí iría la lógica para enviar el formulario
    };

    return (
        <>

            <Head pagePath="contact" />

            {/* Hero Section */}
            <div className="gradient-y-sm-primary position-relative zi-2 overflow-hidden">
                <div className="container position-relative content-space-t-4 content-space-t-lg-4 content-space-b-1">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-1 mb-md-1">
                        <h3 className="display-6 mb-3">{t('pages.contact.hero.title', 'Contactanos')}</h3>
                    </div>
                </div>
            </div>

            <div className="container content-space-1 content-space-lg-1">
                <div className="mx-auto" style={{maxWidth: '35rem'}}>
                    {/* Card */}
                    <div className="card">
                        <div className="card-body">
                            {/* Heading */}
                            <div className="text-center mb-5 mb-md-9">
                                <h2>{t('pages.contact.you_need_help_title')}</h2>
                                <p>{t('pages.contact.you_need_help_subtitle')}</p>
                            </div>
                            {/* End Heading */}

                            {/* Form */}
                            <form onSubmit={handleSubmit}>
                                <div className="row gx-3">
                                    <div className="col-sm-6">
                                        {/* First Name */}
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="contactFormFirstName">
                                                {t('pages.contact.form.firstName.label')}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                name="firstName"
                                                id="contactFormFirstName"
                                                placeholder={t('pages.contact.form.firstName.placeholder')}
                                                aria-label={t('pages.contact.form.firstName.placeholder')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        {/* Last Name */}
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="contactFormLastName">
                                                {t('pages.contact.form.lastName.label')}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                name="lastName"
                                                id="contactFormLastName"
                                                placeholder={t('pages.contact.form.lastName.placeholder')}
                                                aria-label={t('pages.contact.form.lastName.placeholder')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row gx-3">
                                    <div className="col-sm-6">
                                        {/* Email */}
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="contactFormEmail">
                                                {t('pages.contact.form.email.label')}
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control form-control-lg"
                                                name="email"
                                                id="contactFormEmail"
                                                placeholder={t('pages.contact.form.email.placeholder')}
                                                aria-label={t('pages.contact.form.email.placeholder')}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        {/* Phone */}
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="contactFormPhone">
                                                {t('pages.contact.form.phone.label')}{' '}
                                                <span className="form-label-secondary">
                                                {t('pages.contact.form.phone.optional')}
                                            </span>
                                            </label>
                                            <input
                                                type="tel"
                                                className="form-control form-control-lg"
                                                name="phone"
                                                id="contactFormPhone"
                                                placeholder={t('pages.contact.form.phone.placeholder')}
                                                aria-label={t('pages.contact.form.phone.placeholder')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Details */}
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="contactFormDetails">
                                        {t('pages.contact.form.details.label')}
                                    </label>
                                    <textarea
                                        className="form-control form-control-lg"
                                        name="details"
                                        id="contactFormDetails"
                                        placeholder={t('pages.contact.form.details.placeholder')}
                                        aria-label={t('pages.contact.form.details.placeholder')}
                                        rows="4"
                                        required
                                    ></textarea>
                                </div>

                                {/* Submit Button */}
                                <div className="d-grid">
                                    <button type="submit" className="btn btn-primary btn-lg">
                                        {t('pages.contact.form.submit')}
                                    </button>
                                </div>

                                <div className="text-center">
                                    <p className="form-text">
                                        {t('pages.contact.form.response')}
                                    </p>
                                </div>
                            </form>
                            {/* End Form */}
                        </div>
                    </div>
                    {/* End Card */}
                </div>
            </div>

            <NewsletterSubscribe/>
        </>
    );
};

export default Contact;