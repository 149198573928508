import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Head from "@/components/layout/Head";

const MaintenanceMode = () => {
    const { t } = useTranslation();

    return (
        <>


            <Head pagePath="store" />

            <div className="gradient-y-sm-primary position-relative zi-2 overflow-hidden">
                <div className="container position-relative content-space-t-4 content-space-t-lg-4 content-space-b-1">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-1 mb-md-1">
                        <h3 className="display-6 mb-3">{t('pages.store.hero.title', 'Tienda')}</h3>
                    </div>
                </div>
            </div>



            {/* Main Content */}
            <main id="content" role="main">

                <div className="d-flex">

                    <div className="container d-flex align-items-center vh-100">
                        <div className="w-sm-75 w-lg-50 text-center mx-sm-auto">
                            <h1 className="h2">{t('pages.store.maintenance.title')}</h1>
                            <p>{t('pages.store.maintenance.description')}</p>

                            <div className="mb-7">
                                <img
                                    className="img-fluid"
                                    src="/assets/svg/illustrations/oc-maintenance.svg"
                                    alt="Maintenance Illustration"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </main>


        </>
    );
};

export default MaintenanceMode;