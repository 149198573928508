import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Carusel = ({
                             items = [],
                             title,
                             viewAllLink,
                             viewAllText,
                             baseRoute = '/blog',
                             itemType = 'category', // category, technology, post, etc.
                             showDescription = true,
                             showIcon = true,
                             imageField = 'image',
                             backgroundImages = [],
                             imagePrefix = '/assets/img/400x500/img',
                             slidesPerView = {
                                 default: 1,
                                 sm: 2,
                                 md: 3,
                                 lg: 4
                             },
                             cardHeight = '15rem',
                             autoplayDelay = 5000,
                             className = '',
                         }) => {
    const { t, i18n } = useTranslation();
    const currentLocale = i18n.language;

    const getTranslation = (item, field) => {
        if (!item?.translations) return '';
        const translation = item.translations.find(t => t.locale === currentLocale)
            || item.translations[0];
        return translation?.[field] || '';
    };

    // Generar URL según el tipo de item
    const getItemUrl = (item) => {
        const slug = getTranslation(item, 'slug');
        const prefix = currentLocale === 'es' ? '' : `/${currentLocale}`;

        switch (itemType) {
            case 'category':
                return `${prefix}${baseRoute}/category/${slug}`;
            case 'technology':
                return `${prefix}${baseRoute}/technology/${slug}`;
            case 'post':
                const categorySlug = getTranslation(item.default_category, 'slug');
                return `${prefix}${baseRoute}/${categorySlug}/${slug}`;
            default:
                return `${prefix}${baseRoute}/${slug}`;
        }
    };

    // Obtener imagen de fondo
    const getBackgroundImage = (item, index) => {
        if (item[imageField]) return item[imageField];
        if (backgroundImages.length > 0) {
            return backgroundImages[index % backgroundImages.length];
        }
        return `${imagePrefix}${(index % 6) + 13}.jpg`;
    };

    // Filtrar items según el nivel si es necesario
    const filteredItems = itemType === 'category' ?
        items.filter(item => item.level === 0) :
        items;

    return (
        <div className={`container content-space-2 ${className}`}>
            <div className="row align-items-md-center mb-7">
                <div className="col-md-6 mb-4 mb-md-0">
                    <h2>{title || t(`components.carousel.${itemType}.title`)}</h2>
                </div>
                {viewAllLink && (
                    <div className="col-md-6 text-md-end">
                        <Link className="link" to={viewAllLink}>
                            {viewAllText || t(`components.carousel.${itemType}.viewAll`)}
                            <i className="bi-chevron-right small ms-1"></i>
                        </Link>
                    </div>
                )}
            </div>

            <div className="swiper-center-mode-end position-relative">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={16}
                    slidesPerView={slidesPerView.default}
                    navigation={{
                        nextEl: '.js-swiper-button-next',
                        prevEl: '.js-swiper-button-prev',
                    }}
                    autoplay={{
                        delay: autoplayDelay,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        580: { slidesPerView: slidesPerView.sm || 2 },
                        768: { slidesPerView: slidesPerView.md || 3 },
                        992: { slidesPerView: slidesPerView.lg || 4 },
                    }}
                    className="swiper-container"
                >
                    {filteredItems.map((item, index) => (
                        <SwiperSlide key={item.id} className="pt-2">
                            <Link
                                to={getItemUrl(item)}
                                className="card card-sm card-stretched-vertical card-transition bg-img-start"
                                style={{
                                    backgroundImage: `url(${getBackgroundImage(item, index)})`,
                                    minHeight: cardHeight,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            >
                                <div
                                    className="position-absolute w-100 h-100"
                                    style={{
                                        background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%)',
                                        top: 0,
                                        left: 0
                                    }}
                                />

                                <div className="card-body position-relative">
                                    {showIcon && item.icon && (
                                        <div className="mb-3 text-center">
                                            <i className={`${item.icon} fs-1 text-white`}></i>
                                        </div>
                                    )}

                                    <h4 className="card-title text-white">
                                        {getTranslation(item, 'name')}
                                    </h4>

                                    {showDescription && (
                                        <p className="text-white-70">
                                            {getTranslation(item, 'description')}
                                        </p>
                                    )}

                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className="js-swiper-button-prev swiper-button-prev"></div>
                <div className="js-swiper-button-next swiper-button-next"></div>
            </div>
        </div>
    );
};

export default Carusel;