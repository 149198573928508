import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NewsletterSubscribe = () => {
    const { t } = useTranslation();

    return (
        <div className="container content-space-b-2 content-space-b-lg-3">
            <div className="w-md-75 w-lg-50 text-center mx-md-auto">
                <div className="row justify-content-lg-between">
                    {/* Heading */}
                    <div className="mb-5">
                        <h2>{t('components.widgets.newsletter.title')}</h2>
                        <p>{t('components.widgets.newsletter.description')}</p>
                    </div>
                    {/* End Heading */}

                    <form>
                        {/* Input Card */}
                        <div className="input-card input-card-sm border mb-3">
                            <div className="input-card-form">
                                <label htmlFor="subscribeForm" className="form-label visually-hidden">
                                    {t('components.widgets.newsletter.input.label')}
                                </label>
                                <input
                                    type="email"
                                    className="form-control form-control-lg"
                                    id="subscribeForm"
                                    placeholder={t('components.widgets.newsletter.input.placeholder')}
                                    aria-label={t('components.widgets.newsletter.input.label')}
                                />
                            </div>
                            <button type="button" className="btn btn-primary btn-lg">
                                {t('components.widgets.newsletter.button')}
                            </button>
                        </div>
                        {/* End Input Card */}
                    </form>

                    <p>
                        <Link to="/terms">
                            {t('components.widgets.newsletter.terms')}
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NewsletterSubscribe;