import React from 'react';
import { createPortal } from 'react-dom';

const Alert = ({
                   isOpen,
                   onClose,
                   onConfirm,
                   title,
                   message,
                   confirmText = 'Confirmar',
                   cancelText = 'Cancelar',
                   type = 'danger',
                   isLoading = false
               }) => {
    if (!isOpen) return null;

    return createPortal(
        <>
            <div
                className="modal fade show"
                style={{
                    display: 'block',
                    zIndex: 9999 // Alto z-index para estar sobre otros modales
                }}
                tabIndex="-1"
                role="dialog"
                onClick={(e) => {
                    if (e.target === e.currentTarget) onClose();
                }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <h5 className="modal-title">
                                {type === 'danger' && <i className="bi bi-exclamation-triangle text-danger me-2"/>}
                                {type === 'warning' && <i className="bi bi-exclamation-circle text-warning me-2"/>}
                                {type === 'info' && <i className="bi bi-info-circle text-info me-2"/>}
                                {title}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={onClose}
                                disabled={isLoading}
                            />
                        </div>
                        <div className="modal-body text-center py-4">
                            {message}
                        </div>
                        <div className="modal-footer border-0 pt-0">
                            <button
                                type="button"
                                className="btn btn-white"
                                onClick={onClose}
                                disabled={isLoading}
                            >
                                {cancelText}
                            </button>
                            <button
                                type="button"
                                className={`btn btn-${type}`}
                                onClick={onConfirm}
                                disabled={isLoading}
                            >
                                {isLoading && (
                                    <span className="spinner-border spinner-border-sm me-2"
                                          role="status"
                                          aria-hidden="true"/>
                                )}
                                {confirmText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Backdrop con z-index menor que el modal pero mayor que otros modales */}
            <div
                className="modal-backdrop fade show"
                style={{ zIndex: 9998 }}
            />
        </>,
        document.body
    );
};

export default Alert;