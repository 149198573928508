import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const saveSessionUser = (user) => {
    try {
        localStorage.setItem('sessionUser', JSON.stringify(user));
    } catch (error) {
        console.log('Error saving user to storage:', error);
    }
};

export const loadSessionUser = createAsyncThunk(
    'user/fetchUserFromStorage',
    async () => {
        try {
            console.log('Loading User');
            const user = localStorage.getItem('sessionUser');
            return user ? JSON.parse(user) : null;
        } catch (error) {
            console.log('Error fetching user from storage:', error);
            return null;
        }
    }
);

// Función para cargar el estado inicial
const loadInitialState = () => {
    try {
        const userData = localStorage.getItem('sessionUser');
        return userData ? JSON.parse(userData) : {
            name: null,
            email: null,
            country_id: null,
            phone: null,

            image_profile: null,
        };
    } catch (error) {
        console.log('Error loading initial state:', error);
        return {
            name: null,
            email: null,
            image_profile: null,
            phone: null,
            country_id: null,
        };
    }
};

export const userSlice = createSlice({
    name: 'user',
    initialState: loadInitialState(),
    reducers: {
        setSessionUser: (state, action) => {
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.image_profile = action.payload.image_profile;
            state.country_id = action.payload.country_id;
            state.phone = action.payload.phone;

            saveSessionUser(action.payload);
        },
        removeSessionUser: (state) => {
            state.name = null;
            state.email = null;
            state.image_profile = null;
            state.country_id = null;
            state.phone = null;

            // Limpiar el localStorage
            localStorage.removeItem('sessionUser');
        },
        updateSessionUser: (state, action) => {
            // Permite actualizar campos específicos del usuario
            const updatedUser = { ...state, ...action.payload };
            Object.assign(state, updatedUser);
            saveSessionUser(updatedUser);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadSessionUser.pending, (state) => {
                // Opcionalmente puedes agregar un estado de carga
                state.loading = true;
            })
            .addCase(loadSessionUser.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    return {
                        ...state,
                        ...action.payload,
                    };
                }
            })
            .addCase(loadSessionUser.rejected, (state) => {
                state.loading = false;
                // Manejar el error si es necesario
            });
    },
});

// Acciones
export const { setSessionUser, removeSessionUser, updateSessionUser } = userSlice.actions;

// Selectores
export const selectUser = (state) => state.user;
export const selectUserName = (state) => state.user.name;
export const selectUserEmail = (state) => state.user.email;
export const selectCountryId = (state) => state.user.country_id;
export const selectUserImageProfile = (state) => state.user.image_profile;

export default userSlice.reducer;