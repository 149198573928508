
import { useState, useMemo, useEffect } from 'react';

export const useWizard = (formConfig, formData) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [hasNextStep, setHasNextStep] = useState(true);
    const [hasPrevStep, setHasPrevStep] = useState(false);

    // Procesar los steps basados en los separadores
    const steps = useMemo(() => {
        if (formConfig.mode !== 'wizard') return [];

        let separatorFields = [];
        let currentFields = [];
        let stepConfig = {};

        formConfig.fields.forEach((field, index) => {
            if (field.separator) {
                if (currentFields.length > 0) {
                    separatorFields.push({
                        ...stepConfig,
                        fields: currentFields
                    });
                }
                currentFields = [];
                stepConfig = typeof field.separator === 'object'
                    ? {
                        title: field.separator.text,
                        icon: field.separator.icon,
                        className: field.separator.className
                    }
                    : {
                        title: field.separator,
                        icon: null,
                        className: ''
                    };
            } else {
                currentFields.push(field);
            }

            if (index === formConfig.fields.length - 1 && currentFields.length > 0) {
                separatorFields.push({
                    ...stepConfig,
                    fields: currentFields
                });
            }
        });

        return formConfig.wizard?.order === 'desc'
            ? separatorFields.reverse()
            : separatorFields;
    }, [formConfig.fields, formConfig.mode, formConfig.wizard?.order]);

    // Obtener campos del paso actual
    const currentStepFields = useMemo(() => {
        if (formConfig.mode !== 'wizard') {
            return formConfig.fields;
        }
        return steps[currentStep]?.fields || [];
    }, [currentStep, steps, formConfig.mode, formConfig.fields]);

    // Validar el paso actual
    const validateCurrentStep = () => {
        if (!formConfig.wizard?.validation_step) return true;

        return currentStepFields.every(field => {
            if (field.required) {
                const value = formData[field.name];
                return value !== undefined && value !== null && value !== '';
            }
            return true;
        });
    };

    // Actualizar estados de navegación
    useEffect(() => {
        if (formConfig.mode === 'wizard') {
            setHasNextStep(currentStep < steps.length - 1);
            setHasPrevStep(currentStep > 0);
        }
    }, [currentStep, steps.length, formConfig.mode]);

    const goToNextStep = () => {
        if (hasNextStep && validateCurrentStep()) {
            setCurrentStep(prev => prev + 1);
            return true;
        }
        return false;
    };

    const goToPrevStep = () => {
        if (hasPrevStep) {
            setCurrentStep(prev => prev - 1);
            return true;
        }
        return false;
    };

    const goToStep = (step) => {
        if (step >= 0 && step < steps.length) {
            if (!formConfig.wizard?.validation_step || validateCurrentStep()) {
                setCurrentStep(step);
                return true;
            }
        }
        return false;
    };

    const resetWizard = () => {
        setCurrentStep(0);
    };

    return {
        currentStep,
        steps,
        currentStepFields,
        hasNextStep,
        hasPrevStep,
        validateCurrentStep,
        goToNextStep,
        goToPrevStep,
        goToStep,
        resetWizard
    };
};
