import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useNavigate, useLocation, Link, useParams} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {selectUser, removeSessionUser} from '../store/slice/userSlice';
import {removeSessionAuth} from '../store/slice/authSlice';
import {toast} from 'react-toastify';
import Avatar from "../components/widgets/Avatar";
import LanguageSelector from "../components/widgets/LanguageSelector";
import {usePermissions} from '../hooks/usePermissions';
import {clearPermissions, selectPermissions} from "../store/slice/permissionSlice";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Footer from "../components/layout/Footer";

const PanelLayout = () => {
    const { t, i18n } = useTranslation();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { lang } = useParams();

    useEffect(() => {
        if (lang && ['es', 'en'].includes(lang)) {
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);


    const handleLogout = async () => {
        try {
            await dispatch(removeSessionUser());
            await dispatch(removeSessionAuth());
            await dispatch(clearPermissions()); // Agregar esto
            toast.success(t('layout.panel.header.logoutSuccess'));
            navigate('/login');
        } catch (error) {
            toast.error(t('layout.panel.header.logoutError'));
        }
    };

    const isActiveLink = (path) => {
        return location.pathname === path;
    };

    const MenuItem = ({module, path, icon, children}) => {
        const {canRead} = usePermissions(); // Aseguramos tener acceso a canRead
        const hasAccess = canRead(module);

        if (!hasAccess) return null;

        return (
            <li className="nav-item">
                <Link
                    to={path}
                    className={`nav-link ${isActiveLink(path) ? 'active' : ''}`}
                >
                    <i className={`${icon} nav-icon`}></i> {children}
                </Link>
            </li>
        );
    };

    return (
        <main id="content" role="main" className="bg-light">
            <div className="navbar-dark bg-dark" style={{backgroundImage: 'url(/api/placeholder/1200/200)'}}>
                <div className="container content-space-b-lg-1">
                    <div className="row items-center justify-between">
                        <div className="container content-space-1 ">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <div className="d-flex align-items-center gap-4">
                                        <div className="hidden lg:block">
                                            <img src="/assets/logo/secundary_sm.png" alt="Logo" width={150}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="d-flex align-items-center gap-3">
                                        <LanguageSelector/>
                                        <div className="hidden lg:block">
                                            <button
                                                className="btn btn-soft-light btn-sm"
                                                onClick={handleLogout}
                                            >
                                                <i className="bi-box-arrow-right me-2"></i>
                                                {t('layout.panel.header.logout')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="navbar-expand-lg navbar-light">
                            <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
                                <div className="card flex-grow-1 mb-5">
                                    <div className="card-body">
                                        <div className="text-center mb-5">
                                            <div className="avatar avatar-xxl avatar-circle mb-3">
                                                <Avatar name={user.name} size={100}/>
                                                <img
                                                    className="avatar-status avatar-lg-status"
                                                    src="/assets/svg/illustrations/top-vendor.svg"
                                                    alt="Verified"
                                                />
                                            </div>
                                            <h4 className="card-title mb-0">{user.name}</h4>
                                            <p className="card-text small">{user.email}</p>
                                        </div>

                                        <span className="text-cap">{t('layout.panel.sidebar.account')}</span>
                                        <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                                            <MenuItem
                                                module="profile"
                                                path="/panel/profile"
                                                icon="bi-person-badge"
                                            >
                                                {t('layout.panel.sidebar.nav.personal')}
                                            </MenuItem>
                                            <MenuItem
                                                module="security"
                                                path="/panel/security"
                                                icon="bi-shield-shaded"
                                            >
                                                {t('layout.panel.sidebar.nav.security')}
                                            </MenuItem>
                                            {user.role !== 'admin' && (
                                                <>
                                                    <MenuItem
                                                        module="notifications"
                                                        path="/panel/notification"
                                                        icon="bi-bell"
                                                    >
                                                        {t('layout.panel.sidebar.nav.notifications')}
                                                    </MenuItem>
                                                </>
                                            )}
                                        </ul>


                                        {user.role !== 'admin' && (
                                            <>
                                                <span className="text-cap">{t('layout.panel.sidebar.shopping')}</span>
                                                <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                                                    <MenuItem
                                                        module="purchases"
                                                        path="/panel/purchases"
                                                        icon="bi-basket"
                                                    >
                                                        {t('layout.panel.sidebar.nav.purchases')}
                                                    </MenuItem>
                                                </ul>
                                            </>
                                        )}

                                        {user.role == 'admin' && (
                                            <>
                                                <span
                                                    className="text-cap">{t('layout.panel.sidebar.administration')}</span>
                                                <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                                                    <MenuItem
                                                        module="clients"
                                                        path="/panel/clients"
                                                        icon="bi-people"
                                                    >
                                                        {t('layout.panel.sidebar.nav.clients')}
                                                    </MenuItem>
                                                    <MenuItem
                                                        module="products"
                                                        path="/panel/products"
                                                        icon="bi-box"
                                                    >
                                                        {t('layout.panel.sidebar.nav.products')}
                                                    </MenuItem>


                                                    <MenuItem
                                                        module="categories"
                                                        path="/panel/categories"
                                                        icon="bi-columns-gap"
                                                    >
                                                        {t('layout.panel.sidebar.nav.categories')}
                                                    </MenuItem>


                                                    <MenuItem
                                                        module="technologies"
                                                        path="/panel/technologies"
                                                        icon="bi-cpu"
                                                    >
                                                        {t('layout.panel.sidebar.nav.technologies')}
                                                    </MenuItem>

                                                    <MenuItem
                                                        module="blog_posts"
                                                        path="/panel/blog"
                                                        icon="bi-newspaper"
                                                    >
                                                        {t('layout.panel.sidebar.nav.blog')}
                                                    </MenuItem>
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-9">
                        <Outlet/>
                    </div>
                </div>
            </div>

            <Footer />

        </main>
    );
};

export default PanelLayout;