import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import InitialAvatar from "@components/widgets/Avatar";
import api from "@services/api";
import ModuleConstructor from "@components/constructors/ModuleConstructor/ModuleConstructor";

const Products = () => {
    const {t, i18n} = useTranslation();
    const currentLocale = i18n.language;
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 15
    });
    const endpoint = 'auth/catalogs/products';

    const getTranslation = (item, field) => {
        const translation = item.translations?.find(t => t.locale === currentLocale);
        return translation?.[field] || item.translations?.[0]?.[field] || item[field] || '';
    };

    const getRecords = async (page = 1, perPage = 15, search = '') => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting(endpoint, {
                filter: 'all',
                page,
                per_page: perPage,
                search,
                with: ['translations', 'technologies', 'categories'] // Asegurar que se cargan las relaciones
            }, true);

            setRecords(response.data);
            setPagination({
                currentPage: response.current_page,
                lastPage: response.last_page,
                total: response.total,
                perPage: response.per_page
            });
        } catch (error) {
            console.error('Error fetching records:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getRecords().then();
    }, []);

    const handleCreate = async (data) => {
        try {
            const formData = new FormData();

            // Archivos
            if (data.preview_image instanceof File) {
                formData.append('preview_image', data.preview_image);
            }

            if (data.preview_video instanceof File) {
                formData.append('preview_video', data.preview_video);
            }

            if (Array.isArray(data.images)) {
                data.images.forEach((file, index) => {
                    if (file instanceof File) {
                        formData.append(`images[${index}]`, file);
                    }
                });
            }

            // Otros campos
            Object.keys(data).forEach(key => {
                if (!['preview_image', 'preview_video', 'images'].includes(key)) {
                    formData.append(key, data[key]);
                }
            });
            formData.append(`technologies`, data.technologies.selected);
            formData.append(`categories`, data.categories.selected);



            await api.postAwaiting(endpoint, formData, true, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            await getRecords(pagination.currentPage, pagination.perPage);
            return true;
        } catch (error) {
            console.error('Error creating record:', error);
            return false;
        }
    };

    const handleUpdate = async (data, code) => {
        try {

            const formData = new FormData();

            // Archivos
            if (data.preview_image instanceof File) {
                formData.append('preview_image', data.preview_image);
            }

            if (data.preview_video instanceof File) {
                formData.append('preview_video', data.preview_video);
            }

            if (Array.isArray(data.images)) {
                data.images.forEach((file, index) => {
                    if (file instanceof File) {
                        formData.append(`images[${index}]`, file);
                    }
                });
            }

            // Otros campos
            Object.keys(data).forEach(key => {
                if (!['preview_image', 'preview_video', 'images'].includes(key)) {
                    formData.append(key, data[key]);
                }
            });

            formData.append(`technologies`, data.technologies.selected);
            formData.append(`categories`, data.categories.selected);



            await api.putAwaiting(`${endpoint}/${code}`, formData, true, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            await getRecords(pagination.currentPage, pagination.perPage);
            return true;
        } catch (error) {
            console.error('Error updating record:', error);
            return false;
        }
    };

    const handleDelete = async (code) => {
        try {
            setIsLoading(true);
            await api.deleteAwaiting(`${endpoint}/${code}`, {code}, true);
            await getRecords(pagination.currentPage, pagination.perPage);
            return true;
        } catch (error) {
            console.error('Error deleting record:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        getRecords(page, pagination.perPage);
    };

    const handlePerPageChange = (perPage) => {
        getRecords(1, perPage);
    };

    const handleSearch = (term) => {
        getRecords(1, pagination.perPage, term);
    };

    const config = {
        modal: {size: 'xl'},
        table: {
            headers: [
                {title: t('pages.panel.products.headers.name')},
                {title: t('pages.panel.products.headers.price')},
                {title: t('pages.panel.products.headers.status')},
                {title: t('pages.panel.products.headers.actions'), width: '5%'}
            ],
            columns: [
                {
                    key: 'product',
                    render: (item) => (
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                {item.preview_image ? (
                                    <img
                                        src={item.preview_image}
                                        alt={getTranslation(item, 'name')}
                                        className="avatar avatar-sm"
                                        style={{width: '35px', height: '35px', objectFit: 'cover'}}
                                    />
                                ) : (
                                    <InitialAvatar name={getTranslation(item, 'name')} size={35}/>
                                )}
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="text-hover-primary mb-0">
                                    {getTranslation(item, 'name')}
                                </h6>
                                <small className="text-muted text-wrap d-block">
                                    {getTranslation(item, 'description_short')}
                                </small>

                                <div className="mt-1   gap-2">

                                    <span>Tecnologias:</span>
                                    {item.technologies?.data?.map(tech => (
                                        <span key={tech.id} className="badge bg-soft-primary text-primary">
                                            <i className={`${tech.icon} me-1`}/> {getTranslation(tech, 'alias')}
                                        </span>
                                    ))}
                                    <br/>
                                    <span>Categorias:</span>
                                    {item.categories?.data?.map(cat => (
                                        <span key={cat.id} className="badge bg-soft-secondary text-secondary">
                                            <i className="bi-folder me-1"/> {getTranslation(cat, 'alias')}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                },
                {
                    key: 'details',
                    render: (item) => (
                        <div>
                            <div className="fw-semibold">${item.price}</div>
                            {item.discount_percentage > 0 && (
                                <small className="text-success">
                                    {item.discount_percentage}% OFF
                                </small>
                            )}
                        </div>
                    )
                },
                {
                    key: 'license',
                    render: (item) => (
                        <div>
                            <div>{item.license_type === 'standard' ? 'Standard' : 'Extended'}</div>
                            <small className="text-muted">{item.support_time}</small>
                        </div>
                    )
                }
            ],
            searchKeys: [
                'translations.name',
                'translations.description_short',
                'price',
                'technologies.translations.name',
                'categories.translations.name'
            ],
            pagination: {
                enabled: true,
                serverSide: true,
                currentPage: pagination.currentPage,
                lastPage: pagination.lastPage,
                total: pagination.total,
                perPage: pagination.perPage,
                onChange: handlePageChange,
                onPerPageChange: handlePerPageChange
            },
            search: {
                serverSide: true,
                onSearch: handleSearch
            }
        },
        form: {
            mode: 'wizard', // standard // wizard,
            wizard: {'position': 'left', 'validation_step': true, order: 'asc'},
            fields: [
                // Archivos y medios
                {
                    separator: {
                        text: 'Información',
                        icon: 'bi-card-text',
                        className: 'divider-center pt-5'
                    }
                },

                {
                    name: 'name',
                    type: 'text',
                    required: true,
                    class: 'col-12 mb-3',
                    icon: 'bi-card-heading',
                    label: t('pages.panel.products.form.name.label'),
                    placeholder: t('pages.panel.products.form.name.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },
                {
                    name: 'description_short',
                    type: 'textarea',
                    required: true,
                    class: 'col-md-12 mb-3',
                    icon: 'bi-text-paragraph',
                    label: t('pages.panel.products.form.description_short.label'),
                    placeholder: t('pages.panel.products.form.description_short.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },
                {
                    name: 'description_long',
                    type: 'textarea',
                    required: true,
                    class: 'col-md-12 mb-3',
                    icon: 'bi-text-paragraph',
                    label: t('pages.panel.products.form.description_long.label'),
                    placeholder: t('pages.panel.products.form.description_long.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },
                {
                    name: 'features',
                    type: 'textarea',
                    class: 'col-md-12 mb-3',
                    icon: 'bi-list-check',
                    label: t('pages.panel.products.form.features.label'),
                    placeholder: t('pages.panel.products.form.features.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },

                // Precios y descuentos
                {
                    name: 'price',
                    type: 'number',
                    required: true,
                    class: 'col-lg-4 mb-3',
                    icon: 'bi-currency-dollar',
                    label: t('pages.panel.products.form.price.label'),
                    placeholder: t('pages.panel.products.form.price.placeholder'),
                    step: '0.01',
                    min: 0
                },
                {
                    name: 'discount_percentage',
                    type: 'number',
                    class: 'col-md-4 mb-3',
                    icon: 'bi-percent',
                    label: t('pages.panel.products.form.discount_percentage.label'),
                    min: 0,
                    max: 100
                },
                {
                    name: 'discount_expire_at',
                    type: 'datetime-local',
                    class: ' col-md-4 mb-3',
                    icon: 'bi-calendar',
                    label: t('pages.panel.products.form.discount_expire_at.label'),
                    format: 'YYYY-MM-DD HH:mm:ss', // Añadimos el formato esperado
                    placeholder: t('pages.panel.products.form.discount_expire_at.placeholder')
                },
                // Archivos y medios
                {
                    separator: {
                        text: 'Archivos / Multimedia ',
                        icon: 'bi-files',
                        className: 'divider-center pt-5'
                    }
                },
                {
                    name: 'preview_image',
                    type: 'file',
                    required: true,
                    class: 'col-md-6 mb-4',
                    icon: 'bi-image',
                    label: t('pages.panel.products.form.preview_image.label'),
                    accept: 'image/*',
                    preview: true,
                    maxSize: 20 * 1024 * 1024, // 20MB
                    fileTypes: ['image/jpeg', 'image/png', 'image/webp']
                },
                {
                    name: 'preview_video',
                    type: 'file',
                    required: true,
                    class: 'col-md-6 mb-4',
                    icon: 'bi-camera-video',
                    label: t('pages.panel.products.form.preview_video.label'),
                    accept: 'video/*',
                    preview: true,
                    maxSize: 300 * 1024 * 1024, // 300MB
                    fileTypes: ['video/mp4', 'video/webm', 'video/avi']
                },
                {
                    name: 'images',
                    type: 'file',
                    class: 'col-md-6 mb-4',
                    icon: 'bi-images',
                    label: t('pages.panel.products.form.images.label'),
                    accept: 'image/*',
                    multiple: true,
                    preview: true,
                    maxSize: 20 * 1024 * 1024, // 20MB per image
                    fileTypes: ['image/jpeg', 'image/png', 'image/webp']
                }, {
                    name: 'file_path',
                    type: 'file',
                    class: 'col-md-6 mb-4',
                    icon: 'bi-file-earmark-zip',
                    label: t('pages.panel.products.form.file_path.label'),
                    accept: '.zip,.rar,.7z',
                    preview: false,
                    maxSize: 1024 * 1024 * 1024, // 1GB
                    fileTypes: ['application/zip', 'application/x-rar-compressed', 'application/x-7z-compressed']
                },

                {separator: 'Requisitos', icon: 'bi-ui-checks', className: 'divider-start pt-5'},

                {
                    name: 'file_size',
                    type: 'text',
                    required: true,
                    class: 'col-md-3 mb-4',
                    icon: 'bi-harddrive',
                    label: t('pages.panel.products.form.file_size.label'),
                    placeholder: t('pages.panel.products.form.file_size.placeholder')
                },

                // Versión y requisitos
                {
                    name: 'version',
                    type: 'text',
                    class: 'col-lg-3 mb-3',
                    icon: 'bi-code-square',
                    label: t('pages.panel.products.form.version.label'),
                    placeholder: '1.0.0'
                },
                {
                    name: 'requirements',
                    type: 'textarea',
                    class: 'col-lg-6 mb-3',
                    icon: 'bi-gear',
                    label: t('pages.panel.products.form.requirements.label'),
                    placeholder: t('pages.panel.products.form.requirements.placeholder')
                },

                // Demo y credenciales
                {
                    name: 'demo_url',
                    type: 'url',
                    class: 'col-lg-12 mb-3',
                    icon: 'bi-link-45deg',
                    label: t('pages.panel.products.form.demo_url.label'),
                    placeholder: 'https://'
                },
                {
                    name: 'demo_user',
                    type: 'text',
                    class: 'col-lg-6 mb-3',
                    icon: 'bi-person',
                    label: t('pages.panel.products.form.demo_user.label'),
                    placeholder: t('pages.panel.products.form.demo_user.placeholder')
                },
                {
                    name: 'demo_password',
                    type: 'text',
                    class: 'col-lg-6 mb-3',
                    icon: 'bi-key',
                    label: t('pages.panel.products.form.demo_password.label'),
                    placeholder: t('pages.panel.products.form.demo_password.placeholder')
                },

                // Soporte y licencia
                {
                    mode: 'standard', // Añadir esta línea
                    reducer: { // Añadir esta configuración
                        field_label: 'name',
                        field_value: 'value'
                    },
                    name: 'license_type',
                    type: 'select',
                    required: true,
                    class: 'col-lg-4 mb-3',
                    icon: 'bi-shield-check',
                    label: t('pages.panel.products.form.license_type.label'),
                    options: [
                        {value: 'standard', name: t('pages.panel.products.form.license_type.options.standard')},
                        {value: 'extended', name: t('pages.panel.products.form.license_type.options.extended')}
                    ]
                },
                {
                    mode: 'standard', // Añadir esta línea
                    reducer: { // Añadir esta configuración
                        field_label: 'name',
                        field_value: 'value'
                    },
                    name: 'support_time',
                    type: 'select',
                    class: 'col-lg-4 mb-3',
                    icon: 'bi-clock',
                    label: t('pages.panel.products.form.support_time.label'),
                    options: [
                        {value: '3_months', name: t('pages.panel.products.form.support_time.options.3_months')},
                        {value: '6_months', name: t('pages.panel.products.form.support_time.options.6_months')},
                        {value: '1_year', name: t('pages.panel.products.form.support_time.options.1_year')}
                    ]
                },
                {
                    name: 'support_documentation',
                    type: 'switch',
                    class: 'col-lg-2 mb-3',
                    label: t('pages.panel.products.form.support_documentation.label')
                },
                {
                    name: 'support_updates',
                    type: 'switch',
                    class: 'col-lg-2 mb-3',
                    label: t('pages.panel.products.form.support_updates.label')
                },

                {separator: 'Meta data',  icon: 'bi-card-text', className: 'divider-start pt-5'},

                // Meta información
                {
                    name: 'meta_description',
                    type: 'textarea',
                    required: true,
                    class: 'col-12 mb-3',
                    icon: 'bi-file-text',
                    label: t('pages.panel.products.form.meta_description.label'),
                    placeholder: t('pages.panel.products.form.meta_description.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },
                {
                    name: 'meta_tags',
                    type: 'text',
                    required: true,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-tags',
                    label: t('pages.panel.products.form.meta_tags.label'),
                    placeholder: t('pages.panel.products.form.meta_tags.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },
                {
                    name: 'meta_keyboards',
                    type: 'text',
                    required: true,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-keyboard',
                    label: t('pages.panel.products.form.meta_keyboards.label'),
                    placeholder: t('pages.panel.products.form.meta_keyboards.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },

                {
                    separator: 'Secciones',
                    icon: 'bi-tags',
                    className: 'divider-start pt-5'
                },

                {
                    name: 'technologies',
                    type: 'select',
                    mode: "api",
                    endpoint: 'auth/catalogs/technologies',
                    pivot: {
                        relation: 'technologies',
                        field_value: 'id'
                    },
                    reducer: {
                        field_label: 'translations.name',
                        field_value: 'id',
                        searchKeys: ['translations.name', 'code', 'alias']
                    },
                    multiple: true,
                    showPills: true,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-code-square',
                    label: t('pages.panel.blog_posts.form.technologies.label'),
                    placeholder: t('pages.panel.blog_posts.form.technologies.placeholder')
                },

                {
                    name: 'categories',
                    type: 'select',
                    mode: "api",
                    endpoint: 'auth/catalogs/categories',
                    pivot: {
                        relation: 'categories',
                        field_value: 'id'
                    },
                    reducer: {
                        field_label: 'translations.name',
                        field_value: 'id',
                        searchKeys: [
                            'translations.name',
                            'code',
                            'alias'
                        ]
                    },
                    multiple: true,
                    showPills: true,
                    maxPills: 3,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-folder',
                    label: t('pages.panel.blog_posts.form.categories.label'),
                    placeholder: t('pages.panel.blog_posts.form.categories.placeholder')
                }


            ]
        },
        titles: {
            main: <><i className="bi bi-box-seam"></i> {t('pages.panel.products.titles.main')}</>,
            createButton: t('pages.panel.products.buttons.create')
        }
    };

    return (
        <ModuleConstructor
            config={config}
            records={records}
            isLoading={isLoading}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
        />
    );
};

export default Products;