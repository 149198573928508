import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'quill/dist/quill.snow.css';

const QuillEditor = ({ value, onChange, placeholder, icon, maxLength, isMultilocale, t }) => {
    const editorRef = useRef(null);
    const quillInstance = useRef(null);
    const [charCount, setCharCount] = useState(0);

    useEffect(() => {
        if (!quillInstance.current && editorRef.current) {
            quillInstance.current = new Quill(editorRef.current.querySelector('.quill-editor'), {
                theme: 'snow',
                placeholder: placeholder,
                modules: {
                    toolbar: [
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'color': [] }, { 'background': [] }],
                        ['link', 'image', 'video'],
                        ['clean']
                    ]
                }
            });

            if (value) {
                quillInstance.current.root.innerHTML = value;
                updateCharCount(value);
            }

            quillInstance.current.on('text-change', () => {
                const content = quillInstance.current.root.innerHTML;
                updateCharCount(content);
                onChange(content);
            });
        }
    }, []);

    useEffect(() => {
        if (quillInstance.current && value !== quillInstance.current.root.innerHTML) {
            quillInstance.current.root.innerHTML = value || '';
            updateCharCount(value || '');
        }
    }, [value]);

    const updateCharCount = (content) => {
        const textContent = content.replace(/<[^>]+>/g, '');
        setCharCount(textContent.length);
    };

    const getCharCountClass = () => {
        if (!maxLength) return 'text-muted';

        if (charCount >= maxLength) return 'text-danger';
        if (charCount >= maxLength * 0.8) return 'text-warning';
        return 'text-success';
    };

    return (
        <div className="input-group" ref={editorRef}>
            <span className="input-group-text">
                <i className={icon}></i>
            </span>
            <div className="position-relative flex-grow-1">
                <div className="quill-editor"></div>
                {/* Contador y multilenguaje */}
                <div className="position-absolute top-0 end-0 d-flex align-items-center me-2 mt-2 gap-2">
                    {maxLength && (
                        <small className={`${getCharCountClass()} fw-medium`} style={{fontSize: '12px'}}>
                            {charCount}/{maxLength}
                        </small>
                    )}
                    {isMultilocale && (
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip>{t('common.form.multiLanguageField')}</Tooltip>
                            }
                        >
                            <i className="bi bi-translate text-primary" style={{fontSize: '14px', cursor: 'help'}}/>
                        </OverlayTrigger>
                    )}
                </div>
            </div>
        </div>
    );
};

QuillEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    maxLength: PropTypes.number,
    isMultilocale: PropTypes.bool,
    t: PropTypes.func.isRequired
};

export default QuillEditor;