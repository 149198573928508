import { useSelector } from 'react-redux';
import { selectPermissions } from '../store/slice/permissionSlice';

export const usePermissions = () => {
    const permissionsArray = useSelector(selectPermissions);

    // Modificamos para buscar en el array de objetos
    const hasPermission = (permissionName) => {
        return permissionsArray.some(permission => permission.name === permissionName);
    };

    const hasModulePermission = (module, action) => {
        return hasPermission(`${module}-${action}`);
    };

    const canRead = (module) => hasModulePermission(module, 'read');
    const canCreate = (module) => hasModulePermission(module, 'create');
    const canUpdate = (module) => hasModulePermission(module, 'update');
    const canDelete = (module) => hasModulePermission(module, 'delete');

    return {
        hasPermission,
        hasModulePermission,
        canRead,
        canCreate,
        canUpdate,
        canDelete
    };
};