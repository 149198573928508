import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import api from "@services/api";
import ModuleConstructor from "@components/constructors/ModuleConstructor/ModuleConstructor";

const Technologies = () => {
    const {t, i18n} = useTranslation();
    const currentLocale = i18n.language;
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 15
    });
    const endpoint = 'auth/catalogs/categories';

    const getTranslation = (item, field) => {
        const translation = item.translations?.find(t => t.locale === currentLocale);
        return translation?.[field] || item.translations?.[0]?.[field] || '';
    };

    const getRecords = async (page = 1, perPage = 15, search = '') => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting(endpoint, {
                filter: 'all',
                page,
                per_page: perPage,
                search
            }, true);
console.log(response);
            setRecords(response.data);
            setPagination({
                currentPage: response.current_page,
                lastPage: response.last_page,
                total: response.total,
                perPage: response.per_page
            });
        } catch (error) {
            console.error('Error fetching records:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getRecords().then();
    }, []);

    const handleCreate = async (data) => {
        try {
            setIsLoading(true);
            const formData = {
                alias: data.alias,
                slug: data.slug,
                icon: data.icon,
                name: data.name,
                description: data.description
            };

            await api.postAwaiting(endpoint, formData, true);
            await getRecords(pagination.currentPage, pagination.perPage);
            return true;
        } catch (error) {
            console.error('Error creating record:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdate = async (data, code) => {
        try {
            setIsLoading(true);
            const formData = {
                alias: data.alias,
                icon: data.icon,
                name: data.name,
                description: data.description
            };

            if (data.slug) {
                formData.slug = data.slug;
            }

            await api.putAwaiting(`${endpoint}/${code}`, formData, true);
            await getRecords(pagination.currentPage, pagination.perPage);
            return true;
        } catch (error) {
            console.error('Error updating record:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (code) => {
        try {
            setIsLoading(true);
            await api.deleteAwaiting(`${endpoint}/${code}`, {code}, true);
            await getRecords(pagination.currentPage, pagination.perPage);
            return true;
        } catch (error) {
            console.error('Error deleting record:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        getRecords(page, pagination.perPage);
    };

    const handlePerPageChange = (perPage) => {
        getRecords(1, perPage);
    };

    const handleSearch = (term) => {
        getRecords(1, pagination.perPage, term);
    };

    const config = {
        table: {
            headers: [
                {title: t('pages.panel.categories.headers.alias')},
                {title: t('pages.panel.categories.headers.name')},
                {title: t('pages.panel.categories.headers.description'), width: '20%'},
                {title: t('pages.panel.categories.headers.actions'), width: '5%'}
            ],
            columns: [
                {
                    key: 'alias',
                    render: (item) => <span>{item.alias}</span>
                },
                {
                    key: 'name',
                    render: (item) => (
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                {item.icon && <i className={`bi ${item.icon} fs-4`}></i>}
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="text-hover-primary mb-0">{getTranslation(item, 'name')}</h6>
                                <small className="text-muted">{item.slug}</small>
                            </div>
                        </div>
                    )
                },
                {
                    key: 'description',
                    render: (item) => (
                        <span className="text-truncate">
                            {getTranslation(item, 'description') || '-'}
                        </span>
                    )
                },
            ],
            searchKeys: ['alias', 'translations.name', 'translations.description'],
            pagination: {
                enabled: true,
                serverSide: true,
                currentPage: pagination.currentPage,
                lastPage: pagination.lastPage,
                total: pagination.total,
                perPage: pagination.perPage,
                onChange: handlePageChange,
                onPerPageChange: handlePerPageChange
            },
            search: {
                serverSide: true,
                onSearch: handleSearch
            }
        },
        form: {
            fields: [
                {
                    name: 'name',
                    type: 'text',
                    required: true,
                    icon: 'bi-laptop',
                    label: t('pages.panel.categories.form.name.label'),
                    placeholder: t('pages.panel.categories.form.name.placeholder'),
                    tooltip: t('pages.panel.categories.form.name.tooltip'),
                    class: "col-sm-6 mb-3",
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength:50,
                },
                {
                    name: 'alias',
                    type: 'text',
                    required: true,
                    icon: 'bi-laptop',
                    label: t('pages.panel.categories.form.alias.label'),
                    placeholder: t('pages.panel.categories.form.alias.placeholder'),
                    tooltip: t('pages.panel.categories.form.alias.tooltip'),
                    class: "col-sm-6 mb-3",
                    maxLength:50
                },
                {
                    name: 'description',
                    type: 'textarea',
                    required: true,
                    icon: 'bi-text-paragraph',
                    label: t('pages.panel.categories.form.description.label'),
                    placeholder: t('pages.panel.categories.form.description.placeholder'),
                    class: "col-12 mb-3",
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength:255
                },
                {
                    name: 'icon',
                    type: 'text',
                    icon: 'bi-code',
                    label: t('pages.panel.categories.form.icon.label'),
                    placeholder: t('pages.panel.categories.form.icon.placeholder'),
                    tooltip: t('pages.panel.categories.form.icon.tooltip'),
                    class: "col-sm-6 mb-3"
                },
                {
                    name: 'slug',
                    type: 'text',
                    required: true,
                    icon: 'bi-link',
                    label: t('pages.panel.categories.form.slug.label'),
                    placeholder: t('pages.panel.categories.form.slug.placeholder'),
                    tooltip: t('pages.panel.categories.form.slug.tooltip'),
                    disabled: false,
                    class: "col-sm-6 mb-3"
                }
            ]
        },
        titles: {
            main: <><i className="bi bi-grid-fill me-2"></i>{t('pages.panel.categories.titles.main')}</>,
            createButton: t('pages.panel.categories.buttons.create')
        }
    };

    return (
        <ModuleConstructor
            config={config}
            records={records}
            isLoading={isLoading}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
        />
    );
};

export default Technologies;