import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dropdownButtonRef = useRef(null);
    const bootstrapDropdownRef = useRef(null);

    const languages = [
        { code: 'en', name: t('layout.header.languages.en'), flag: 'us' },
        { code: 'es', name: t('layout.header.languages.es'), flag: 'es' },
        { code: 'de', name: t('layout.header.languages.de'), flag: 'de' }
    ];

    useEffect(() => {
        const initDropdown = async () => {
            try {
                const bootstrap = await import('bootstrap');
                bootstrapDropdownRef.current = new bootstrap.Dropdown(dropdownButtonRef.current);
            } catch (error) {
                console.error('Error initializing language dropdown:', error);
            }
        };

        initDropdown();

        return () => {
            if (bootstrapDropdownRef.current && bootstrapDropdownRef.current.dispose) {
                bootstrapDropdownRef.current.dispose();
            }
        };
    }, []);

    const changeLanguage = (lng) => {
        // Cambiar el idioma en i18n
        i18n.changeLanguage(lng);

        // Obtener la ruta actual sin el prefijo de idioma si existe
        const pathWithoutLang = location.pathname.replace(/^\/(es|en|de)/, '');

        // Si es español (idioma por defecto) no agregamos prefijo
        const newPath = lng === 'es' ? pathWithoutLang : `/${lng}${pathWithoutLang}`;

        // Navegar a la nueva ruta
        navigate(newPath);

        // Cerrar el dropdown
        if (bootstrapDropdownRef.current) {
            bootstrapDropdownRef.current.hide();
        }
    };

    const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

    return (
        <div className="btn-group">
            <button
                ref={dropdownButtonRef}
                type="button"
                className="btn btn-soft-secondary btn-gray btn-sm dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span className="d-flex align-items-center">
                    <img
                        className="avatar avatar-xss avatar-circle me-2"
                        src={`/assets/img/flags/1x1/${currentLanguage.flag}.svg`}
                        alt={currentLanguage.name}
                        width="16"
                    />
                    <span>{currentLanguage.code.toUpperCase()}</span>
                </span>
            </button>
            <div className="dropdown-menu">
                {languages.map((language) => (
                    <button
                        key={language.code}
                        className={`dropdown-item d-flex align-items-center ${
                            i18n.language === language.code ? 'active' : ''
                        }`}
                        onClick={() => changeLanguage(language.code)}
                    >
                        <img
                            className="avatar avatar-xss avatar-circle me-2"
                            src={`/assets/img/flags/1x1/${language.flag}.svg`}
                            alt={language.name}
                            width="16"
                        />
                        <span className="text-white">{language.name}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default LanguageSelector;