import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../widgets/LanguageSelector';
import {Link} from "react-router-dom";

const TopBar = () => {
  const { t } = useTranslation();

  return (
    <div className="container navbar-topbar">
      <nav className="js-mega-menu navbar-nav-wrap">
        <div className="header-top dark header-top-margin w-100">
          <div className="container">
            <div className="row">
              {/* Social Links Section */}
              <div className="col-xs-3 col-sm-6 col-md-7">
                <div className="header-top-first clearfix">
                  <ul className="social-links circle small clearfix hidden-xs">
                    <li className="twitter">
                      <a target="_blank" href="http://www.twitter.com/wecodex" rel="noopener noreferrer">
                        <i className="bi bi-twitter"></i>
                      </a>
                    </li>
                    <li className="youtube">
                      <a target="_blank" href="http://www.youtube.com/c/WecodexSolutions" rel="noopener noreferrer">
                        <i className="bi bi-youtube"></i>
                      </a>
                    </li>
                    <li className="facebook">
                      <a target="_blank" href="http://www.facebook.com/WecodexOficial" rel="noopener noreferrer">
                        <i className="bi bi-facebook"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="social-links hidden-lg hidden-md hidden-sm circle small">
                    <div className="btn-group dropdown">
                      <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">
                        <i className="bi bi-share"></i>
                      </button>
                      <ul className="dropdown-menu dropdown-animation">
                        <li className="twitter">
                          <a target="_blank" href="https://www.twitter.com/wecodex" rel="noopener noreferrer">
                            <i className="bi bi-twitter"></i>
                          </a>
                        </li>
                        <li className="youtube">
                          <a target="_blank" href="http://www.youtube.com/c/WecodexSolutions" rel="noopener noreferrer">
                            <i className="bi bi-youtube"></i>
                          </a>
                        </li>
                        <li className="facebook">
                          <a target="_blank" href="https://www.facebook.com/WecodexOficial" rel="noopener noreferrer">
                            <i className="bi bi-facebook"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="list-inline hidden-sm hidden-xs">
                    <li>
                      <a style={{ color: 'white' }} href="mailto:info@wecodex.com">
                        <i className="bi bi-envelope"></i> info@wecodex.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-9 col-sm-6 col-md-5">
                <div id="header-top-second" className="clearfix">
                  <div className="header-top-dropdown text-right">
                    <div className="btn-group">

                      <Link to="/login" className="btn btn-default btn-xs m-lg-1 ">
                        <i className="bi bi-person-fill"></i>  {t('layout.topbar.login')}
                      </Link>
                     
                    </div>
                    <LanguageSelector />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TopBar;
