import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import Head from "@/components/layout/Head";
import GallerySwiper from "@components/elements/Swiper";
import api from "@services/api";
import {useNavigate, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import ItemPlaceholder from "@components/elements/Placeholder";
import VideoPlayer from "@components/elements/VideoPlayer";

const ItemViewStore = () => {
    const {t, i18n} = useTranslation();
    const [activeTab, setActiveTab] = useState("featuresOne");
    const [itemData, setItemData] = useState({name: null});
    const [isLoading, setIsLoading] = useState(false);
    const currentLocale = i18n.language;
    const {slug} = useParams();
    const navigate = useNavigate();

    // Definimos los planes de precios
    const pricing = useMemo(() => [
        {
            title: "Hospedaje Mensual",
            description: "Solución completa que incluye hosting, mantenimiento y actualizaciones del sistema.",
            price: 100,
            icon: "bi bi-hdd-rack",
            period: "mes"
        },
        {
            title: "Código Fuente",
            description: "Adquiere el código fuente completo con licencia estándar para uso personal o interno.",
            price: itemData?.price || 0,
            discount: itemData?.discount_percentage || 0,
            discountExpireAt: itemData?.discount_expire_at,
            hasActiveDiscount: itemData?.has_active_discount,
            priceDiscount: itemData?.price_discount,
            icon: "bi bi-braces",
            period: "único pago"
        }
    ], [itemData]);


    const formatGalleryImages = (images) => {
        if (!images) return [];
        return images.map(image => ({
            large: image,
            thumb: image
        }));
    };

    const galleryImages = useMemo(() => {
        if (!itemData.images) return [];
        return formatGalleryImages(itemData.images);
    }, [itemData.images]);


    const getTranslation = (item, field) => {
        const translation = item.translations?.find(
            (t) => t.locale === currentLocale
        );
        const text = translation?.[field] ||
            item.translations?.[0]?.[field] ||
            item[field] ||
            "";

        return text.split('\n').map((line, i) => (
            <React.Fragment key={i}>
                {line}
                {i !== text.split('\n').length - 1 && <br/>}
            </React.Fragment>
        ));
    };

    const getItem = async () => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting(
                "catalogs/products/" + slug,
                {
                    with: ["translations", "technologies", "categories"],
                },
                false
            );
            setItemData(response.data);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getItem();
    }, []);


    const renderPriceCard = (plan) => (
        <div className="card card-sm card-bordered shadow-none mb-2 mt-2 gap-4">
            <div className="card-body">
                <div className="row align-items-sm-center">
                    <div className="col">
                        <div className="ms-2">
                            <h5 className="card-title mb-0">
                                <i className={`${plan.icon} text-primary fs-4`}/> {' '} {plan.title}
                            </h5>
                            <p className="card-text text-size-xs">
                                {plan.description}
                            </p>
                        </div>
                        <div className="d-grid">
                            {plan.title === "Código Fuente" && plan.hasActiveDiscount ? (
                                <div className="text-center">
                                    <span className="text-decoration-line-through text-muted">
                                        ${plan.price} USD
                                    </span>
                                    <span className="badge bg-danger ms-2">
                                        -{plan.discount}%
                                    </span>
                                    <div className="h4 text-primary mb-1">
                                        ${plan.priceDiscount} USD
                                    </div>
                                    {plan.discountExpireAt && (
                                        <small className="text-danger">
                                            <i className="bi-clock"/> Oferta termina el: {
                                            new Date(plan.discountExpireAt).toLocaleDateString()
                                        }
                                        </small>
                                    )}
                                </div>
                            ) : (
                                <a className="btn btn-primary" href="#">
                                    ${plan.price} USD
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    if (isLoading) {
        return <ItemPlaceholder
            config={{
                showImage: true,
                imageHeight: 300,
                priceCards: 2,
                technologies: 3,
                categories: 2,
                features: 3,
                showFileSize: true,
                showDemo: true,
                tabsCount: 4,
                contentLines: 6
            }}
        />;
    }


    if (!itemData.price) {
        return (
            <div id="content" role="main">
                <div className="container content-space-t-4 content-space-b-lg-2 text-center">
                    <img className="img-fluid" src="/assets/svg/illustrations/oc-project-development.svg" width={380}/>
                    <h4 className="p-2">No se encontró el información</h4>
                    <p>Lo sentimos al no se ha encontrado ninguna información de este articulo </p>
                    <button className="btn btn-primary" type="button" onClick={()=>{  navigate("/store");}}>Volver al inicio</button>
                </div>
            </div>);
    }
    return (
        <main id="content" role="main">

            <Head pagePath="store/item"/>


            <div className="container content-space-t-4 content-space-b-lg-2">


                <div className="row">
                    {/* Sidebar */}
                    <div className="col-md-4 col-lg-3 mb-9 mb-md-0">
                        <div className="pe-lg-2">
                            <div className="card card-soft-dark p-2 card-bordered  mb-5">
                                <img className="" src={itemData.preview_image} alt={getTranslation(itemData, "name")}/>
                            </div>

                            <span className="muted">Compra: </span>
                            {pricing.map((plan, index) => renderPriceCard(plan))}

                            <div className="d-flex gap-4 mb-7">
                                <div className="mt-1 gap-4">
                                    <span className="text-muted p-1">Tecnologías:</span>
                                    <br/>
                                    {itemData.technologies?.data?.map((tech) => (
                                        <span key={tech.id} className="badge bg-soft-primary text-primary m-1">
                                            <i className={`${tech.icon} me-1`}/>{" "}
                                            {getTranslation(tech, "alias")}
                                        </span>
                                    ))}

                                    <br/>
                                    <br/>

                                    <span className="text-muted content-space-t-1">Categorías:</span>
                                    <br/>
                                    {itemData.categories?.data?.map((cat) => (
                                        <span key={cat.id} className="badge bg-soft-secondary text-secondary m-1">
                                            <i className="bi-folder me-1"/>{" "}
                                            {getTranslation(cat, "alias")}
                                        </span>
                                    ))}
                                </div>
                            </div>

                            <div className="text-left d-md-block mb-7">
                                <span className="text-muted content-space-t-1">Incluye:</span>
                                <div className="shadow-none">
                                    <div className="card-body">
                                        <ul className="list-checked list-checked-soft-bg-primary">
                                            {itemData.support_documentation && (
                                                <li className="list-checked-item">Documentación</li>
                                            )}
                                            {itemData.support_updates && (
                                                <li className="list-checked-item">Actualizaciones</li>
                                            )}
                                            <li className="list-checked-item">Soporte por {itemData.support_time}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="card card-sm card-bordered border-primary text-center d-md-block mb-7">
                                <i className="bi bi-file-zip fs-1"/>
                                <h5 className="m-0 text-primary">{itemData.file_size || '?'} MB</h5>
                                <span className="small text-muted">Tamaño de proyecto</span>
                            </div>

                            {itemData.demo_url && (
                                <div className="d-none d-md-block mb-7">
                                    <h5>Demo URL</h5>
                                    <a className="link mb-4" target="_blank" href={itemData.demo_url}>
                                        {itemData.demo_url}
                                    </a>

                                    <h6 className="mt-4">Acceso de demostración:</h6>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="demoUser">
                                            <i className="bi bi-people"/> Usuario:
                                        </label>
                                        <input
                                            type="text"
                                            id="demoUser"
                                            value={itemData.demo_user || ''}
                                            className="form-control form-control-sm small"
                                            placeholder="demo@domain.com"
                                            readOnly
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="demoPassword">
                                            <i className="bi bi-unlock"/> Contraseña
                                        </label>
                                        <input
                                            type="text"
                                            id="demoPassword"
                                            value={itemData.demo_password || ''}
                                            className="form-control form-control-sm small"
                                            placeholder="********"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Main Content */}

                    <div className="col-md-8 col-lg-9 column-divider-md">


                        <div className="ps-lg-2">
                            <div className="mb-5">
                                <h2>
                                   <img src="/assets/svg/illustrations/top-vendor.svg" width={30} alt="Sistema verificado por Wecodex" /> {getTranslation(itemData, "name")}
                                </h2>
                                <p>{getTranslation(itemData, "description_short")}</p>
                            </div>

                            {/* Nav Scroller */}
                            <div className="js-nav-scroller hs-nav-scroller-horizontal">
                                <span className="hs-nav-scroller-arrow-prev" style={{display: "none"}}>
                                    <a className="hs-nav-scroller-arrow-link" href="#">
                                        <i className="bi-chevron-left"/>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{display: "none"}}>
                                    <a className="hs-nav-scroller-arrow-link" href="#">
                                        <i className="bi-chevron-right"/>
                                    </a>
                                </span>

                                {/* Nav */}
                                <ul className="nav nav-segment nav-fill mb-7" id="featuresTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className={`nav-link ${activeTab === "featuresOne" ? "active" : ""}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab("featuresOne");
                                            }}
                                            href="#"
                                            style={{minWidth: "7rem"}}
                                        >
                                            <i className="bi bi-file-text-fill"/> Información
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className={`nav-link ${activeTab === "featuresTwo" ? "active" : ""}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab("featuresTwo");
                                            }}
                                            href="#"
                                            style={{minWidth: "7rem"}}
                                        >
                                            <i className="bi bi-images"/> Imágenes
                                        </a>
                                    </li>
                                    {itemData.preview_video && (
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className={`nav-link ${activeTab === "featuresVideo" ? "active" : ""}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setActiveTab("featuresVideo");
                                                }}
                                                href="#"
                                                style={{minWidth: "7rem"}}
                                            >
                                                <i className="bi bi-camera-video-fill"/> Video
                                            </a>
                                        </li>
                                    )}
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className={`nav-link ${activeTab === "featuresThree" ? "active" : ""}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab("featuresThree");
                                            }}
                                            href="#"
                                            style={{minWidth: "7rem"}}
                                        >
                                            <i className="bi bi-ui-checks"/> Requerimientos
                                        </a>
                                    </li>
                                </ul>
                                {/* End Nav */}
                            </div>

                            {/* Tab Content */}
                            <div className="tab-content">
                                {/* Información Tab */}
                                <div className={`tab-pane fade ${activeTab === "featuresOne" ? "show active" : ""}`}>
                                    <div className="pb-4">
                                        <span className="divider-start">
                                            <h4 className="small">
                                                <i className="bi bi-file-text-fill"/> Descripción
                                            </h4>
                                        </span>
                                        <p>{getTranslation(itemData, "description_long")}</p>
                                    </div>

                                    <div className="pb-4">
                                        <span className="divider-start">
                                            <h4 className="small">
                                                <i className="bi bi-ui-checks"/> Características
                                            </h4>
                                        </span>
                                        <p>{getTranslation(itemData, "features")}</p>
                                    </div>
                                </div>

                                {/* Imágenes Tab */}
                                <div className={`tab-pane fade ${activeTab === "featuresTwo" ? "show active" : ""}`}>
                                    <GallerySwiper images={galleryImages}/>
                                </div>
                                <div className={`tab-pane fade ${activeTab === "featuresVideo" ? "show active" : ""}`}>
                                    <VideoPlayer
                                        previewImage={itemData.preview_image}
                                        videoUrl={itemData.preview_video}
                                    />
                                </div>

                                {/* Requerimientos Tab */}
                                <div className={`tab-pane fade ${activeTab === "featuresThree" ? "show active" : ""}`}>
                                    <h4 className="mb-4">Requerimientos del Sistema</h4>
                                    <div className="card card-bordered shadow-none">
                                        <div className="card-body">
                                            <pre className="language-markup" style={{whiteSpace: 'pre-wrap'}}>
                                                {itemData.requirements}
                                            </pre>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <div className="card card-bordered shadow-none">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ItemViewStore;