import React from 'react';
import PropTypes from 'prop-types';

const WizardNavigation = ({
                              steps,
                              currentStep,
                              onStepClick,
                              position = 'left',
                              validationEnabled = false,
                              validateCurrentStep
                          }) => {
    const getStepClasses = (index) => {
        const baseClasses = "relative flex items-center group";
        const isActive = index === currentStep;
        const isCompleted = index < currentStep;

        return `wizard-step ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''} relative mb-2 last:mb-0`;
    };

    const getLineStyles = (index) => {
        const isLast = index === steps.length - 1;
        if (isLast) return {};

        if (position === 'left' || position === 'right') {
            return {
                position: 'absolute',
                left: '15px',
                top: '2.5rem',
                bottom: '-1rem',
                width: '2px',
                background: index < currentStep ? 'var(--bs-primary)' : '#e9ecef'
            };
        }

        return {
            position: 'absolute',
            left: '60%',
            right: '-0%',
            top: '20px',
            height: '2px',
            background: index < currentStep ? 'var(--bs-primary)' : '#e9ecef'
        };
    };

    const containerClasses = {
        top: 'flex-row justify-between w-full mb-8',
        bottom: 'flex-row justify-between w-full mt-8',
        left: 'flex-col w-full md:w-auto',
        right: 'flex-col w-full md:w-auto'
    };

    return (
        <div className={`wizard-container wizard-${position}`}>
            <ul className={`wizard-steps list-none p-0 flex ${containerClasses[position]}`}>
                {steps.map((step, index) => (
                    <li
                        key={index}
                        className={getStepClasses(index)}
                        onClick={() => {
                            if (!validationEnabled || (index < currentStep || validateCurrentStep())) {
                                onStepClick(index);
                            }
                        }}
                        style={{ cursor: validationEnabled && index > currentStep ? 'not-allowed' : 'pointer' }}
                    >
                        {/* Número o ícono del paso */}
                        <div className={`
                            wizard-step-icon
                            flex items-center justify-center
                            w-8 h-8 rounded-full 
                            ${index === currentStep
                            ? 'bg-primary text-white ring-4 ring-primary-100'
                            : index < currentStep
                                ? 'bg-success text-white'
                                : 'bg-gray-100 text-gray-500'
                        }
                            transition-all duration-200
                            ${position === 'left' || position === 'right' ? 'mr-3' : 'mb-2'}
                        `}>
                            {index < currentStep ? (
                                <i className="bi-check-lg text-sm"/>
                            ) : (
                                step.icon ? <i className={`${step.icon} text-sm`}/> :
                                    <span className="text-sm">{index + 1}</span>
                            )}
                        </div>

                        {/* Título y descripción */}
                        <div className="flex flex-col">
                            <span className={`
                                text-sm font-medium
                                ${index === currentStep ? 'text-primary' : 'text-gray-900'}
                            `}>
                                {step.title}
                            </span>
                            {step.description && (
                                <span className="text-xs text-gray-500 mt-0.5">
                                    {step.description}
                                </span>
                            )}
                        </div>

                        {/* Línea conectora */}
                        {index < steps.length - 1 && (
                            <div className="wizard-step-line" style={getLineStyles(index)} />
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

WizardNavigation.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        icon: PropTypes.string,
        className: PropTypes.string
    })).isRequired,
    currentStep: PropTypes.number.isRequired,
    onStepClick: PropTypes.func.isRequired,
    position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    validationEnabled: PropTypes.bool,
    validateCurrentStep: PropTypes.func
};

export default WizardNavigation;