import React from 'react';
import PropTypes from 'prop-types';

const FilePreview = ({ field, filePreview, formData, onRemove }) => {
    const renderFileItem = (file, index = null, multiple = false) => {
        // Obtener el nombre del archivo
        const getFileName = (file) => {
            if (typeof file === 'string') {
                return file.split('/').pop().split('?')[0];
            }
            if (file instanceof File) {
                return file.name;
            }
            return 'Archivo';
        };

        // Determinar tipo de archivo
        const isImage = (file) => {
            if (typeof file === 'string') {
                return /\.(jpg|jpeg|png|gif|webp)/i.test(file);
            }
            return file instanceof File && file.type.startsWith('image/');
        };

        const isVideo = (file) => {
            if (typeof file === 'string') {
                return /\.(mp4|webm|ogg)/i.test(file);
            }
            return file instanceof File && file.type.startsWith('video/');
        };

        return (
            <div className={multiple ? "col-md-3 col-sm-3" : "col-md-12 col-sm-12"} key={index}>
                <div className="card h-100">
                    <div className="card-pinned">
                        {isImage(file) && (
                            <>
                                <img
                                    src={typeof file === 'string' ? file : filePreview[field.name]}
                                    alt={getFileName(file)}
                                    className="card-img-top"
                                    style={{aspectRatio: '16/9', objectFit: 'cover'}}
                                />
                                <div className="card-pinned-top-start">
                                    <span className="badge bg-primary">Imagen</span>
                                </div>
                            </>
                        )}

                        {isVideo(file) && (
                            <>
                                <div className="video-player-preview">
                                    <video
                                        className="card-img-top"
                                        style={{aspectRatio: '16/9', objectFit: 'cover'}}
                                    >
                                        <source
                                            src={typeof file === 'string' ? file : filePreview[field.name]}
                                            type={file instanceof File ? file.type : 'video/mp4'}
                                        />
                                    </video>
                                    <span className="video-player-icon">
                                        <i className="bi-play-fill"/>
                                    </span>
                                </div>
                                <div className="card-pinned-top-start">
                                    <span className="badge bg-primary">Video</span>
                                </div>
                            </>
                        )}

                        {!isImage(file) && !isVideo(file) && (
                            <>
                                <div className="card-body text-center py-5">
                                    <i className="bi-file-earmark-text display-4"/>
                                    <p className="mt-2 mb-0">{getFileName(file)}</p>
                                </div>
                                <div className="card-pinned-top-start">
                                    <span className="badge bg-primary">Archivo</span>
                                </div>
                            </>
                        )}

                        <div className="card-pinned-top-end">
                            <button
                                type="button"
                                className="btn btn-soft-danger btn-xs"
                                onClick={() => onRemove(field.name, field.multiple ? index : null)}
                            >
                                <i className="bi-trash"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const preview = filePreview[field.name];
    const value = formData[field.name];

    if (!preview && !value) return null;

    return (
        <div className="file-preview mt-3">
            <div className="row g-2">
                {field.multiple
                    ? (Array.isArray(value) ? value : [value]).map((file, index) =>
                        renderFileItem(file, index, field.multiple))
                    : renderFileItem(value, null, field.multiple)
                }
            </div>
        </div>
    );
};

FilePreview.propTypes = {
    field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        multiple: PropTypes.bool
    }).isRequired,
    filePreview: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default FilePreview;