import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setSessionUser, updateSessionUser} from "../../store/slice/userSlice";
import api from "../../services/api";
import Select from "../../components/elements/Select";
import {toast} from "react-toastify";

const Profile = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [avatar, setAvatar] = useState(user?.avatar || '/assets/img/160x160/img9.jpg');
    const [formData, setFormData] = useState({
        name: user?.name || '',
        email: user?.email || '',
        phone: user?.phone || '',
        country_id: Number(user?.country_id) || '',
        gender: user?.gender || 'other',
    });

    useEffect(() => {
        if (user) {
            setFormData({
                name: user.name || '',
                email: user.email || '',
                phone: user.phone || '',
                country_id: Number(user.country_id) || '',
                gender: user.gender || 'other'
            });
            if (user.avatar) {
                setAvatar(user.avatar);
            }
        }
    }, [user]);

    const handleDeleteAvatar = () => {
        setAvatar('/assets/img/160x160/img1.jpg');
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            setError(null);
            setSuccessMessage('');

            const response = await api.putAwaiting('user/update', formData, true);

            const updateData = {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                country_id: Number(formData.country_id),

            };


            dispatch(setSessionUser(updateData));
            toast.success(response.data.message);


        } catch (err) {
            setError(t('pages.panel.profile.messages.updateError'));
            console.error('Error updating profile:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCountryChange = (value) => {
        setFormData(prev => ({
            ...prev,
            country_id: Number(value)
        }));
    };

    if (!user) {
        return <div className="text-center p-5">{t('pages.panel.profile.messages.loading')}</div>;
    }

    return (
        <div className="col-lg-12">
            <div className="d-grid gap-3 gap-lg-5">
                <div className="card">
                    <div className="card-header border-bottom">
                        <h4 className="card-header-title">{t('pages.panel.profile.basic.title')}</h4>
                    </div>


                    <div className="card-body">
                        <form>
                            <div className="row mb-4">
                                <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">
                                    {t('pages.panel.profile.basic.fullName.label')}
                                    <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip"
                                       data-bs-placement="top" title={t('pages.panel.profile.basic.fullName.tooltip')}/>
                                </label>

                                <div className="col-sm-9">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="firstName"
                                            id="firstNameLabel"
                                            value={formData.name}
                                            onChange={e => setFormData(prev => ({...prev, name: e.target.value}))}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <label htmlFor="emailLabel" className="col-sm-3 col-form-label form-label">
                                    {t('pages.panel.profile.basic.email.label')}
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        disabled
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="emailLabel"
                                        value={formData.email}

                                    />
                                </div>
                            </div>

                            <div className="row mb-4">
                                <label htmlFor="phoneLabel" className="col-sm-3 col-form-label form-label">
                                    {t('pages.panel.profile.basic.phone.label')}
                                    <span
                                        className="form-label-secondary">{t('pages.panel.profile.basic.phone.optional')}</span>
                                </label>

                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            name="phone"
                                            id="phoneLabel"
                                            value={formData.phone}
                                            onChange={e => setFormData(prev => ({...prev, phone: e.target.value}))}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="input-group">
                                        <Select
                                            renderOptions={{
                                                size: {width: '20px', height: '20px'},
                                                path_concat: '/assets/img/flags/1x1',
                                                field_concat: 'code',
                                                extension_concat: 'svg'
                                            }}
                                            endpoint="catalogs/countries"
                                            filter={{'filter': 'all'}}
                                            selected={formData.country_id}
                                            onChange={handleCountryChange}
                                        />
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>

                    <div className="card-footer pt-0">
                        <div className="d-flex justify-content-end gap-3">
                            <button className="btn btn-white" type="button">
                                {t('pages.panel.profile.buttons.cancel')}
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                {t('pages.panel.profile.buttons.save')}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header border-bottom">
                        <h4 className="card-header-title">{t('pages.panel.profile.delete.title')}</h4>
                    </div>

                    <div className="card-body">
                        <p className="card-text">{t('pages.panel.profile.delete.description')}</p>

                        <div className="mb-4">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="deleteAccountCheckbox"/>
                                <label className="form-check-label" htmlFor="deleteAccountCheckbox">
                                    {t('pages.panel.profile.delete.confirm')}
                                </label>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-danger">
                                {t('pages.panel.profile.delete.button')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;