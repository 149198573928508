import React, {useState, useEffect} from 'react';
import InitialAvatar from "@components/widgets/Avatar";
import {useTranslation} from 'react-i18next';
import api from "@services/api";
import ModuleConstructor from "@components/constructors/ModuleConstructor/ModuleConstructor";

const Clients = () => {
    const {t} = useTranslation();
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const endpoint = 'user';


    const getRecords = async () => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting(endpoint, {filter: 'all'}, true);
            setRecords(response.data);
        } catch (error) {
            console.error('Error fetching records:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getRecords().then();
    }, []);

    const handleCreate = async (data) => {
        try {
            setIsLoading(true);
            await api.postAwaiting(endpoint, data, true);
            await getRecords();
            return true;
        } catch (error) {
            console.error('Error creating record:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdate = async (data, code) => {
        try {
            setIsLoading(true);
            await api.putAwaiting(endpoint + '/' + code, data, true);
            await getRecords();
            return true;
        } catch (error) {
            console.error('Error updating record:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (code) => {
        try {
            setIsLoading(true);
            await api.deleteAwaiting(endpoint + '/' + code, {code: code}, true);
            await getRecords();
            return true;
        } catch (error) {
            console.error('Error deleting record:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const config = {
        table: {
            headers: [
                {title: t('pages.panel.clients.headers.name')},
                {title: t('pages.panel.clients.headers.status')},
                {title: t('pages.panel.clients.headers.phone')},
                {title: t('pages.panel.clients.headers.country')},
                {title: t('pages.panel.clients.headers.actions'), width: '5%'}
            ],
            columns: [
                {
                    key: 'user',
                    render: (item) => (
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <InitialAvatar name={item.name} size={35}/>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="text-hover-primary mb-0">
                                    {item.name}
                                    {item.verified && (
                                        <img
                                            className="avatar avatar-xss ms-1"
                                            src="/assets/svg/illustrations/top-vendor.svg"
                                            alt="Verified"
                                        />
                                    )}
                                </h6>
                                <small>{item.email}</small>
                            </div>
                        </div>
                    )
                },
                {
                    key: 'status',
                    render: (item) => (
                        <span className={`badge bg-soft-${item.status.color} text-${item.status.color}`}>
                            {item.status.name}
                        </span>
                    )
                },
                {
                    key: 'phone',
                    render: (item) => <span>{item.phone || '-'}</span>
                },
                {
                    key: 'country',
                    render: (item) => <span>{item.country?.name || '-'}</span>
                },
            ],
            searchKeys: ['name', 'email']
        },
        form: {
            fields: [
                {
                    name: 'name',
                    type: 'text',
                    required: true,
                    icon: 'bi-person',
                    label: t('pages.panel.clients.form.name.label'),
                    placeholder: t('pages.panel.clients.form.name.placeholder'),
                    tooltip: t('pages.panel.clients.form.name.tooltip')
                },
                {
                    name: 'email',
                    type: 'email',
                    disabled: false,
                    disabledOnEdit: true,
                    icon: 'bi-envelope',
                    label: t('pages.panel.clients.form.email.label'),
                    placeholder: t('pages.panel.clients.form.email.placeholder')
                },
                {
                    name: 'phone',
                    type: 'tel',
                    icon: 'bi-telephone',
                    label: t('pages.panel.clients.form.phone.label'),
                    placeholder: t('pages.panel.clients.form.phone.placeholder')
                },
                {
                    name: 'phone',
                    type: 'tel',
                    icon: 'bi-telephone',
                    label: t('pages.panel.clients.form.phone.label'),
                    placeholder: t('pages.panel.clients.form.phone.placeholder')
                },
                {
                    name: 'country_id',
                    type: 'select',
                    endpoint: 'catalogs/countries',
                    filter: {filter: 'all'},
                    label: t('pages.panel.clients.form.country_id.label'),
                    required: true
                }
            ]
        },
        titles: {
            main: <><i className="bi bi-people-fill"></i> {t('pages.panel.clients.titles.main')}</>,
            createButton: t('pages.panel.clients.buttons.create')
        }
    };

    return (
        <ModuleConstructor
            config={config}
            records={records}
            isLoading={isLoading}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
        />
    );
};

export default Clients;