import React, { useEffect } from 'react';

const FacebookComments = ({ url }) => {
    useEffect(() => {
        // Inicializar el SDK de Facebook
        const initFacebookSDK = () => {
            if (window.FB) {
                window.FB.XFBML.parse();
            } else {
                window.fbAsyncInit = function() {
                    window.FB.init({
                        appId: 'TU_APP_ID', // Reemplaza con tu App ID de Facebook
                        xfbml: true,
                        version: 'v18.0'
                    });
                    window.FB.XFBML.parse();
                };

                // Cargar el SDK de Facebook
                (function(d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "https://connect.facebook.net/es_LA/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            }
        };

        initFacebookSDK();

        // Limpieza
        return () => {
            // Eliminar el script de Facebook si existe
            const facebookScript = document.getElementById('facebook-jssdk');
            if (facebookScript) {
                facebookScript.remove();
            }
        };
    }, []);

    return (
        <div className="facebook-comments-container">
            <div id="fb-root"></div>
            <div
                className="fb-comments"
                data-href={url}
                data-width="100%"
                data-numposts="10"
                data-order-by="social" // Ordenar por relevancia social
                data-colorscheme="light" // o "dark" para modo oscuro
            />
        </div>
    );
};

export default FacebookComments;