import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ErrorPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="container text-center">
            <div className="mb-3">
                <img
                    className="img-fluid"
                    src="/assets/svg/illustrations/oc-error.svg"
                    alt={t('pages.error_page.imageAlt')}
                    style={{ width: '30rem' }}
                />
            </div>

            <div className="mb-4">
                <p className="fs-4 mb-0">{t('pages.error_page.message1')}</p>
                <p className="fs-4">
                    {t('pages.error_page.message2')} <a className="link" href="#">{t('pages.error_page.contactLink')}</a>.
                </p>
            </div>

            <button className="btn btn-primary" onClick={() => navigate(-1)}>
                {t('pages.error_page.goBack')}
            </button>
        </div>
    );
}

export default ErrorPage;
