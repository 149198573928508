// AccountStep.js
import React from 'react';
import {Link} from 'react-router-dom';

const AccountStep = ({t, register, loading, showPassword, setRegister, setShowPassword, registerAccount, renderPasswordInput}) => (
    <form className="js-validate needs-validation" noValidate>
        <div className="mb-4">
            <label className="form-label" htmlFor="signupName">
                {t('pages.auth.register_confirm.form.name.label')}
            </label>
            <input
                type="text"
                className="form-control form-control-lg"
                name="name"
                id="signupName"
                placeholder={t('pages.auth.register_confirm.form.name.placeholder')}
                value={register.name}
                onChange={(e) => setRegister(prev => ({...prev, name: e.target.value}))}
            />
        </div>

        <div className="mb-4">
            <label className="form-label" htmlFor="signupEmail">
                {t('pages.auth.register_confirm.form.email.label')}
            </label>
            <input
                type="email"
                className="form-control form-control-lg"
                name="email"
                id="signupEmail"
                placeholder={t('pages.auth.register_confirm.form.email.placeholder')}
                value={register.email}
                onChange={(e) => setRegister(prev => ({...prev, email: e.target.value}))}
            />
        </div>

        <div className="mb-4">
            <label className="form-label" htmlFor="signupPassword">
                {t('pages.auth.register_confirm.form.password.label')}
            </label>
            {renderPasswordInput('password', t('pages.auth.register_confirm.form.password.placeholder'))}
        </div>

        <div className="mb-4">
            <label className="form-label" htmlFor="signupConfirmPassword">
                {t('pages.auth.register_confirm.form.password.confirmation.label')}
            </label>
            {renderPasswordInput('password_confirmation', t('pages.auth.register_confirm.form.password.confirmation.placeholder'))}
        </div>

        <div className="d-grid mb-4">
            <button
                className="btn btn-primary btn-lg"
                onClick={registerAccount}
                disabled={loading}
                type="button"
            >
                {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
                {t('pages.auth.register_confirm.buttons.confirm')}
            </button>
        </div>

        <div className="text-center">
            <p>{t('pages.auth.register_confirm.footer.login')}{' '}
                <Link to="/login" className="link">
                    {t('pages.auth.register_confirm.footer.login_link')}
                </Link>
            </p>
        </div>
    </form>
);

// VerificationStep.js
const VerificationStep = ({t, register, loading, countDown, resendConfirmation}) => (
    <div className="text-center">
        <h4 className="mb-4">{t('pages.auth.register_confirm.steps.verification.title')}</h4>
        <i className="bi bi-envelope fa-2 mb-4 text-primary"/>
        <p>{t('pages.auth.register_confirm.steps.verification.description')}</p>
        <p className="fw-bold mb-4">{register.email}</p>

        <div className="mt-4">
            <h5>{t('pages.auth.register_confirm.steps.verification.check_spam')}</h5>
        </div>

        {countDown > 0 && (
            <p className="text-primary">
                {t('pages.auth.register_confirm.steps.verification.resend.countdown', {seconds: countDown})}
            </p>
        )}

        <button
            className="btn btn-primary"
            onClick={resendConfirmation}
            disabled={countDown > 0 || loading}
        >
            {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
            {t('pages.auth.register_confirm.steps.verification.resend.button')}
        </button>
    </div>
);

// SetPasswordStep.js
const SetPasswordStep = ({t, register, loading, showPassword, passwordValidation, passwordRequirements, renderPasswordInput, confirmPassword}) => (
    <div>
        <div className="text-center mb-4">
            <h4>{t('pages.auth.register_confirm.steps.set_password.title')}</h4>
            <p>{t('pages.auth.register_confirm.steps.set_password.description')}</p>
        </div>

        <div className="mb-4">
            <label className="form-label">
                {t('pages.auth.register_confirm.form.password.label')}
            </label>
            {renderPasswordInput('password')}
        </div>

        <div className="mb-4">
            <label className="form-label">
                {t('pages.auth.register_confirm.form.password.confirmation.label')}
            </label>
            {renderPasswordInput('password_confirmation')}
        </div>

        <div className="mb-4">
            <label className="form-label">
                {t('pages.auth.register_confirm.steps.set_password.requirements.title')}
            </label>
            <ul className="list-unstyled">
                {passwordRequirements.map((requirement) => (
                    <li
                        key={requirement.key}
                        className={`mb-2 ${passwordValidation[requirement.condition] ? 'text-success' : ''}`}
                    >
                        <i className={`bi bi-${passwordValidation[requirement.condition] ? 'check-circle-fill' : 'circle'} me-2`}></i>
                        {t(`pages.auth.register_confirm.steps.set_password.requirements.${requirement.key}`)}
                    </li>
                ))}
            </ul>
        </div>

        <div className="d-grid">
            <button
                className="btn btn-primary btn-lg"
                onClick={confirmPassword}
                disabled={!passwordValidation.valid_password || loading}
            >
                {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
                <i className="fa fa-lock me-2"></i>
                {t('pages.auth.register_confirm.buttons.set_password')}
            </button>
        </div>
    </div>
);

// SuccessStep.js
const SuccessStep = ({t, verify, navigate}) => (
    <div className="text-center">
        <h4 className="mb-4">{t('pages.auth.register_confirm.steps.success.title')}</h4>
        <i className="fa fa-check-circle fa-4x text-success mb-4"></i>
        <p className="mb-4" dangerouslySetInnerHTML={{
            __html: verify.response.split(',')[1] || t('pages.auth.register_confirm.steps.success.description')
        }}/>
        <button
            className="btn btn-primary"
            onClick={() => navigate('/login')}
        >
            {t('pages.auth.register_confirm.steps.success.login_button')}
        </button>
    </div>
);

// ExpiredStep.js
const ExpiredStep = ({t, navigate}) => (
    <div className="text-center">

        <h4 className="mb-4">{t('pages.auth.register_confirm.steps.expired.title')}</h4>
        <h1><i className=" bi bi-stopwatch   text-warning "/></h1>

        <p className="small">{t('pages.auth.register_confirm.steps.expired.description')}</p>
        <p className="small">{t('pages.auth.register_confirm.steps.expired.action')}</p>
        <div className="mt-4">
            <button
                className="btn btn-primary"
                onClick={() => navigate('/login')}
            >
                {t('pages.auth.register_confirm.buttons.login')}
            </button>
        </div>
    </div>
);

// VerifyStep.js
const VerifyStep = ({t, verify}) => (
    <div className="text-center">
        <h4 className="mb-4">{t('pages.auth.register_confirm.steps.verify.title')}</h4>
        {verify.status === '' ? (
            <div className="spinner-grow text-primary mb-4" role="status">
                <span className="visually-hidden">{t('pages.auth.register_confirm.steps.verify.description')}</span>
            </div>
        ) : verify.status === false ? (
            <i className="fa fa-exclamation-triangle fa-4x text-warning mb-4"></i>
        ) : null}
        <p className="mb-4">{verify.response}</p>
        <Link
            to="/login"
            className="btn btn-link"
        >
            {t('pages.auth.register_confirm.buttons.login')}
        </Link>
    </div>
);

// PasswordChangedStep.js
const PasswordChangedStep = ({t, navigate}) => (
    <div className="text-center">
        <h4 className="mb-4">{t('pages.auth.register_confirm.steps.password_changed.title')}</h4>
        <p>{t('pages.auth.register_confirm.steps.password_changed.description')}</p>
        <i className="fa fa-check-circle fa-4x text-success mb-4"></i>
        <div className="mt-4">
            <button
                className="btn btn-primary"
                onClick={() => navigate('/login')}
            >
                {t('pages.auth.register_confirm.buttons.login')}
            </button>
        </div>
    </div>
);

export {
    AccountStep,
    VerificationStep,
    SetPasswordStep,
    SuccessStep,
    ExpiredStep,
    VerifyStep,
    PasswordChangedStep
};