import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Head from "@/components/layout/Head";
import {Search, ChevronRight} from 'lucide-react';
import api from '@/services/api'; // Asegúrate de tener la importación correcta
import TypedText from '@/components/elements/TypedText'

const Category = () => {
    const {t} = useTranslation();
    const {i18n} = useTranslation();
    const currentLocale = i18n.language;

    // Estados para las diferentes secciones
    const [categories, setCategories] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState({
        categories: false,
        technologies: false,
        products: false
    });

    // Estados de paginación para productos
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 15
    });

    const backgroundImages = [
        'url(assets/img/400x500/img13.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(assets/img/400x500/img14.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(assets/img/400x500/img15.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(assets/img/400x500/img16.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(assets/img/400x500/img17.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(assets/img/400x500/img18.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales

    ];


    // Función helper para traducciones
    const getTranslation = (item, field) => {
        const translation = item.translations?.find(t => t.locale === currentLocale);
        return translation?.[field] || item.translations?.[0]?.[field] || item[field] || '';
    };

    // Obtener categorías
    const getCategories = async () => {
        try {
            setIsLoading(prev => ({...prev, categories: true}));
            const response = await api.getAwaiting('catalogs/categories', {
                with: ['translations'],
            }, true);
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setIsLoading(prev => ({...prev, categories: false}));
        }
    };

    // Obtener tecnologías
    const getTechnologies = async () => {
        try {
            setIsLoading(prev => ({...prev, technologies: true}));
            const response = await api.getAwaiting('catalogs/technologies', {
                with: ['translations'],
            }, true);
            setTechnologies(response.data);
        } catch (error) {
            console.error('Error fetching technologies:', error);
        } finally {
            setIsLoading(prev => ({...prev, technologies: false}));
        }
    };

    // Obtener productos
    const getProducts = async (page = 1, perPage = 15, search = '') => {
        try {
            setIsLoading(prev => ({...prev, products: true}));
            const response = await api.getAwaiting('catalogs/products', {
                filter: 'all',
                page,
                per_page: perPage,
                search,
                with: ['translations', 'technologies', 'categories']
            }, true);

            setProducts(response.data);
            setPagination({
                currentPage: response.current_page,
                lastPage: response.last_page,
                total: response.total,
                perPage: response.per_page
            });
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setIsLoading(prev => ({...prev, products: false}));
        }
    };

    const CategoryMenuItem = ({category, currentLocale, getTranslation}) => {
        const [isOpen, setIsOpen] = useState(true); // Cambiado a true
        const hasSubcategories = category.subcategories && category.subcategories.length > 0;

        return (
            <div className="nav-item">
                <div
                    className={`nav-link d-flex justify-content-between align-items-center cursor-pointer ${isOpen ? '' : ''}`}
                    onClick={() => hasSubcategories && setIsOpen(!isOpen)}
                    role="button"
                    tabIndex={0}
                >
                    <Link to={'/blog/category/' + getTranslation(category, 'slug')}>
                        <div className="d-flex align-items-center gap-2 text-dark">
                            <i className={category.icon}></i>
                            <span>{getTranslation(category, 'name')}</span>
                        </div>
                    </Link>
                    {hasSubcategories && (
                        <div className="ms-auto">
                            <i className={`bi bi-chevron-${isOpen ? 'down' : 'right'}`}></i>
                        </div>
                    )}
                </div>

                {hasSubcategories && isOpen && (
                    <div className="ms-3 border-start ps-3 my-2">
                        {category.subcategories.map(subcategory => (
                            <CategoryMenuItem
                                key={subcategory.id}
                                category={subcategory}
                                currentLocale={currentLocale}
                                getTranslation={getTranslation}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    }; // Cargar datos iniciales
    useEffect(() => {
        const loadInitialData = async () => {
            await Promise.all([
                getCategories(),
                getTechnologies(),
                getProducts()
            ]);
        };

        loadInitialData();
    }, []);

    // Búsqueda
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (e) => {
        e.preventDefault();
        getProducts(1, pagination.perPage, searchTerm);
    };

    return (
        <>
            <Head pagePath="store/home"/>

            <div className="position-relative bg-dark pb-2 zi-2 overflow-hidden">
                <div
                    className="container position-relative content-space-t-4   content-space-t-lg-4 content-space-b pb-4">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto  mb-md-1">

                        <div className="mb-4">
                            <h1 className="text-white">Tienda de desarrollos</h1>
                            <p>Encuentra herramientas que se adapten a tus necesidades</p>
                        </div>
                        <form onSubmit={handleSearch}>
                            <div className="input-card border">
                                <div className="input-card-form">
                                    <label htmlFor="searchAppsForm" className="form-label visually-hidden">
                                        Buscar aplicaciones
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="searchAppsForm"
                                        placeholder="Busquemos algo..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    <i className="bi-search"/>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <main id="content" role="main">
                {/* Hero Section */}


                {/* Card Grid */}
                <div className="container content-space-b-2  content-space-t-2 content-space-b-lg-3">


                    <div className="row col-lg-divider">
                        <div className="col-lg-3">
                            {/* Navbar */}
                            {/* Navbar */}
                            <div className="navbar-expand-lg">
                                {/* Navbar Toggle */}
                                <div className="d-grid">
                                    <button
                                        type="button"
                                        className="navbar-toggler btn btn-white rounded mb-3"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarVerticalNavMenu"
                                        aria-label="Toggle navigation"
                                    >
            <span className="d-flex justify-content-between align-items-center">
                <span className="text-dark">Menu</span>
                <span className="navbar-toggler-default">
                    <i className="bi-list"></i>
                </span>
                <span className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                </span>
            </span>
                                    </button>
                                </div>

                                {/* Navbar Collapse - Añadida la clase show */}
                                <div id="navbarVerticalNavMenu" className=" show navbar-collapse">
                                    <div className="d-grid gap-4 flex-grow-1">
                                        <div className="nav nav-sm nav-pills nav-vertical">
                                            <h5 className="nav-subtitle">Categorías</h5>
                                            {isLoading.categories ? (
                                                <div>Loading categories...</div>
                                            ) : (
                                                categories
                                                    .filter(category => !category.parent_id)
                                                    .map(category => (
                                                        <CategoryMenuItem
                                                            key={category.id}
                                                            category={category}
                                                            currentLocale={currentLocale}
                                                            getTranslation={getTranslation}
                                                        />
                                                    ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9">
                            <div className="ps-lg-2">

                                {/* Products Section */}
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="mb-0"><i className="bi bi-box-seam-fill"/> Articulos</h4>
                                    <a className="link" href="#">
                                        Ver todo <i className="bi-chevron-right small ms-1"></i>
                                    </a>
                                </div>

                                <div className="row gx-3 mx-n2 mb-7">
                                    {isLoading.products ? (
                                        <div>Loading products...</div>
                                    ) : (
                                        products.map(product => (
                                            <>
                                                <div key={product.id} className="col-sm-6 col-md-6 mb-3 mb-md-0">
                                                    <Link
                                                        to={`/store/item/${product.slug}`}
                                                        className="card card-bordered card-transition shadow-none h-100"
                                                    >
                                                        <figure
                                                            className="device-browser small card-img-top border-bottom ">
                                                            <div className="device-browser-header  pb-0">
                                                                <div className="device-browser-header-btn-list">
                                                                    <span
                                                                        className="device-browser-header-btn-list-btn bg-danger"></span>
                                                                    <span
                                                                        className="device-browser-header-btn-list-btn bg-warning"></span>
                                                                    <span
                                                                        className="device-browser-header-btn-list-btn bg-success"></span>
                                                                </div>
                                                                <div className="p-2"></div>

                                                            </div>

                                                            <div className="device-browser-frame">
                                                                <img className="w-100  " src={product.preview_image}
                                                                     alt={getTranslation(product, 'name')}/>

                                                            </div>
                                                        </figure>

                                                        <div className="card-body">
                                                            <div className="d-flex align-items-center mb-2">
                                                                <h5 className="card-title mb-0">
                                                                    {getTranslation(product, 'name')}


                                                                </h5>
                                                            </div>


                                                            <p className="card-text small text-body">
                                                                {getTranslation(product, 'description_short')}
                                                            </p>
                                                            <hr/>

                                                            <div className="mt-1 d-flex flex-wrap gap-1 small">
                                                                <span className="text-muted">Tecnologias:</span>
                                                                {product.technologies?.data?.map(tech => (
                                                                    <span key={tech.id}
                                                                          className="badge bg-soft-primary text-primary">
                                                             <i className={`${tech.icon} me-1`}/> {getTranslation(tech, 'alias')}
                                                         </span>
                                                                ))}
                                                                <br/>
                                                                <span className="text-muted">Categorias:</span>
                                                                {product.categories?.data?.map(cat => (
                                                                    <span key={cat.id}
                                                                          className="badge bg-soft-secondary text-secondary">
                                                            <i className="bi-folder me-1"/> {getTranslation(cat, 'alias')}
                                                        </span>
                                                                ))}
                                                            </div>


                                                            <div className="row col-sm-divider mt-5">
                                                                <div className="col-sm text-end">
                                                                    <span className="d-block h3 mb-0">$72.46</span>
                                                                    <small className="d-block text-muted">Precio</small>
                                                                </div>

                                                                <div className="col-sm text-start">
    <span className="d-block h4 text-danger mb-0">
      <i className="bi-graph-up"></i> 12%
    </span>
                                                                    <small
                                                                        className="d-block text-muted">Descuento</small>
                                                                </div>
                                                            </div>

                                                            <div className="row col-sm-divider mt-5">
                                                                <button type="button"
                                                                        className="btn btn-primary btn-sm ">Ver mas
                                                                    información
                                                                </button>
                                                            </div>


                                                        </div>
                                                    </Link>
                                                </div>

                                            </>
                                        ))
                                    )}
                                </div>

                                {/* Technologies Section */}
                                <div className="row gx-3 mx-n2 mb-7">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h4 className="mb-0"><i className="bi bi-cpu-fill"/> Tecnologias</h4>
                                        <a className="link" href="#">
                                            Ver todas <i className="bi-chevron-right small ms-1"></i>
                                        </a>
                                    </div>

                                    {isLoading.technologies ? (
                                        <div>Loading technologies...</div>
                                    ) : (
                                        technologies.map((tech, index) => (
                                            <div key={tech.id} className="col-sm-3 col-lg-3 mb-4">
                                                <Link
                                                    to={`/store/technology/${tech.slug}`}
                                                    className="card card-sm card-stretched-vertical card-transition bg-img-start h-100"
                                                    style={{
                                                        backgroundImage: backgroundImages[index % backgroundImages.length],
                                                        minHeight: '5rem',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    {/* Overlay para mejorar la legibilidad del texto */}
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgb(0 0 0 / 0%));'
                                                    }}></div>

                                                    <div className=" text-center position-relative pt-2">
                                                        {/* Badge o subtítulo */}
                                                        <div>
                                                            <i className={`bi ${tech.icon} text-white fs-1`}></i>
                                                        </div>
                                                        {/* Título */}
                                                        <h6 className=" text-white small">
                                                            {getTranslation(tech, 'name')}
                                                        </h6>
                                                        <p className="text-white text-size-xs  ">{getTranslation(tech, 'description')}</p>


                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    )}
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Category;