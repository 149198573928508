

import React, {useEffect, useState, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import api from "../../services/api";

const Select = ({
                    reducer,
                    mode,
                    endpoint,
                    filter,
                    options,
                    selected,
                    onChange,
                    disabled,
                    multiple,
                    showPills,
                    maxPills,
                    renderOptions,
                    placeholder
                }) => {
    const {t} = useTranslation();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState({});
    const selectRef = useRef(null);

    const selectedValues = useMemo(() => {
        if (!selected) return [];

        // Si selected es un array y tiene la estructura {data, selected}
        if (Array.isArray(selected) && selected[0]?.data !== undefined) {
            return selected[0].selected || [];
        }

        // Si selected es un array simple
        if (Array.isArray(selected)) {
            return selected.filter(value =>
                typeof value === 'number' || typeof value === 'string'
            );
        }

        // Si es un solo valor
        return [selected];
    }, [selected]);

    const finalReducer = {
        field_label: 'name',
        field_value: 'id',
        searchKeys: [],
        ...reducer
    };

    const searchInItem = (item, term) => {
        const searchValue = term.toLowerCase();

        const displayValue = getDisplayValue(item);
        if (displayValue?.toString().toLowerCase().includes(searchValue)) {
            return true;
        }

        return finalReducer.searchKeys?.some(key => {
            const parts = key.split('.');
            let value = item;

            for (const part of parts) {
                if (Array.isArray(value?.[part])) {
                    value = value[part][0];
                    continue;
                }
                value = value?.[part];
            }

            return value?.toString().toLowerCase().includes(searchValue);
        }) || false;
    };

    const getNestedValue = (obj, path) => {
        if (!obj || !path) return '';

        if (mode === 'standard') {
            return obj[path];
        }

        let value = obj;
        const parts = path.split('.');

        for (let part of parts) {
            value = Array.isArray(value[part]) ? value[part][0] : value[part];
            if (!value) break;
        }

        return value || '';
    };

    const getDisplayValue = (item) => {
        if (!item) return '';

        if (mode === 'standard') {
            return item[finalReducer.field_label];
        }

        let value = item;
        const parts = finalReducer.field_label.split('.');

        for (let part of parts) {
            if (Array.isArray(value?.[part])) {
                value = value[part][0];
            } else {
                value = value?.[part];
            }
            if (!value) break;
        }

        return value || '';
    };

    const updateDropdownPosition = () => {
        if (selectRef.current) {
            const rect = selectRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const dropdownHeight = 320;
            const spaceBelow = windowHeight - rect.bottom;
            const shouldDropUp = spaceBelow < dropdownHeight && rect.top > dropdownHeight;

            setDropdownStyle({
                position: 'fixed',
                top: shouldDropUp ? 'auto' : `${rect.bottom}px`,
                bottom: shouldDropUp ? `${windowHeight - rect.top}px` : 'auto',
                left: `${rect.left}px`,
                width: `${rect.width}px`,
                maxHeight: `${Math.min(dropdownHeight, shouldDropUp ? rect.top - 10 : windowHeight - rect.bottom - 10)}px`,
                zIndex: 9999,
            });
        }
    };

    useEffect(() => {
        if (mode === 'api' && endpoint) {
            const fetchOptions = async () => {
                try {
                    setIsLoading(true);
                    const response = await api.getAwaiting(endpoint, filter);
                    setItems(response.data);
                } catch (err) {
                    console.error('Error fetching options:', err);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchOptions();
        } else if (mode === 'standard') {
            setItems(options);
            setIsLoading(false);
        }
    }, [mode, endpoint, JSON.stringify(filter), JSON.stringify(options)]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
                setSearchTerm('');
            }
        };

        const handleScroll = () => {
            if (isOpen) {
                updateDropdownPosition();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll, true);
        window.addEventListener('resize', handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll, true);
            window.removeEventListener('resize', handleScroll);
        };
    }, [isOpen]);

    const handleSelect = (option) => {
        const value = option[finalReducer.field_value];

        if (multiple) {
            const newValues = selectedValues.includes(value)
                ? selectedValues.filter(v => v !== value)
                : [...selectedValues, value];
            onChange?.(newValues);
        } else {
            onChange?.(value);
            setIsOpen(false);
        }
        setSearchTerm('');
    };

    const handleToggleDropdown = () => {
        if (!isLoading && !disabled) {
            if (!isOpen) {
                updateDropdownPosition();
            }
            setIsOpen(!isOpen);
        }
    };

    const removeSelected = (valueToRemove, e) => {
        e?.stopPropagation();
        const newValues = selectedValues.filter(value => value !== valueToRemove);
        onChange?.(multiple ? newValues : null);
    };

    const renderOption = (option) => {
        // Primero verificamos si renderOptions existe y es una función
        if (typeof renderOptions === 'function') {
            return renderOptions(option);
        }

        const displayName = getDisplayValue(option);

        if (option.icon) {
            return (
                <span className="d-flex align-items-center">
                    <i className={`${option.icon} me-2`}/>
                    <span className="text-truncate">{displayName}</span>
                </span>
            );
        }

        return <span className="text-truncate">{displayName}</span>;
    };

    const renderPills = () => {
        if (!showPills) return null;

        if (!selectedValues.length) {
            return (
                <div className="d-flex align-items-center justify-content-between">
                    <span className="text-muted small">
                        {multiple ? 'Selecciona una o varias opciones' : placeholder}
                    </span>
                    <i className={`bi-chevron-${isOpen ? 'up' : 'down'}`}/>
                </div>
            );
        }

        const dataToUse = mode === 'standard' ? options : items;
        const selectedItems = dataToUse.filter(item =>
            selectedValues.includes(item[finalReducer.field_value])
        );
        const displayItems = selectedItems.slice(0, maxPills);
        const remainingCount = selectedItems.length - maxPills;

        return (
            <div className="d-flex flex-wrap gap-1 mb-2">
                {displayItems.map(item => (
                    <span key={item[finalReducer.field_value]}
                          className="badge bg-soft-primary text-primary bg-sm bg-primary small d-flex align-items-center">
                        {item.icon && <i className={`${item.icon} me-1`}/>}
                        {getDisplayValue(item)}

                        <a type="button"
                           className="text-black p-0 ms-2"
                           onClick={(e) => removeSelected(item[finalReducer.field_value], e)}
                        >
                            <i className="bi-x"/>
                        </a>
                    </span>
                ))}
                {remainingCount > 0 && (
                    <span className="badge bg-secondary">
                        +{remainingCount} más
                    </span>
                )}
            </div>
        );
    };

    const filteredOptions = useMemo(() => {
        const dataToUse = mode === 'standard' ? options : items;

        if (!dataToUse || !Array.isArray(dataToUse)) {
            return [];
        }

        if (!searchTerm) return dataToUse;

        return dataToUse.filter(option => searchInItem(option, searchTerm));
    }, [items, options, searchTerm, mode, reducer]);

    return (
        <div className="custom-select-container" ref={selectRef}>
            <div
                className={`custom-select-header form-select ${isOpen ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
                onClick={handleToggleDropdown}
            >
                {isLoading ? (
                    <div className="d-flex align-items-center text-muted">
                        <div className="spinner-border spinner-border-sm me-2" role="status"/>
                        <span>Cargando...</span>
                    </div>
                ) : (
                    <div className="w-100">
                        {multiple && showPills ? (
                            renderPills()
                        ) : (
                            <div className="d-flex align-items-center justify-content-between">
                                <span className={!selectedValues.length ? 'text-muted small' : ''}>
                                    {selectedValues.length ? (
                                        multiple
                                            ? `${selectedValues.length} seleccionados`
                                            : getDisplayValue(
                                            (mode === 'standard' ? options : items).find(
                                                item => item[finalReducer.field_value] === selectedValues[0]
                                            )
                                            )
                                    ) : (
                                        <span className="text-muted">
                                            {placeholder}
                                        </span>
                                    )}
                                </span>
                                <i className={`bi-chevron-${isOpen ? 'up' : 'down'}`}/>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {isOpen && !isLoading && !disabled && (
                <div className="custom-select-dropdown shadow-sm" style={dropdownStyle}>
                    <div className="p-2 border-bottom">
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Buscar..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                    <div className="custom-select-options">
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option) => (
                                <div
                                    key={option[finalReducer.field_value]}
                                    className={`custom-select-option ${
                                        selectedValues.includes(option[finalReducer.field_value]) ? 'selected' : ''
                                    }`}
                                    onClick={() => handleSelect(option)}
                                >
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                        {renderOption(option)}
                                        {selectedValues.includes(option[finalReducer.field_value]) && (
                                            <i className="bi-check2 text-primary"/>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-center py-2 text-muted">
                                No se encontraron resultados
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

Select.propTypes = {
    reducer: PropTypes.shape({
        field_label: PropTypes.string,
        field_value: PropTypes.string,
        searchKeys: PropTypes.arrayOf(PropTypes.string)
    }),
    mode: PropTypes.oneOf(['api', 'standard']),
    endpoint: PropTypes.string,
    filter: PropTypes.object,
    options: PropTypes.array,
    selected: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({
            data: PropTypes.array,
            selected: PropTypes.array
        })
    ]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    showPills: PropTypes.bool,
    maxPills: PropTypes.number,
    renderOptions: PropTypes.func,
    placeholder: PropTypes.string
};

Select.defaultProps = {
    reducer: {
        field_label: 'name',
        field_value: 'id',
        searchKeys: []
    },
    mode: 'api',
    options: [],
    disabled: false,
    multiple: false,
    showPills: false,
    maxPills: 2,
    placeholder: 'Selecciona una opción...'
};

export default Select;
