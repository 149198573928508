import React from 'react';
import { useTranslation } from 'react-i18next';
import Head from "../components/layout/Head";

const Privacy = () => {
    const { t } = useTranslation();

    return (
        <>
            <Head pagePath="privacy" />

            {/* Content */}
            <div className="container content-space-3 content-space-lg-4">
                <div className="card card-lg">
                    {/* Header */}
                    <div className="card-header bg-dark py-sm-10">
                        <h1 className="card-title h2 text-white">{t('pages.privacy.hero.title')}</h1>
                        <p className="card-text text-white">{t('pages.privacy.hero.lastModified')}</p>
                    </div>

                    {/* Card Body */}
                    <div className="card-body">
                        {/* Mapping through all sections */}
                        {Object.entries(t('pages.privacy.sections', { returnObjects: true })).map(([key, section]) => (
                            <div key={key} className="mb-7">
                                <h4>{section.title}</h4>
                                {section.content.map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))}
                                {section.bullets && (
                                    <ul>
                                        {section.bullets.map((bullet, index) => (
                                            <li key={index}>{bullet}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Privacy;