import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewsletterSubscribe from "../../components/widgets/NewsletterSubscribe";
import Head from "../../components/layout/Head";

const Api = () => {
    const { t } = useTranslation();

    return (
        <>
            {/* Head Section */}
            <Head pagePath="services.api" />


            {/* Hero Section */}
            <div className="gradient-y-sm-primary position-relative zi-2 overflow-hidden">
                <div className="container position-relative content-space-t-4 content-space-t-lg-4 content-space-b-1">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <h3 className="display-6 mb-3">{t('pages.services.api.hero.title', 'Arquitectura de Servicios Empresariales')}</h3>
                        <p>{t('pages.services.api.hero.description', 'Soluciones flexibles que combinan lo mejor de SOA y Microservicios para adaptarse a sus necesidades específicas.')}</p>
                    </div>
                </div>


            </div>

            {/* Image Section */}
            <div className="text-center mb-5 mt-5">
                <img src="/assets/img/others/soa.png" className="img-thumbnail img-fluid" width="700px" alt="API Architecture" />
            </div>

            {/* Features Section */}
            <div className="overflow-hidden">
                <div className="container content-space-t-2 content-space-t-lg-3 content-space-b-1 content-space-b-lg-3">
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <h2>{t('pages.services.api.features.title', 'Enfoque Híbrido y Flexible')}</h2>
                        <p>{t('pages.services.api.features.description', 'Combinamos la robustez de SOA con la agilidad de los Microservicios para crear soluciones empresariales adaptables')}</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 d-none d-lg-block">
                            <div className="position-relative pe-lg-4">
                                <a className="card card-transition shadow-none bg-img-start" href="#">
                                    <div className="card-body">
                                        <h4 className="card-title">{t('pages.services.api.features.architecture.title', 'Arquitectura Híbrida')}</h4>
                                        <p className="card-text text-body">
                                            {t('pages.services.api.features.architecture.description', 'Diseñamos su arquitectura combinando SOA y Microservicios según sus necesidades específicas de negocio y escalabilidad.')}
                                        </p>
                                    </div>
                                    <div className="card-footer pt-0">
                                        <span className="card-link">
                                            {t('pages.services.api.features.architecture.cta', 'Explorar soluciones')} <i className="bi-chevron-right small ms-1"></i>
                                        </span>
                                    </div>
                                </a>

                                <div className="position-absolute bottom-0 start-0 zi-n1 mb-n7 ms-n7" style={{ width: "12rem" }}>
                                    <img className="w-100" src="/assets/svg/components/dots-lg.svg" alt="SVG" />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="row">
                                {/* SOA Feature */}
                                <div className="col-sm-6 mb-3 mb-sm-7">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10.9607 12.9128H18.8607C19.4607 12.9128 19.9607 13.4128 19.8607 14.0128C19.2607 19.0128 14.4607 22.7128 9.26068 21.7128C5.66068 21.0128 2.86071 18.2128 2.16071 14.6128C1.16071 9.31284 4.96069 4.61281 9.86069 4.01281C10.4607 3.91281 10.9607 4.41281 10.9607 5.01281V12.9128V12.9128Z" fill="#035A4B"/>
                                            </svg>
                                        </span>
                                        <h3 className="h4">{t('pages.services.api.features.soa.title', 'SOA Empresarial')}</h3>
                                        <p>{t('pages.services.api.features.soa.description', 'Integración centralizada para sistemas legacy y procesos de negocio complejos mediante ESB y servicios orquestados.')}</p>
                                    </div>
                                </div>

                                {/* Microservices Feature */}
                                <div className="col-sm-6 mb-3 mb-sm-7">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M16 0.200012H4C1.8 0.200012 0 2.00001 0 4.20001V16.2C0 18.4 1.8 20.2 4 20.2H16C18.2 20.2 20 18.4 20 16.2V4.20001C20 2.00001 18.2 0.200012 16 0.200012Z" fill="#035A4B"/>
                                            </svg>
                                        </span>
                                        <h4>{t('pages.services.api.features.microservices.title', 'Microservicios Cloud')}</h4>
                                        <p>{t('pages.services.api.features.microservices.description', 'Servicios independientes y escalables en la nube para aplicaciones modernas y desarrollo ágil.')}</p>
                                    </div>
                                </div>

                                {/* Gateway Feature */}
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725V8.725Z" fill="#035A4B"/>
                                            </svg>
                                        </span>
                                        <h4>{t('pages.services.api.features.gateway.title', 'Gateway Unificado')}</h4>
                                        <p>{t('pages.services.api.features.gateway.description', 'Portal único de acceso que gestiona tanto servicios SOA como microservicios con seguridad integrada.')}</p>
                                    </div>
                                </div>

                                {/* DevOps Feature */}
                                <div className="col-sm-6">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z" fill="#035A4B"/>
                                            </svg>
                                        </span>
                                        <h4>{t('pages.services.api.features.devops.title', 'DevOps & Monitoreo')}</h4>
                                        <p>{t('pages.services.api.features.devops.description', 'Herramientas unificadas para despliegue, monitoreo y mantenimiento de toda su arquitectura de servicios.')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="container content-space-t-2 content-space-lg-2">
                <div className="w-lg-75 mx-lg-auto">
                    <div className="card card-sm overflow-hidden">
                        <div className="card-body">
                            <div className="row justify-content-md-start align-items-md-center text-center text-md-start">
                                <div className="col-md offset-md-3 mb-3 mb-md-0">
                                    <h4 className="card-title">{t('pages.services.api.cta.question', '¿Tienes una idea o algo en mente?')}</h4>
                                </div>
                                <div className="col-md-auto">
                                    <Link className="btn btn-primary btn-transition" to="/contact">
                                        {t('pages.services.api.cta.button', '¡Contáctanos ahora!')}
                                    </Link>
                                </div>
                            </div>

                            <figure className="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
                                <img className="card-img" src="/assets/img/icons/idea.png" style={{width:'70px', textAlign: 'center'}} alt="Idea Icon" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>



            <NewsletterSubscribe />
        </>
    );
};

export default Api;