import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewsletterSubscribe from '../../components/widgets/NewsletterSubscribe';
import Head from "../../components/layout/Head";

const Web = () => {
    const { t } = useTranslation();
    const [slideIndex, setSlideIndex] = useState(1);
    const [projectTitle, setProjectTitle] = useState('');
    // Servicios detallados
    const services = [
        {
            title: "Desarrollo Web Custom",
            description: "Aplicaciones web a medida con las últimas tecnologías",
            features: [
                "Arquitectura escalable",
                "Diseño responsivo",
                "Alto rendimiento",
                "SEO optimizado"
            ]
        },
        {
            title: "Software Empresarial",
            description: "Sistemas robustos para gestión empresarial",
            features: [
                "ERP personalizado",
                "CRM integrado",
                "Business Intelligence",
                "Reportes avanzados"
            ]
        },
        {
            title: "E-commerce",
            description: "Plataformas de comercio electrónico completas",
            features: [
                "Múltiples pasarelas de pago",
                "Gestión de inventario",
                "Marketing integrado",
                "Analytics avanzado"
            ]
        },
        {
            title: "Integraciones",
            description: "Conectamos sistemas y automatizamos procesos",
            features: [
                "APIs RESTful",
                "Webhooks",
                "Microservicios",
                "ETL personalizado"
            ]
        }
    ];

    const projects = [
        {
            title: "Sistema de rastreo",
            image: "/assets/projects/web/school.png"
        },
        {
            title: "Sistema de restaurante",
            image: "/assets/projects/web/restaurant.png"
        },
        {
            title: "Sistema de tienda online",
            image: "/assets/projects/web/storexpress.png"
        },
        {
            title: "Sistema de inventario y venta",
            image: "/assets/projects/web/inventory.jpg"
        },
        {
            title: "Sistema de facturación SAT",
            image: "/assets/projects/web/sat.png"
        }
    ];

    useEffect(() => {
        setProjectTitle(projects[slideIndex - 1].title);

        const interval = setInterval(() => {
            plusSlides(1);
        }, 5000);

        return () => clearInterval(interval);
    }, [slideIndex]);

    const plusSlides = (n) => {
        let newIndex = slideIndex + n;
        if (newIndex > projects.length) newIndex = 1;
        if (newIndex < 1) newIndex = projects.length;
        setSlideIndex(newIndex);
    };

    return (
        <>

            {/* Head Section */}
            <Head pagePath="services.web" />


            <div className="gradient-y-sm-primary position-relative zi-2 overflow-hidden">
                <div className="container position-relative content-space-t-5 content-space-t-lg-4 content-space-b-2">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <h3 className="display-6 mb-3">{t('pages.services.web.hero.title')}</h3>
                        <p>{t('pages.services.web.hero.description')}</p>
                    </div>
                </div>

                <div className="shape shape-bottom zi-1">
                    <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 500H3000V0L0 500Z" fill="#fff" />
                    </svg>
                </div>
            </div>

            <div className="overflow-hidden">
                <div className="container content-space-t-1 content-space-b-2 content-space-b-lg-4">
                    <div className="row">
                        <div className="col-lg-4 d-none d-lg-block">
                            <div className="position-relative pe-lg-4">
                                <a className="card card-transition shadow-none bg-img-start" href="#">
                                    <div className="card-body">
                                        <h4 className="card-title">{t('pages.services.web.features.architecture.title')}</h4>
                                        <p className="card-text text-body">{t('pages.services.web.features.architecture.description')}</p>
                                    </div>
                                    <div className="card-footer pt-0">
                                        <span className="card-link">{t('pages.services.web.features.architecture.cta')} <i className="bi-chevron-right small ms-1"></i></span>
                                    </div>
                                </a>

                                <div className="position-absolute bottom-0 start-0 zi-n1 mb-n7 ms-n7" style={{width: "12rem"}}>
                                    <img className="w-100" src="/assets/svg/components/dots-lg.svg" alt="SVG" />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-sm-6 mb-3 mb-sm-7">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10.9607 12.9128H18.8607C19.4607 12.9128 19.9607 13.4128 19.8607 14.0128C19.2607 19.0128 14.4607 22.7128 9.26068 21.7128C5.66068 21.0128 2.86071 18.2128 2.16071 14.6128C1.16071 9.31284 4.96069 4.61281 9.86069 4.01281C10.4607 3.91281 10.9607 4.41281 10.9607 5.01281V12.9128V12.9128Z" fill="#035A4B" />
                                                <path d="M12.9607 10.9128V3.01281C12.9607 2.41281 13.4607 1.91281 14.0607 2.01281C16.0607 2.21281 17.8607 3.11284 19.2607 4.61284C20.6607 6.01284 21.5607 7.91285 21.8607 9.81285C21.9607 10.4129 21.4607 10.9128 20.8607 10.9128H12.9607V10.9128Z" fill="#035A4B" />
                                            </svg>
                                        </span>
                                        <h3 className="h4">{t('pages.services.web.features.integration.title')}</h3>
                                        <p>{t('pages.services.web.features.integration.description')}</p>
                                    </div>
                                </div>

                                <div className="col-sm-6 mb-3 mb-sm-7">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M16 0.200012H4C1.8 0.200012 0 2.00001 0 4.20001V16.2C0 18.4 1.8 20.2 4 20.2H16C18.2 20.2 20 18.4 20 16.2V4.20001C20 2.00001 18.2 0.200012 16 0.200012ZM15 10.2C15 10.9 14.8 11.6 14.6 12.2H18V16.2C18 17.3 17.1 18.2 16 18.2H12V14.8C11.4 15.1 10.7 15.2 10 15.2C9.3 15.2 8.6 15 8 14.8V18.2H4C2.9 18.2 2 17.3 2 16.2V12.2H5.4C5.1 11.6 5 10.9 5 10.2C5 9.50001 5.2 8.80001 5.4 8.20001H2V4.20001C2 3.10001 2.9 2.20001 4 2.20001H8V5.60001C8.6 5.30001 9.3 5.20001 10 5.20001C10.7 5.20001 11.4 5.40001 12 5.60001V2.20001H16C17.1 2.20001 18 3.10001 18 4.20001V8.20001H14.6C14.8 8.80001 15 9.50001 15 10.2Z" fill="#035A4B" />
                                                <path d="M12 1.40002C15.4 2.20002 18 4.80003 18.8 8.20003H14.6C14.1 7.00003 13.2 6.10003 12 5.60003V1.40002V1.40002ZM5.40001 8.20003C5.90001 7.00003 6.80001 6.10003 8.00001 5.60003V1.40002C4.60001 2.20002 2.00001 4.80003 1.20001 8.20003H5.40001ZM14.6 12.2C14.1 13.4 13.2 14.3 12 14.8V19C15.4 18.2 18 15.6 18.8 12.2H14.6V12.2ZM8.00001 14.8C6.80001 14.3 5.90001 13.4 5.40001 12.2H1.20001C2.00001 15.6 4.60001 18.2 8.00001 19V14.8V14.8Z" fill="#035A4B" />
                                            </svg>
                                        </span>
                                        <h4>{t('pages.services.web.features.development.title')}</h4>
                                        <p>{t('pages.services.web.features.development.description')}</p>
                                    </div>
                                </div>

                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725V8.725Z" fill="#035A4B" />
                                                <path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="#035A4B" />
                                            </svg>
                                        </span>
                                        <h4>{t('pages.services.web.features.optimization.title')}</h4>
                                        <p>{t('pages.services.web.features.optimization.description')}</p>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z" fill="#035A4B" />
                                                <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z" fill="#035A4B" />
                                            </svg>
                                        </span>
                                        <h4>{t('pages.services.web.features.analytics.title')}</h4>
                                        <p>{t('pages.services.web.features.analytics.description')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sección de Servicios Detallados */}
            <div className="container content-space-1">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5">
                    <h2>Nuestros Servicios</h2>
                    <p>Soluciones completas para tu negocio</p>
                </div>

                <div className="row">
                    {services.map((service, index) => (
                        <div key={index} className="col-md-6 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h3 className="card-title">{service.title}</h3>
                                    <p className="card-text">{service.description}</p>
                                    <ul className="list-unstyled mb-0">
                                        {service.features.map((feature, idx) => (
                                            <li key={idx} className="mb-2">
                                                <i className="bi-check-circle-fill text-success me-2"></i>
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="container">
                <div className="w-lg-75 mx-lg-auto">
                    <div className="card card-sm overflow-hidden">
                        <div className="card-body">
                            <div className="row justify-content-md-start align-items-md-center text-center text-md-start">
                                <div className="col-md offset-md-3 mb-3 mb-md-0">
                                    <h4 className="card-title">{t('pages.services.web.cta.question')}</h4>
                                </div>
                                <div className="col-md-auto">
                                    <a className="btn btn-primary btn-transition" href="#">
                                        {t('pages.services.web.cta.button')}
                                    </a>
                                </div>
                            </div>

                            <figure className="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
                                <img className="card-img" src="/assets/img/icons/idea.png" style={{width:'70px', textAlign: 'center'}} alt="Idea" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container content-space-t-1 content-space-lg-1">
                <div className="tab-content" id="featuresTabContent">
                    <div className="tab-pane fade show active" id="featuresOne" role="tabpanel" aria-labelledby="featuresOne-tab">
                        <div className="devices">
                            <figure className="device-browser">
                                <div className="device-browser-header">
                                    <div className="device-browser-header-btn-list">
                                        <span className="device-browser-header-btn-list-btn"></span>
                                        <span className="device-browser-header-btn-list-btn"></span>
                                        <span className="device-browser-header-btn-list-btn"></span>
                                    </div>
                                    <div className="device-browser-header-browser-bar">
                                        <span id="project-name">{projectTitle}</span>
                                    </div>
                                </div>

                                <div className="device-browser-frame slideshow-container">
                                    {projects.map((project, index) => (
                                        <div
                                            key={index}
                                            className="device-browser-img"
                                            style={{
                                                display: index + 1 === slideIndex ? 'block' : 'none',
                                                width: '100%'
                                            }}
                                        >
                                            <img
                                                src={project.image}
                                                alt={project.title}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    ))}
                                    <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
                                    <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>

            <NewsletterSubscribe />

        </>
    );
};

export default Web;