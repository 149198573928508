import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import InitialAvatar from "@components/widgets/Avatar";
import api from "@services/api";
import ModuleConstructor from "@components/constructors/ModuleConstructor/ModuleConstructor";

const BlogPosts = () => {
    const {t, i18n} = useTranslation();
    const currentLocale = i18n.language;
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 10
    });
    const endpoint = 'auth/blog-posts';

    const getTranslation = (item, field) => {
        if (!item) return '';
        if (!item.translations || !Array.isArray(item.translations)) {
            return item[field] || '';
        }
        const translation = item.translations.find(t => t.locale === currentLocale);

        if (translation && translation[field]) {
            return translation[field];
        }

        if (item.translations.length > 0 && item.translations[0][field]) {
            return item.translations[0][field];
        }

        return item[field] || '';
    };


    const getRecords = async (page = 1, perPage = 10, search = '') => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting(endpoint, {
                page,
                per_page: perPage,
                search,
                with: ['translations', 'technologies', 'categories'] // Añadir ambas relaciones
            }, true);

            setRecords(response.data);
            setPagination({
                currentPage: response.current_page,
                lastPage: response.last_page,
                total: response.total,
                perPage: response.per_page
            });
        } catch (error) {
            console.error('Error fetching records:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getRecords().then();
    }, []);

    const handleCreate = async (data) => {
        try {
            const formData = new FormData();

            // Archivos
            if (data.preview_image instanceof File) {
                formData.append('preview_image', data.preview_image);
            }

            if (data.preview_video instanceof File) {
                formData.append('preview_video', data.preview_video);
            }

            if (Array.isArray(data.images)) {
                data.images.forEach((file, index) => {
                    if (file instanceof File) {
                        formData.append(`images[${index}]`, file);
                    }
                });
            }



            // Otros campos
            Object.keys(data).forEach(key => {
                if (!['preview_image', 'preview_video', 'images'].includes(key)) {
                    formData.append(key, data[key]);
                }
            });

            formData.append(`technologies`, data.technologies.selected);
            formData.append(`categories`, data.categories.selected);


            await api.postAwaiting(endpoint, formData, true, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            await getRecords(pagination.currentPage, pagination.perPage);
            return true;
        } catch (error) {
            console.error('Error creating record:', error);
            return false;
        }
    };

    const handleUpdate = async (data, code) => {
        try {
            const formData = new FormData();

            if (data.preview_image instanceof File) {
                formData.append('preview_image', data.preview_image);
            }

            if (data.preview_video instanceof File) {
                formData.append('preview_video', data.preview_video);
            }

            if (Array.isArray(data.images)) {
                data.images.forEach((file, index) => {
                    if (file instanceof File) {
                        formData.append(`images[${index}]`, file);
                    }
                });
            }


            // All fields except
            Object.keys(data).forEach(key => {
                if (!['preview_image', 'preview_video', 'images'].includes(key)) {
                    formData.append(key, data[key]);
                }
            });


            // Control de selecteds
            formData.append(`technologies`, data.technologies.selected);
            formData.append(`categories`, data.categories.selected);


            // Realizar la petición como POST pero con _method = PUT
            const response = await api.putAwaiting(`${endpoint}/${code}`, formData, true, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            await getRecords(pagination.currentPage, pagination.perPage);
            return true;
        } catch (error) {
            console.error('Error updating record:', error);
            return false;
        }
    };


    const handleDelete = async (code) => {
        try {
            setIsLoading(true);
            await api.deleteAwaiting(`${endpoint}/${code}`, {code}, true);
            await getRecords(pagination.currentPage, pagination.perPage);
            return true;
        } catch (error) {
            console.error('Error deleting record:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const config = {
        modal: {size: 'xl'},
        table: {
            headers: [
                {title: t('pages.panel.blog_posts.headers.post'),width: '50%'},
                {title: t('pages.panel.blog_posts.headers.status')},
                {title: t('pages.panel.blog_posts.headers.actions'), width: '10%'}
            ],
            columns: [
                {
                    key: 'post',
                    render: (item) => (
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                {item.preview_image ? (
                                    <img
                                        src={item.preview_image}
                                        alt={getTranslation(item, 'title')}
                                        className="avatar avatar-lg rounded"
                                        style={{width: '64px', height: '64px', objectFit: 'cover'}}
                                    />
                                ) : (
                                    <InitialAvatar
                                        name={getTranslation(item, 'title')}
                                        size={64}
                                        className="rounded"
                                    />
                                )}
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h5 className="text-hover-primary mb-0">
                                    {getTranslation(item, 'title')}
                                </h5>
                                <p className="text-muted text-wrap text-break">
                                    {getTranslation(item, 'description_short')}
                                </p>
                                <div className="mt-1  flex-wrap gap-1">

                                    <span>Tecnologias:</span>
                                    {item.technologies?.data?.map(tech => (
                                        <span key={tech.id} className="badge bg-soft-primary text-primary">
                                            <i className={`${tech.icon} me-1`}/> {getTranslation(tech, 'alias')}
                                        </span>
                                    ))}
                                    <br/>
                                    <span>Categorias:</span>
                                    {item.categories?.data?.map(cat => (
                                        <span key={cat.id} className="badge bg-soft-secondary text-secondary">
                                            <i className="bi-folder me-1"/> {getTranslation(cat, 'alias')}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                },
                {
                    key: 'status',
                    render: (item) => (
                        <div className="">
                            <span className={`badge bg-${item.is_public ? 'success' : 'warning'}`}>
                                {item.is_public ? 'Publicado' : 'Borrador'}
                            </span>
                        </div>
                    )
                }
            ],
            searchKeys: ['translations.title', 'translations.description_short', 'technology.translations.name'],
            pagination: {
                enabled: true,
                serverSide: true,
                currentPage: pagination.currentPage,
                lastPage: pagination.lastPage,
                total: pagination.total,
                perPage: pagination.perPage,
                onChange: (page) => getRecords(page, pagination.perPage),
                onPerPageChange: (perPage) => getRecords(1, perPage)
            },
            search: {
                serverSide: true,
                onSearch: (term) => getRecords(1, pagination.perPage, term)
            }
        },
        form: {
            mode: 'wizard',
            wizard: {
                position: 'left',
                validation_step: true
            },
            fields: [
                {
                    separator: {
                        text: t('pages.panel.blog_posts.separators.general'),
                        icon: 'bi-info-circle',
                        className: 'divider-center'
                    }
                },
                {
                    'component': <div className="col-md-12 pb-2 pt-4"><b className="divider-start">Configuración</b>
                    </div>
                },

                {
                    name: 'is_bot',
                    type: 'switch',
                    class: 'col mb-3',
                    label: t('pages.panel.blog_posts.form.is_bot.label'),
                    hint: t('pages.panel.blog_posts.form.is_bot.hint')
                },
                {
                    name: 'is_top',
                    type: 'switch',
                    class: 'col mb-3',
                    label: t('pages.panel.blog_posts.form.is_top.label'),
                    hint: t('pages.panel.blog_posts.form.is_top.hint')
                },
                {
                    name: 'is_public',
                    type: 'switch',
                    class: 'col mb-3',
                    label: t('pages.panel.blog_posts.form.is_public.label'),
                    hint: t('pages.panel.blog_posts.form.is_public.hint')
                },
                {
                    name: 'is_searchable',
                    type: 'switch',
                    class: 'col mb-3',
                    label: t('pages.panel.blog_posts.form.is_searchable.label'),
                    hint: t('pages.panel.blog_posts.form.is_searchable.hint')
                },
                {
                    name: 'allow_comments',
                    type: 'switch',
                    class: 'col mb-3',
                    label: t('pages.panel.blog_posts.form.allow_comments.label'),
                    hint: t('pages.panel.blog_posts.form.allow_comments.hint')
                },

                {
                    'component': <div className="col-md-12 pb-2 pt-4"><b className="divider-start">Compartir
                        automaticamente en:</b></div>
                },
                {
                    name: 'share_facebook',
                    type: 'box_icon',
                    class: 'col mb-3',
                    icon: 'bi-facebook',
                    label: t('pages.panel.blog_posts.form.share_facebook.label'),
                    color: 'primary'
                },
                {
                    name: 'share_instagram',
                    type: 'box_icon',
                    class: 'col mb-3',
                    icon: 'bi-instagram',
                    label: t('pages.panel.blog_posts.form.share_instagram.label'),
                    color: 'danger'
                },
                {
                    name: 'share_twitter',
                    type: 'box_icon',
                    class: 'col mb-3',
                    icon: 'bi-twitter',
                    label: t('pages.panel.blog_posts.form.share_twitter.label'),
                    color: 'info'
                },
                {
                    name: 'share_linkedin',
                    type: 'box_icon',
                    class: 'col mb-3',
                    icon: 'bi-linkedin',
                    label: t('pages.panel.blog_posts.form.share_linkedin.label'),
                    color: 'primary'
                },
                {
                    name: 'share_pinterest',
                    type: 'box_icon',
                    class: 'col mb-3',
                    icon: 'bi-pinterest',
                    label: t('pages.panel.blog_posts.form.share_pinterest.label'),
                    color: 'danger'
                },

                {'component': <div className="col-md-12 pb-2 pt-4"><b className="divider-start">Información</b></div>},

                {
                    name: 'title',
                    type: 'text',
                    required: true,
                    class: 'col-12 mb-3',
                    icon: 'bi-type-h1',
                    label: t('pages.panel.blog_posts.form.title.label'),
                    placeholder: t('pages.panel.blog_posts.form.title.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },
                {
                    name: 'description_short',
                    type: 'textarea',
                    required: true,
                    class: 'col-12 mb-3',
                    icon: 'bi-text-paragraph',
                    label: t('pages.panel.blog_posts.form.description_short.label'),
                    placeholder: t('pages.panel.blog_posts.form.description_short.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },
                {
                    name: 'description_long',
                    type: 'textarea',
                    required: true,
                    class: 'col-12 mb-3',
                    icon: 'bi-text-paragraph',
                    label: t('pages.panel.blog_posts.form.description_long.label'),
                    placeholder: t('pages.panel.blog_posts.form.description_long.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es'
                },
                {
                    name: 'content',
                    type: 'editor',
                    required: true,
                    class: 'col-12 mb-4',
                    icon: 'bi-file-earmark-richtext',
                    label: t('pages.panel.blog_posts.form.content.label'),
                    placeholder: t('pages.panel.blog_posts.form.content.placeholder'),
                    multilocale: true,
                    maxLength: 50000, // O el límite que necesites
                    defaultLocale: 'es'
                },
                {
                    separator: {
                        text: t('pages.panel.blog_posts.separators.media'),
                        icon: 'bi-images',
                        className: 'divider-center'
                    }
                },
                {
                    name: 'preview_image',
                    type: 'file',
                    required: true,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-image',
                    label: t('pages.panel.blog_posts.form.preview_image.label'),
                    accept: 'image/*',
                    preview: true,
                    maxSize: 20 * 1024 * 1024,
                    fileTypes: ['image/jpeg', 'image/png', 'image/webp']
                },
                {
                    name: 'preview_video',
                    type: 'file',
                    required: false,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-camera-video',
                    label: t('pages.panel.blog_posts.form.preview_video.label'),
                    accept: 'video/*',
                    preview: true,
                    maxSize: 100 * 1024 * 1024,
                    fileTypes: ['video/mp4', 'video/webm']
                },
                {
                    name: 'images',
                    type: 'file',
                    required: false,
                    class: 'col-12 mb-3',
                    icon: 'bi-images',
                    label: t('pages.panel.blog_posts.form.images.label'),
                    accept: 'image/*',
                    multiple: true,
                    preview: true,
                    maxSize: 20 * 1024 * 1024,
                    fileTypes: ['image/jpeg', 'image/png', 'image/webp']
                },
                {
                    separator: {
                        text: t('pages.panel.blog_posts.separators.meta'),
                        icon: 'bi-tags',
                        className: 'divider-center'
                    }
                },
                {
                    name: 'meta_description',
                    type: 'textarea',
                    required: true,
                    class: 'col-12 mb-3',
                    icon: 'bi-file-text',
                    label: t('pages.panel.blog_posts.form.meta_description.label'),
                    placeholder: t('pages.panel.blog_posts.form.meta_description.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es',
                    maxLength: 255
                },
                {
                    name: 'meta_tags',
                    type: 'text',
                    required: true,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-tags',
                    label: t('pages.panel.blog_posts.form.meta_tags.label'),
                    placeholder: t('pages.panel.blog_posts.form.meta_tags.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es'
                },
                {
                    name: 'meta_keyboards',
                    type: 'text',
                    required: true,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-keyboard',
                    label: t('pages.panel.blog_posts.form.meta_keyboards.label'),
                    placeholder: t('pages.panel.blog_posts.form.meta_keyboards.placeholder'),
                    multilocale: true,
                    defaultLocale: 'es'
                },
                {
                    separator: {
                        text: t('pages.panel.blog_posts.separators.settings'),
                        icon: 'bi-gear',
                        className: 'divider-center'
                    }
                },


                {
                    name: 'technologies',
                    type: 'select',
                    mode: "api",
                    endpoint: 'auth/catalogs/technologies',
                    pivot: {
                        relation: 'technologies',
                        field_value: 'id'
                    },
                    reducer: {
                        field_label: 'translations.name',
                        field_value: 'id',
                        searchKeys: ['translations.name', 'code', 'alias']
                    },
                    multiple: true,
                    showPills: true,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-code-square',
                    label: t('pages.panel.blog_posts.form.technologies.label'),
                    placeholder: t('pages.panel.blog_posts.form.technologies.placeholder')
                },

                {
                    name: 'categories',
                    type: 'select',
                    mode: "api",
                    endpoint: 'auth/catalogs/categories',
                    pivot: {
                        relation: 'categories',
                        field_value: 'id'
                    },
                    reducer: {
                        field_label: 'translations.name',
                        field_value: 'id',
                        searchKeys: [
                            'translations.name',
                            'code',
                            'alias'
                        ]
                    },
                    multiple: true,
                    showPills: true,
                    maxPills: 3,
                    class: 'col-md-6 mb-3',
                    icon: 'bi-folder',
                    label: t('pages.panel.blog_posts.form.categories.label'),
                    placeholder: t('pages.panel.blog_posts.form.categories.placeholder')
                }
            ]
        },
        titles: {
            main: <><i className="bi bi-journal-richtext"></i> {t('pages.panel.blog_posts.titles.main')}</>,
            createButton: t('pages.panel.blog_posts.buttons.create')
        }
    };

    return (
        <ModuleConstructor
            config={config}
            records={records}
            isLoading={isLoading}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
        />
    );
};

export default BlogPosts;