import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

/**
 * Thunk para guardar la sesión de autenticación
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const setSessionAuth = createAsyncThunk(
    'auth/saveToken',
    async (store, thunkAPI) => {
        try {
            const { token, lang } = store;
            localStorage.setItem('authToken', token);
            localStorage.setItem('authLang', lang);
            return { token, lang };
        } catch (error) {
            return thunkAPI.rejectWithValue();
        }
    }
);

/**
 * Thunk para remover la sesión de autenticación
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const removeSessionAuth = createAsyncThunk(
    'auth/removeToken',
    async (_, thunkAPI) => {
        try {
            localStorage.removeItem('authToken');
            localStorage.removeItem('authLang');
        } catch (error) {
            return thunkAPI.rejectWithValue();
        }
    }
);

// Función para cargar el estado inicial desde localStorage
const loadInitialState = () => {
    try {
        const token = localStorage.getItem('authToken');
        const lang = localStorage.getItem('authLang');

        return {
            isLoggedIn: !!token,
            token: token || null,
            lang: lang || null,
        };
    } catch (error) {
        console.error('Error loading initial state:', error);
        return {
            isLoggedIn: false,
            token: null,
            lang: null,
        };
    }
};

/**
 * Slice de autenticación
 * @type {Slice<{isLoggedIn: boolean, lang: null, token: null}, {}, string, Name, SliceSelectors<State>>}
 */
export const authSlice = createSlice({
    name: 'auth',
    initialState: loadInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setSessionAuth.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.token = action.payload.token;
                state.lang = action.payload.lang;
            })
            .addCase(removeSessionAuth.fulfilled, (state) => {
                state.isLoggedIn = false;
                state.token = null;
                state.lang = null;
            });
    },
});

// Selectores
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectToken = (state) => state.auth.token;
export const selectLang = (state) => state.auth.lang;

export default authSlice.reducer;