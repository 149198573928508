import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import api from "@/services/api";
import {useAuth} from "@/context/AuthContext";
import Select from "@components/elements/Select";

function Register() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {setIsReadyForRedirect} = useAuth();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        country_id: '',
        password_confirmation: '',
        privacyAccepted: false
    });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    useEffect(() => {
        setIsReadyForRedirect(false);
        return () => setIsReadyForRedirect(true);
    }, [setIsReadyForRedirect]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validar que las contraseñas coincidan
        if (formData.password !== formData.password_confirmation) {
            toast.error(t('pages.auth.signup.passwordMismatch'));
            return;
        }

        // Validar aceptación de política de privacidad
        if (!formData.privacyAccepted) {
            toast.error(t('pages.auth.signup.privacyRequired'));
            return;
        }

        setLoading(true);

        try {
            setIsReadyForRedirect(false);

            const response = await api.postAwaiting('auth/register', formData, true);

            if (response.data.user.code) {

                toast.success(response.data.message);
                setTimeout(()=>{
                    navigate('/login'); // O la ruta a la que quieras redirigir después del registro
                    setIsReadyForRedirect(true);
                },1000)

            }
        } catch (error) {
            setIsReadyForRedirect(true);
        } finally {
            setLoading(false);
        }
    };


    const handleCountryChange = (value) => {
        setFormData(prev => ({
            ...prev,
            country_id: Number(value)
        }));
    };
    return (
        <div>
            <div className="text-center mb-5 mb-md-7 content-space-t-2">
                <h1 className="h2">{t('pages.auth.signup.title')}</h1>
                <p>{t('pages.auth.signup.description')}</p>
            </div>

            <form className="js-validate needs-validation" noValidate onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label" htmlFor="signupEmail">
                        {t('pages.auth.signup.name.label')}
                    </label>
                    <input
                        type="name"
                        className="form-control form-control-lg"
                        name="name"
                        placeholder={t('pages.auth.signup.name.placeholder')}
                        aria-label={t('pages.auth.signup.name.placeholder')}
                        required
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <span className="invalid-feedback">{t('pages.auth.signup.name.error')}</span>
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="signupEmail">
                        {t('pages.auth.signup.email.label')}
                    </label>
                    <input
                        type="email"
                        className="form-control form-control-lg"
                        name="email"
                        id="signupEmail"
                        placeholder={t('pages.auth.signup.email.placeholder')}
                        aria-label={t('pages.auth.signup.email.placeholder')}
                        required
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <span className="invalid-feedback">{t('pages.auth.signup.email.error')}</span>
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="signupPassword">
                        {t('pages.auth.signup.password.label')}
                    </label>
                    <div className="input-group input-group-merge">
                        <input
                            type={showPassword ? "text" : "password"}
                            className="form-control form-control-lg"
                            name="password"
                            id="signupPassword"
                            placeholder={t('pages.auth.signup.password.placeholder')}
                            required
                            minLength="8"
                            value={formData.password}
                            onChange={handleChange}
                        />
                        <button
                            className="input-group-append input-group-text"
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <i className={`bi-${showPassword ? 'eye-slash' : 'eye'}`}></i>
                        </button>
                    </div>
                    <span className="invalid-feedback">{t('pages.auth.signup.password.error')}</span>
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="signupConfirmPassword">
                        {t('pages.auth.signup.confirmPassword.label')}
                    </label>
                    <div className="input-group input-group-merge">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control form-control-lg"
                            name="password_confirmation"
                            id="signupConfirmPassword"
                            placeholder={t('pages.auth.signup.confirmPassword.placeholder')}
                            required
                            minLength="8"
                            value={formData.password_confirmation}
                            onChange={handleChange}
                        />
                        <button
                            className="input-group-append input-group-text"
                            type="button"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            <i className={`bi-${showConfirmPassword ? 'eye-slash' : 'eye'}`}></i>
                        </button>
                    </div>
                    <span className="invalid-feedback">{t('pages.auth.signup.confirmPassword.error')}</span>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="signupConfirmPassword">
                        {t('pages.auth.signup.country.label')}
                    </label>
                    <div className="input-group input-group-merge">
                        <Select
                            renderOptions={(option) => (
                                <span className="d-flex align-items-center">
                                    <img
                                        src={`/assets/img/flags/1x1/${option.code}.svg`}
                                        alt={option.name}
                                        style={{width: '30px', height: '30px'}}
                                        className="me-2 img-thumbnail p-1 avatar avatar-sm avatar-circle"
                                    />
                                     <span>{option.name}</span>
                                 </span>
                            )}
                            endpoint="catalogs/countries"
                            filter={{'filter': 'all'}}
                            selected={formData.country_id}
                            onChange={handleCountryChange}
                        />
                    </div>

                </div>

                <div className="form-check mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="signupPrivacyCheck"
                        name="privacyAccepted"
                        required
                        checked={formData.privacyAccepted}
                        onChange={handleChange}
                    />
                    <label className="form-check-label small" htmlFor="signupPrivacyCheck">
                        {t('pages.auth.signup.privacy.label')}{' '}
                        <Link to="/privacy-policy" target="_blank">{t('pages.auth.signup.privacy.link')}</Link>
                    </label>
                    <span className="invalid-feedback">{t('pages.auth.signup.privacy.error')}</span>
                </div>

                <div className="d-grid mb-3">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="spinner-border spinner-border-sm me-2" role="status"
                                  aria-hidden="true"></span>
                        ) : null}
                        {t('pages.auth.signup.submit')}
                    </button>
                </div>

                <div className="text-center">
                    <p>
                        {t('pages.auth.signup.loginPrompt')}{' '}
                        <Link to="/login" className="link">
                            {t('pages.auth.signup.loginLink')}
                        </Link>
                    </p>
                </div>
            </form>

            <div className="w-md-85 text-lg-center mx-lg-auto mt-lg-10">
                <p className="text-primary small">
                    &copy; Wecodex Solutions | <Link to='/terms-and-conditions'>{t('layout.footer.copyright')}</Link>
                </p>
            </div>
        </div>
    );
}

export default Register;