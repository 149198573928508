
import moment from 'moment';

export const formatDateForServer = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    if (!date) return null;
    return moment(date).format(format);
};

export const parseDateFromServer = (dateString) => {
    if (!dateString) return null;
    return moment(dateString).toDate();
};

export const formatFileSize = (bytes) => {
    if (!bytes) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export const getFileType = (file) => {
    if (typeof file === 'string') {
        const ext = file.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(ext)) return 'image';
        if (['mp4', 'webm', 'ogg'].includes(ext)) return 'video';
        return 'file';
    }
    if (file instanceof File) {
        if (file.type.startsWith('image/')) return 'image';
        if (file.type.startsWith('video/')) return 'video';
        return 'file';
    }
    return 'file';
};

export const getFileName = (file) => {
    if (typeof file === 'string') {
        return file.split('/').pop().split('?')[0];
    }
    if (file instanceof File) {
        return file.name;
    }
    return 'Archivo';
};
