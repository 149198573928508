import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Table from '@/components/elements/Table';
import Alert from '@/components/elements/Alert';
import FormField from '@/components/constructors/ModuleConstructor/components/FormField';
import FormActions from '@/components/constructors/ModuleConstructor/components/FormActions';
import WizardNavigation from '@/components/constructors/ModuleConstructor/components/WizardNavigation';
import {useFormData} from '@/components/constructors/ModuleConstructor/hooks/useFormData';
import {useWizard} from '@/components/constructors/ModuleConstructor/hooks/useWizard';
import {ConfigPropType} from '@/components/constructors/ModuleConstructor/types';
import PropTypes from 'prop-types';

const ModuleConstructor = ({
                               config,
                               records,
                               isLoading,
                               onCreate,
                               onUpdate,
                               onDelete
                           }) => {
    const {t} = useTranslation();
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);
    const [code, setCode] = useState(null);
    const [show, setShow] = useState(false);
    const [isCreate, setIsCreate] = useState(false);

    const configDefault = {
        modal: {size: 'xl'},
        ...config
    };

    const {
        formData,
        filePreview,
        handleChange,
        removeFile,
        formatDataForEdit,
        resetForm
    } = useFormData({});

    const {
        currentStep,
        steps,
        currentStepFields,
        hasNextStep,
        hasPrevStep,
        validateCurrentStep,
        goToNextStep,
        goToPrevStep,
        goToStep,
        resetWizard
    } = useWizard(config.form, formData);

    const tableColumns = React.useMemo(() => {
        const baseColumns = config.table.columns || [];

        const actionsColumn = {
            key: 'actions',
            render: (item) => (
                <div className="d-flex gap-1">
                    <button
                        type="button"
                        className="btn btn-sm btn-soft-danger"
                        onClick={() => handleDeleteClick(item.code)}
                        disabled={isLoading}
                    >
                        <i className="bi bi-trash"/>
                    </button>
                    <button
                        type="button"
                        onClick={() => handleEdit(item.code)}
                        className="btn btn-sm btn-soft-primary"
                        disabled={isLoading}
                    >
                        <i className="bi bi-pencil-square"/>
                    </button>
                    {config.table.actions?.map((action, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn btn-sm ${action.className || 'btn-soft-secondary'}`}
                            onClick={() => action.onClick(item)}
                            disabled={isLoading || action.disabled?.(item)}
                        >
                            <i className={action.icon}/>
                        </button>
                    ))}
                </div>
            )
        };

        return config.table.hideActions ? baseColumns : [...baseColumns, actionsColumn];
    }, [config.table.columns, config.table.hideActions, config.table.actions, isLoading]);

    const handlePageChange = (page) => {
        if (config.table.pagination?.onChange) {
            config.table.pagination.onChange(page);
        }
    };

    const handlePerPageChange = (perPage) => {
        if (config.table.pagination?.onPerPageChange) {
            config.table.pagination.onPerPageChange(perPage);
        }
    };

    const handleSearch = (term) => {
        if (config.table.search?.onSearch) {
            config.table.search.onSearch(term);
        }
    };

    const handleClose = () => {
        setShow(false);
        resetForm();
        setCode(null);
        resetWizard();
    };

    const handleEdit = (recordCode) => {
        const record = records.find(item => item.code === recordCode);
        if (record) {
            setCode(recordCode);
            const formattedData = formatDataForEdit(record, config.form.fields);
            resetForm(formattedData);
            setIsCreate(false);
            setShow(true);
        }
    };

    const handleDeleteClick = (code) => {
        setRecordToDelete(code);
        setShowDeleteAlert(true);
    };

    const handleDeleteConfirm = async () => {
        if (!recordToDelete) return;

        const success = await onDelete(recordToDelete);
        if (success) {
            setShowDeleteAlert(false);
            setRecordToDelete(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (config.form.mode === 'wizard' && hasNextStep) {
            if (validateCurrentStep()) {
                goToNextStep();
            }
            return;
        }

        const missingRequiredFiles = config.form.fields
            .filter(field => !field.separator && field.type === 'file' && field.required && isCreate)
            .some(field => !formData[field.name]);

        if (missingRequiredFiles) {
            alert(t('common.form.requiredFiles'));
            return;
        }

        let success;
        try {
            if (isCreate) {
                success = await onCreate(formData);
            } else {
                success = await onUpdate(formData, code);
            }

            if (success) {
                handleClose();
            }
        } catch (error) {
            console.error('Error in form submission:', error);
        }
    };

    const openFormModal = (create = false) => {
        setIsCreate(create);
        resetForm();
        setShow(true);
    };

    const translations = {
        save: t('common.crud.save'),
        saving: t('common.crud.saving'),
        cancel: t('common.crud.cancel'),
        next: t('common.crud.next'),
        previous: t('common.crud.previous'),
        processing: t('common.crud.processing')
    };

    return (
        <>
            <div className="card">
                <div className="card-header border-bottom d-flex justify-content-between align-items-center">
                    <h5 className="card-header-title">
                        {config.titles.main}
                    </h5>
                    <button
                        onClick={() => openFormModal(true)}
                        className="btn btn-primary btn-sm"
                        disabled={isLoading}
                    >
                        <i className="bi-plus-circle me-2"/>
                        {config.titles.createButton}
                    </button>
                </div>

                <Table
                    headers={config.table.headers}
                    columns={tableColumns}
                    data={records}
                    itemsPerPage={config.table.pagination?.perPage || 5}
                    currentPage={config.table.pagination?.currentPage}
                    totalPages={config.table.pagination?.lastPage}
                    totalItems={config.table.pagination?.total}
                    serverSide={config.table.pagination?.serverSide}
                    onPageChange={handlePageChange}
                    onPerPageChange={handlePerPageChange}
                    searchable={true}
                    searchKeys={config.table.searchKeys}
                    serverSideSearch={config.table.search?.serverSide}
                    onSearch={handleSearch}
                    isResponsive={true}
                    isTheadLight={true}
                    loading={isLoading}
                />
            </div>

            <Alert
                isOpen={showDeleteAlert}
                onClose={() => setShowDeleteAlert(false)}
                onConfirm={handleDeleteConfirm}
                title={t('common.crud.confirmDelete')}
                message={t('common.crud.confirmDeleteMessage')}
                confirmText={t('common.crud.delete')}
                cancelText={t('common.crud.cancel')}
                type="danger"
                isLoading={isLoading}
            />

            <Modal
                show={show}
                size={configDefault.modal.size}
                onHide={handleClose}
                animation={false}

            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isCreate ? t('common.crud.create') : t('common.crud.edit')}
                        {config.form.mode === 'wizard' && (
                            <small className="text-muted ms-2">
                                {`Paso ${currentStep + 1} de ${steps.length}`}
                            </small>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body   className={(config.form.mode==='winzard')?"bg-light":""}>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col md:flex-row gap-6">


                            <div className="row">


                                {config.form.mode === 'wizard' && (
                                    <div className={config.form.wizard?.position === 'left' ? 'col-md-3' : ''}>
                                        <WizardNavigation
                                            steps={steps}
                                            currentStep={currentStep}
                                            onStepClick={goToStep}
                                            position={config.form.wizard?.position || 'left'}
                                            validationEnabled={config.form.wizard?.validation_step}
                                            validateCurrentStep={validateCurrentStep}
                                        />

                                    </div>
                                )}


                                <div className={(config.form.mode === 'wizard' && config.form.wizard?.position==='left') ? "col-md-9 card " : 'col-md-12 card'}>


                                    <div className="mt-n3">
                                        <div className="alert alert-primary " role="alert">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <i className={'bi ' + steps[currentStep]?.icon}/>
                                                </div>
                                                <div className="flex-grow-1 ms-2">
                                                    <b className="text-white"> {steps[currentStep]?.title}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <div className="row  ">
                                            {currentStepFields.map((field, index) => {
                                                if (field.component) {
                                                    return React.cloneElement(field.component, {
                                                        key: `component-${index}`
                                                    });
                                                }

                                                if (!field.separator) {
                                                    return (
                                                        <FormField
                                                            key={field.name}
                                                            field={field}
                                                            formData={formData}
                                                            onChange={handleChange}
                                                            isCreate={isCreate}
                                                            filePreview={filePreview}
                                                            onFileRemove={removeFile}
                                                            t={t}
                                                        />
                                                    );
                                                }

                                                // Renderizar separador si no estamos en modo wizard
                                                if (config.form.mode !== 'wizard') {
                                                    const separatorContent = typeof field.separator === 'object'
                                                        ? (
                                                            <>
                                                                {field.separator.icon &&
                                                                <i className={`${field.separator.icon} me-2`}/>
                                                                }
                                                                {field.separator.text}
                                                            </>
                                                        )
                                                        : field.separator;

                                                    return (
                                                        <div
                                                            key={`separator-${index}`}
                                                            className={`col-12 mt-4 mb-3 ${
                                                                field.separator.className || ''
                                                            }`}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <hr className="flex-grow-1 border-light-subtle"/>
                                                                <h6 className="text-body-secondary text-uppercase px-3 mb-0 fs-6 fw-semibold">
                                                                    {separatorContent}
                                                                </h6>
                                                                <hr className="flex-grow-1 border-light-subtle"/>
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="card-footer pt-0">
                                    <FormActions
                                        isWizard={config.form.mode === 'wizard'}
                                        isLoading={isLoading}
                                        onClose={handleClose}
                                        hasPrevStep={hasPrevStep}
                                        hasNextStep={hasNextStep}
                                        onPrevStep={goToPrevStep}
                                        currentStep={currentStep}
                                        stepsLength={steps.length}
                                        translations={translations}
                                        wizardValidation={config.form.wizard?.validation_step}
                                        isStepValid={validateCurrentStep()}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

ModuleConstructor.propTypes = {
    titles: PropTypes.shape({
        main: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        createButton: PropTypes.string.isRequired
    }).isRequired,
    config: ConfigPropType.isRequired,
    records: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default ModuleConstructor;