import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from "../../services/api";

const Notification = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState(true);
    const [notifications, setNotifications] = useState({
        newContent: false,
        accountActivity: true,
        meetups: true,
        opportunities: false
    });

    const handleToggleAll = () => {
        const allChecked = Object.values(notifications).every(value => value);
        const newState = Object.keys(notifications).reduce((acc, key) => ({
            ...acc,
            [key]: !allChecked
        }), {});
        setNotifications(newState);
        updateNotificationSettings(newState);
    };

    const handleToggle = async (key) => {
        const newState = {
            ...notifications,
            [key]: !notifications[key]
        };
        setNotifications(newState);
        updateNotificationSettings(newState);
    };

    const updateNotificationSettings = async (settings) => {
        try {
            setIsLoading(true);
            await api.postAwaiting('user/notifications', settings, true);
        } catch (error) {
            console.error('Error updating notifications:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const requestNotificationPermission = async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                setShowPermissionAlert(false);
            }
        } catch (error) {
            console.error('Error requesting notification permission:', error);
        }
    };

    return (
        <div className="card card-sm">
            <div className="card-header d-flex justify-content-between align-items-center border-bottom">
                <h5 className="card-header-title">{t('pages.panel.profile.notifications.title')}</h5>
                <button
                    className="btn btn-white btn-sm btn-toggle"
                    onClick={handleToggleAll}
                    disabled={isLoading}
                >
                    {Object.values(notifications).every(value => value)
                        ? t('pages.panel.profile.notifications.untoggleAll')
                        : t('pages.panel.profile.notifications.toggleAll')
                    }
                </button>
            </div>


            <div className="card-body">
                <small className="card-subtitle">{t('pages.panel.profile.notifications.subtitle')}</small>

                <div className="list-group list-group-flush list-group-no-gutters">
                    {[
                        {
                            id: 'newContent',
                            title: t('pages.panel.profile.notifications.options.new_projects.title'),
                            description: t('pages.panel.profile.notifications.options.new_projects.description'),
                            isNew: false
                        },
                        {
                            id: 'accountActivity',
                            title: t('pages.panel.profile.notifications.options.new_posts_blog.title'),
                            description: t('pages.panel.profile.notifications.options.new_posts_blog.description'),
                            isNew: true
                        },
                        {
                            id: 'meetups',
                            title: t('pages.panel.profile.notifications.options.discounts.title'),
                            description: t('pages.panel.profile.notifications.options.discounts.description'),
                            isNew: true
                        },
                        {
                            id: 'opportunities',
                            title: t('pages.panel.profile.notifications.options.opportunities.title'),
                            description: t('pages.panel.profile.notifications.options.opportunities.description'),
                            isNew: false
                        }
                    ].map((option) => (
                        <div key={option.id} className="list-group-item">
                            <label className="form-check form-switch" htmlFor={`notification-${option.id}`}>
                                <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                    id={`notification-${option.id}`}
                                    checked={notifications[option.id]}
                                    onChange={() => handleToggle(option.id)}
                                    disabled={isLoading}
                                />
                                <span className="d-block">
                                    {option.title}
                                    {option.isNew && (
                                        <span className="badge bg-success ms-1">
                                            {t('pages.panel.profile.notifications.badge.new')}
                                        </span>
                                    )}
                                </span>
                                <span className="d-block small text-muted">
                                    {option.description}
                                </span>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Notification;