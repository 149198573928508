import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Head = ({
                  pagePath,
                  postData = null,
                  baseUrl = window.location.origin
              }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const currentLanguage = i18n.language;

    // Función para obtener traducción específica
    const getTranslation = (item, field) => {
        if (!item?.translations) return '';

        const translation = item.translations.find(t => t.locale === currentLanguage);
        return translation?.[field] ||
            item.translations[0]?.[field] ||
            item?.[field] ||
            '';
    };

    // Función para construir URLs localizadas
    const getLocalizedUrl = (lang) => {
        if (!postData) return `${baseUrl}${location.pathname}`;

        const categoryTranslation = postData.default_category?.translations.find(t => t.locale === lang);
        const postTranslation = postData.translations.find(t => t.locale === lang);

        if (!categoryTranslation || !postTranslation) {
            // Fallback a la traducción por defecto si no existe la traducción para el idioma
            const defaultCategoryTranslation = postData.default_category?.translations[0];
            const defaultPostTranslation = postData.translations[0];

            return `${baseUrl}${lang === 'es' ? '' : '/' + lang}/${defaultCategoryTranslation?.slug}/${defaultPostTranslation?.slug}`;
        }

        return `${baseUrl}${lang === 'es' ? '' : '/' + lang}/${categoryTranslation.slug}/${postTranslation.slug}`;
    };

    // Obtener datos SEO
    const getSeoData = () => {
        if (postData) {
            const currentTranslation = postData.translations?.find(t => t.locale === currentLanguage)
                || postData.translations?.[0];

            return {
                title: getTranslation(postData, 'title'),
                description: currentTranslation?.meta_description || getTranslation(postData, 'description_short'),
                keywords: currentTranslation?.meta_keyboards,
                robots: postData.is_searchable ? 'index, follow' : 'noindex, nofollow',
                author: 'WeCodex',
                publishedTime: postData.created_at,
                modifiedTime: postData.updated_at,
                og: {
                    title: getTranslation(postData, 'title'),
                    description: getTranslation(postData, 'description_short'),
                    type: 'article',
                    image: postData.preview_image,
                    siteName: 'WeCodex Blog',
                },
                twitter: {
                    card: 'summary_large_image',
                    title: getTranslation(postData, 'title'),
                    description: getTranslation(postData, 'description_short'),
                    image: postData.preview_image,
                },
                schema: {
                    "@context": "https://schema.org",
                    "@type": "BlogPosting",
                    headline: getTranslation(postData, 'title'),
                    image: postData.preview_image,
                    datePublished: postData.created_at,
                    dateModified: postData.updated_at,
                    articleBody: getTranslation(postData, 'content'),
                    author: {
                        "@type": "Organization",
                        name: "WeCodex"
                    },
                    publisher: {
                        "@type": "Organization",
                        name: "WeCodex",
                        logo: {
                            "@type": "ImageObject",
                            url: `${baseUrl}/logo.png`
                        }
                    },
                    mainEntityOfPage: {
                        "@type": "WebPage",
                        "@id": getLocalizedUrl(currentLanguage)
                    },
                    keywords: currentTranslation?.meta_keyboards,
                    description: getTranslation(postData, 'description_short')
                }
            };
        }

        try {
            return t(`pages.${pagePath}.head`, { returnObjects: true });
        } catch (error) {
            console.warn(`No SEO data found for path: ${pagePath}`);
            return {};
        }
    };

    const seoData = getSeoData();
    const currentUrl = getLocalizedUrl(currentLanguage);

    return (
        <Helmet>
            {/* Configuración básica */}
            <html lang={currentLanguage} />
            {seoData.title && <title>{seoData.title}</title>}
            <link rel="canonical" href={currentUrl} />

            {/* Enlaces de idiomas alternativos */}
            <link rel="alternate" hrefLang="x-default" href={getLocalizedUrl('es')} />
            {['es', 'en'].map((lang) => (
                <link
                    key={lang}
                    rel="alternate"
                    hrefLang={lang}
                    href={getLocalizedUrl(lang)}
                />
            ))}

            {/* Meta tags básicos */}
            {seoData.description && <meta name="description" content={seoData.description} />}
            {seoData.keywords && <meta name="keywords" content={seoData.keywords} />}
            {seoData.author && <meta name="author" content={seoData.author} />}
            {seoData.robots && <meta name="robots" content={seoData.robots} />}

            {/* Meta tags de tiempo para artículos */}
            {seoData.publishedTime && (
                <>
                    <meta name="article:published_time" content={seoData.publishedTime} />
                    <meta name="date" content={seoData.publishedTime} />
                </>
            )}
            {seoData.modifiedTime && <meta name="article:modified_time" content={seoData.modifiedTime} />}

            {/* Open Graph */}
            {seoData.og?.title && <meta property="og:title" content={seoData.og.title} />}
            {seoData.og?.description && <meta property="og:description" content={seoData.og.description} />}
            {seoData.og?.type && <meta property="og:type" content={seoData.og.type} />}
            <meta property="og:url" content={currentUrl} />
            {seoData.og?.image && <meta property="og:image" content={seoData.og.image} />}
            {seoData.og?.siteName && <meta property="og:site_name" content={seoData.og.siteName} />}
            <meta property="og:locale" content={currentLanguage === 'es' ? 'es_ES' : 'en_US'} />

            {/* Twitter Card */}
            {seoData.twitter?.card && <meta name="twitter:card" content={seoData.twitter.card} />}
            {seoData.twitter?.title && <meta name="twitter:title" content={seoData.twitter.title} />}
            {seoData.twitter?.description && <meta name="twitter:description" content={seoData.twitter.description} />}
            {seoData.twitter?.image && <meta name="twitter:image" content={seoData.twitter.image} />}

            {/* Schema.org */}
            {seoData.schema && (
                <script type="application/ld+json">
                    {JSON.stringify(seoData.schema)}
                </script>
            )}
        </Helmet>
    );
};

export default Head;