import React, {useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Typed from 'typed.js';
import VideoGrid from "../components/widgets/VideoGrid";
import Head from "../components/layout/Head";



const Home = () => {
    const {t} = useTranslation();
    const typedElement = useRef(null);
    const typed = useRef(null);

    useEffect(() => {
        if (typedElement.current) {
            const options = {
                strings: [
                    t('pages.home.hero.typed.projects'),
                    t('pages.home.hero.typed.ideas'),
                    t('pages.home.hero.typed.applications')
                ],
                typeSpeed: 60,
                backSpeed: 25,
                backDelay: 1500,
                loop: true
            };

            // Inicializar Typed
            typed.current = new Typed(typedElement.current, options);

            // Cleanup al desmontar
            return () => {
                if (typed.current) {
                    typed.current.destroy();
                }
            };
        }
    }, [t]); // Dependencia de t para que se actualice cuando cambie el idioma

    return (
        <>
            {/* Hero Section */}

            <Head pagePath="home" ><title/></Head>

            <div className="position-relative bg-dark overflow-hidden"
                 style={{backgroundImage: "url(assets/svg/components/wave-pattern-light.svg)"}}>
                <div
                    className="container position-relative zi-2 content-space-t-5 content-space-b-2 content-space-b-md-3">
                    <div className="row align-items-lg-center">
                        <div className="col-lg-6 mb-7 mb-lg-0">
                            {/* Heading */}
                            <div className="mb-6">
                                <h1 className="display-6 text-white">
                                    {t('pages.home.hero.title')}{' '}
                                    <span className="text-warning">
                                      <span ref={typedElement}></span>
                                     </span>
                                </h1>
                                <p className="lead text-white-70">
                                    {t('pages.home.hero.description')}
                                </p>
                            </div>
                   
                            <div className="d-grid d-sm-flex gap-3">
                                <Link className="btn btn-primary btn-transition" to="/contact">
                                    {t('pages.home.hero.buttons.contact')}
                                </Link>
                                <Link className="btn btn-ghost-light btn-transition" to="/services/all-development">
                                    {t('pages.home.hero.buttons.services')} <i className="bi-chevron-right small ms-1"></i>
                                </Link>
                            </div>
                        </div>

                        {/* Hero Image */}
                        <div className="col-lg-6">
                            <div className="position-relative">
                                <figure className="device-browser">
                                    <div className="device-browser-frame"
                                         style={{padding: 10, background: '#2e3537', 'border-right': 10}}>
                                        <img
                                            src="assets/img/source/company.jpg"
                                            style={{width: '100%'}}
                                            alt="Desarrolladores de Software"
                                        />
                                    </div>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Shape */}
                <div className="shape shape-bottom zi-1">
                    <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 500H3000V0L0 500Z" fill="#fff"/>
                    </svg>
                </div>
            </div>


            <div className="overflow-hidden">
                <div className="container content-space-2 content-space-t-lg-3">
                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-5 mb-9 mb-lg-0">
                            <div className="mb-5">
                                <h2>{t('pages.home.development_app.title')} </h2>
                                <p>{t('pages.home.development_app.description')}</p>
                                <p>{t('pages.home.development_app.process')}  </p>
                                <ul>
                                    {Object.keys(t('pages.home.development_app.steps', { returnObjects: true })).map((key) => (
                                        <li key={key}>{t(`pages.home.development_app.steps.${key}`)}</li>
                                    ))}
                                </ul>

                            </div>
                            <div className="w-md-50 w-lg-75 mb-2">
                                <div className="d-grid d-sm-flex gap-3">
                                    <a className="btn btn-primary btn-transition" href="/service-develop-movil">
                                        {t('pages.home.development_app.more_information')}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="position-relative mx-auto" data-aos="fade-up">
                                <figure className="device-mobile mx-auto">
                                    <div className="device-mobile-frame">
                                        <img className="device-mobile-img" src="assets/img/407x867/img1.jpg" alt={t('pages.home.development_app.title')}/>
                                    </div>
                                </figure>
                                <div className="position-absolute top-50 start-50 translate-middle zi-n1"
                                     style={{width: '25rem'}}>
                                    <img className="img-fluid" src="assets/svg/components/shape-2.svg" alt="SVG"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="overflow-hidden">
                <div className="container content-space-t-lg-3 content-space-b-lg-2">
                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-5 order-lg-2 mb-9 mb-lg-0">
                            <div className="mb-5">
                                <h2>{t('pages.home.development_web.title')}</h2>
                                <p>{t('pages.home.development_web.description')}</p>
                                <ul>
                                    {Object.keys(t('pages.home.development_web.steps', {returnObjects: true})).map((key) => (
                                        <li key={key}>{t(`pages.home.development_web.steps.${key}`)}</li>
                                    ))}
                                </ul>
                            </div>
                            <Link className="btn btn-primary btn-transition" to="/services">
                                {t('pages.home.development_web.more_information')}
                            </Link>
                        </div>

                        <div className="col-lg-6 order-lg-1">
                            <div className="position-relative mx-auto" data-aos="fade-up">
                                <img className="img-fluid" src="assets/img/source/develop-systems.png" alt={t('pages.home.development_web.title')}/>
                                <div className="position-absolute top-50 start-50 translate-middle zi-n1"
                                     style={{width: '25rem'}}>
                                    <img className="img-fluid" src="assets/svg/components/shape-1.svg" alt="SVG"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container content-space-2 content-space-lg-3">
                <div className="w-lg-50 text-center mx-lg-auto mb-5">
                    <h2>    {t('pages.home.why_choose_us.title')}</h2>
                    <p>    {t('pages.home.why_choose_us.description')}</p>
                </div>

                <div className="row gx-0 align-items-lg-center mb-7 mb-md-10">
                    <div className="col-lg-7">
                        <div className="card card-lg text-center zi-2" data-aos="fade-up">
                            <div className="card-header pb-0">
                                <div className="mb-1">
<span className="badge bg-dark rounded-pill align-top ms-1">
{t('pages.home.why_choose_us.we_offer.title')}
</span>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="row justify-content-sm-center align-items-sm-center mb-5">
                                    <div className="col-sm-5">
                                        <div className="mb-2">
                                            <img className="avatar avatar-xl avatar-4x3"
                                                 src="assets/svg/illustrations/account-creation.svg"
                                                 alt="Quick Solution"/>
                                        </div>
                                        <h4>{t('pages.home.why_choose_us.we_offer.description_1')}</h4>
                                    </div>

                                    <div className="col-sm-1 my-3 my-sm-0">
                                        <span className="fs-3 text-primary">+</span>
                                    </div>

                                    <div className="col-sm-5">
                                        <div className="mb-2">
                                            <img className="avatar avatar-xl avatar-4x3"
                                                 src="assets/svg/illustrations/pro-account.svg" alt="Affordable Cost"/>
                                        </div>
                                        <h4>{t('pages.home.why_choose_us.we_offer.description_2')}</h4>
                                    </div>
                                </div>

                                <div className="mb-7">
                                    <p className="card-text small">{t('pages.home.why_choose_us.question')}</p>
                                </div>

                                <button type="button" className="btn btn-primary btn-transition px-6">
                                    {t('pages.home.why_choose_us.contact')}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="card card-lg bg-dark ms-md-n2" data-aos="fade-up" data-aos-delay="200">
                            <div className="card-body">
                                <ul className="list-pointer list-pointer-soft-bg-light list-pointer-lg">
                                    {Object.keys(t('pages.home.why_choose_us.features', {returnObjects: true})).map((key) => (
                                        <li key={key} className="list-pointer-item">
                                            <h4 className="text-white mb-1">
                                                {t(`pages.home.why_choose_us.features.${key}.title`)}
                                            </h4>
                                            <p className="text-white-70 mb-0">
                                                {t(`pages.home.why_choose_us.features.${key}.description`)}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>



                    <div className="container content-space-2 content-space-t-lg-4 content-space-b-lg-3">

                        <div className="w-lg-50 text-center mx-lg-auto mb-5">
                            <h2>  {t('pages.home.software_solutions.title')}</h2>
                            <p>{t('pages.home.software_solutions.description')}</p>
                        </div>

                        <div className="text-center mb-10">
                            <a className="video-player btn btn-outline-primary"
                               href="https://www.youtube.com/@WecodexSolutions/videos" target="_blank" role="button">
                                <i className="bi-play-circle-fill me-1"/> {t('pages.home.software_solutions.discover_how')}
                            </a>
                        </div>


                        <VideoGrid videoUrls={[
                            "https://www.youtube.com/embed/nzo632_4c6U",
                            "https://www.youtube.com/embed/_RLu0WqkJfI",
                            "https://www.youtube.com/embed/yV-0juyYewQ",
                            // Agrega más URLs según necesites
                        ]}> </VideoGrid>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;