import axios from 'axios';
import { toast } from 'react-toastify';

axios.defaults.baseURL = "https://api.wecodex.com";

const api = {
    async showNotification(status, response) {
        if (status === 'success' || (status === true && (Array.isArray(status) && !status.includes('success'))) && response.data?.message !== undefined) {
            return toast.success(response.data.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }

        if (status === 'error' && response.response.data?.message !== undefined) {
            const errors = response.response.data.errors;
            let errorMessages = '';

            if (errors) {
                errorMessages = Object.keys(errors).map(key => `${errors[key].join(', ')}`).join('\n');
            } else {
                errorMessages = response.response.data.message;
            }

            return toast.error(errorMessages, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    },

    async parseErrorResponseValidator(error) {


        if(error.response.data.message ==="Unauthenticated."){
            localStorage.clear();
            window.location.href = '/login';

        }

        if (error.response.status === 500) {
            return (error.response.data.message);
        } else if (error.response.status === 401) {

            if (error.response.data.error === 'Unauthenticated') {
                window.location.href = '/login';
                return false;
            }

            return (error.response.data);
        } else if (error.response.status === 422) {
            return (error.response.data);
        } else if (error.response.status === 400) {
            if (error.response.data.error === 'Unauthenticated') {
                window.location.href = '/login';
                return false;
            } else {
                return (error.response.data.error);
            }
        }
    },

    async parseSessionAuth() {
        try {
            const authToken = localStorage.getItem('authToken');
            const authLang = localStorage.getItem('authLang');

            return { authToken, authLang };
        } catch (error) {
            console.error('Error parsing session auth:', error);
            return {};
        }
    },

    getDefaultHeaders(accessToken, locale, customHeaders = {}) {
        return {
            Authorization: 'Bearer ' + accessToken,
            'X-localization': locale,
            ...customHeaders
        };
    },

    async postAwaiting(endpoint, params = {}, show_notification = false, options = {}) {
        const authSession = await this.parseSessionAuth();
        const accessToken = authSession.authToken;
        const locale = authSession.authLang;

        // Determinar si estamos enviando FormData
        const isFormData = params instanceof FormData;

        // Configurar headers según el tipo de datos
        const headers = this.getDefaultHeaders(accessToken, locale,
            isFormData ? {
                'Content-Type': 'multipart/form-data'
            } : {
                'Content-Type': 'application/json',
                'Accept': 'application/json, text/plain, */*'
            }
        );

        // Combinar con headers personalizados si existen
        if (options.headers) {
            Object.assign(headers, options.headers);
        }

        return axios.post(endpoint, params, {
            ...options,
            headers
        }).then(async response => {
            if (show_notification === true) await this.showNotification('success', response);
            return response.data;
        }).catch(async error => {
            await this.showNotification('error', error);
            return Promise.reject(await this.parseErrorResponseValidator(error));
        });
    },

    async putAwaiting(endpoint, params = {}, show_notification = false, options = {}) {
        const authSession = await this.parseSessionAuth();
        const accessToken = authSession.authToken;
        const locale = authSession.authLang;

        const isFormData = params instanceof FormData;

        if (isFormData) {
            params.append('_method', 'PUT');
            return this.postAwaiting(endpoint, params, show_notification, options);
        }

        const headers = this.getDefaultHeaders(accessToken, locale, {
            'Content-Type': 'application/json',
            'Accept': 'application/json, text/plain, */*',
            ...options.headers
        });

        return axios.put(endpoint, params, {
            ...options,
            headers
        }).then(async response => {
            if (show_notification === true) await this.showNotification('success', response);
            return response.data;
        }).catch(async error => {
            await this.showNotification('error', error);
            return Promise.reject(await this.parseErrorResponseValidator(error));
        });
    },


    async getAwaiting(endpoint, params = {}, show_notification = false, options = {}) {
        const authSession = await this.parseSessionAuth();
        const accessToken = authSession.authToken;
        const locale = authSession.authLang;

        const headers = this.getDefaultHeaders(accessToken, locale, options.headers);

        return axios.get(`${endpoint}`, {
            ...options,
            params: params,
            headers
        }).then(async response => {
            if (show_notification === true) await this.showNotification('success', response);
            return response.data;
        }).catch(async error => {
            await this.showNotification('error', error);
            return Promise.reject(await this.parseErrorResponseValidator(error));
        });
    },

    async deleteAwaiting(endpoint, params = {}, show_notification = false, options = {}) {
        const authSession = await this.parseSessionAuth();
        const accessToken = authSession.authToken;
        const locale = authSession.authLang;

        const headers = this.getDefaultHeaders(accessToken, locale, options.headers);

        return axios.delete(endpoint, {
            ...options,
            headers,
            data: params
        }).then(async response => {
            if (show_notification === true) await this.showNotification('success', response);
            return response.data;
        }).catch(async error => {
            await this.showNotification('error', error);
            return Promise.reject(await this.parseErrorResponseValidator(error));
        });
    },
};

export default api;