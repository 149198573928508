
import PropTypes from 'prop-types';

export const FieldPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    class: PropTypes.string,
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledOnEdit: PropTypes.bool,
    multilocale: PropTypes.bool,
    maxLength: PropTypes.number,
    tooltip: PropTypes.string,
    helper: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    preview: PropTypes.bool,
    mode: PropTypes.oneOf(['api', 'standard']),
    reducer: PropTypes.string,
    endpoint: PropTypes.string,
    filter: PropTypes.object,
    options: PropTypes.array,
    showPills: PropTypes.bool,
    maxPills: PropTypes.number,
    separator: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            icon: PropTypes.string,
            className: PropTypes.string
        })
    ]),
    pivot: PropTypes.shape({
        relation: PropTypes.string,
        field_value: PropTypes.string
    })
});

export const ConfigPropType = PropTypes.shape({
    titles: PropTypes.shape({
        main: PropTypes.string.isRequired,
        createButton: PropTypes.string.isRequired
    }).isRequired,
    modal: PropTypes.shape({
        size: PropTypes.string
    }),
    form: PropTypes.shape({
        mode: PropTypes.oneOf(['standard', 'wizard']),
        fields: PropTypes.arrayOf(FieldPropType).isRequired,
        wizard: PropTypes.shape({
            position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
            validation_step: PropTypes.bool,
            order: PropTypes.oneOf(['asc', 'desc'])
        })
    }).isRequired,
    table: PropTypes.shape({
        headers: PropTypes.array.isRequired,
        columns: PropTypes.array.isRequired,
        hideActions: PropTypes.bool,
        actions: PropTypes.arrayOf(PropTypes.shape({
            icon: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            className: PropTypes.string,
            disabled: PropTypes.func
        })),
        pagination: PropTypes.shape({
            perPage: PropTypes.number,
            currentPage: PropTypes.number,
            lastPage: PropTypes.number,
            total: PropTypes.number,
            serverSide: PropTypes.bool,
            onChange: PropTypes.func,
            onPerPageChange: PropTypes.func
        }),
        search: PropTypes.shape({
            serverSide: PropTypes.bool,
            onSearch: PropTypes.func
        }),
        searchKeys: PropTypes.arrayOf(PropTypes.string)
    }).isRequired
});
