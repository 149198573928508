import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from "../components/widgets/LanguageSelector";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function AuthLayout() {
    const { t, i18n } = useTranslation();
    const [quote, setQuote] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Obtener todas las claves de citas disponibles
        const quoteKeys = Object.keys(t('quotes', { returnObjects: true }) || {});

        if (quoteKeys.length > 0) {
            // Seleccionar una cita aleatoria
            const randomKey = quoteKeys[Math.floor(Math.random() * quoteKeys.length)];
            const selectedQuote = t(`quotes.${randomKey}`, { returnObjects: true });
            setQuote(selectedQuote);
        }
    }, [i18n.language, t]);

    // Función para manejar la navegación hacia atrás
    const handleBack = (e) => {
        e.preventDefault();
        navigate(-1); // Retrocede una página en el historial
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            {/* HEADER */}
            <header className="navbar navbar-expand navbar-light navbar-absolute-top">
                <div className="container-fluid">
                    <nav className="navbar-nav-wrap">
                        <a className="navbar-brand d-none d-lg-flex p-2" href="/" aria-label="Front">
                            <img className="navbar-brand-logo" src="/assets/logo/secundary_sm.png" alt="Logo" />
                        </a>
                        <a className="navbar-brand d-flex d-lg-none" href="/" aria-label="Front">
                            <img className="navbar-brand-logo" src="/assets/logo/secundary_sm.png" alt="Logo" />
                        </a>
                        <div className="ms-auto">
                            <a href="/" className="link link-sm link-secondary m-lg-3">
                                <i className="bi-chevron-left small ms-1"></i> {t('actions.back', 'Regresar')}
                            </a>

                            <LanguageSelector />
                        </div>
                    </nav>
                </div>
            </header>

            <main role="main" className="flex-grow-1">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-lg-5 col-xl-4 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-dark"
                             style={{ backgroundImage: 'url(/assets/svg/components/wave-pattern-light.svg)' }}>
                            <div className="flex-grow-1 p-5">
                                {/* Blockquote */}
                                <figure className="text-center">
                                    <div className="mb-4">
                                        <h2 className="text-white">{t('pages.auth.login.title_slide_left')}</h2>
                                    </div>
                                    {quote && (
                                        <>
                                            <blockquote className="blockquote blockquote-light">
                                                {quote.message}
                                            </blockquote>
                                            <figcaption className="blockquote-footer blockquote-light">
                                                {quote.author}
                                                <span className="blockquote-footer-source">{quote.position}</span>
                                            </figcaption>
                                        </>
                                    )}
                                </figure>
                            </div>
                        </div>

                        <div className="col-lg-7 col-xl-8 d-flex justify-content-center align-items-center min-vh-lg-100">
                            <div className="flex-grow-1 mx-auto" style={{ maxWidth: '28rem' }}>
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default AuthLayout;