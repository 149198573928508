import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, FreeMode } from 'swiper/modules';

// Importar estilos de Swiper
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const GallerySwiper = ({ images = [] }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div>
            {/* Swiper Principal */}
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mb-2"
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img
                            className="img-fluid"
                            src={image.large || image}
                            alt={`Image ${index + 1}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Swiper Miniaturas */}
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiper-thumbs"
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img
                            className="img-fluid cursor-pointer"
                            src={image.thumb || image}
                            alt={`Thumbnail ${index + 1}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default GallerySwiper;