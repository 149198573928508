import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const collapseRef = useRef(null);
    const bootstrapCollapseRef = useRef(null);

    const services = [
        {
            title: t('services.platforms.gps'),
            url: 'https://www.orbytrace.com',
            external: true
        },
        {
            title: t('services.platforms.appquick'),
            url: '#',
            badge: t('services.platforms.coming_soon'),
            disabled: true
        },
        {
            title: t('services.platforms.orbystore'),
            url: '#',
            badge: t('services.platforms.coming_soon'),
            disabled: true
        }
    ];

    const developmentServices = [
        {
            title: t('services.development.web'),
            badge: '(Desktop / Web)',
            url: '/services/web-development'
        },
        {
            title: t('services.development.app'),
            badge: '(Nativas / Hibridas)',
            url: '/services/app-development'
        },
        {
            title: t('services.development.api'),
            badge: 'Microservicios / SOA',
            url: '/services/api-development'
        },
        {
            title: t('actions.see_all') + ' ' + t('layout.header.menu.services'),
            badge: '',
            url: '/services/all-development'
        }
    ];

    useEffect(() => {
        const initBootstrap = async () => {
            try {
                const bootstrap = await import('bootstrap');
                bootstrapCollapseRef.current = new bootstrap.Collapse(collapseRef.current, {
                    toggle: false
                });
            } catch (error) {
                console.error('Error loading Bootstrap:', error);
            }
        };

        initBootstrap();

        return () => {
            if (bootstrapCollapseRef.current) {
                bootstrapCollapseRef.current.dispose();
            }
        };
    }, []);

    const closeMenu = () => {
        if (bootstrapCollapseRef.current) {
            bootstrapCollapseRef.current.hide();
            setIsMenuOpen(false);
        }
    };

    const handleToggle = () => {
        if (isMenuOpen) {
            closeMenu();
        } else {
            if (bootstrapCollapseRef.current) {
                bootstrapCollapseRef.current.show();
                setIsMenuOpen(true);
            }
        }
    };

    const handleLinkClick = (e, to) => {
        e.preventDefault();
        closeMenu();
        navigate(to);
    };

    const handleExternalLinkClick = (e, url) => {
        e.preventDefault();
        closeMenu();
        window.open(url, '_blank');
    };

    return (
        <div className="container position-relative">
            <nav className="js-mega-menu navbar-nav-wrap">
                <Link to="/" className="navbar-brand" aria-label="Front" onClick={(e) => handleLinkClick(e, '/')}>
                    <img className="navbar-brand-logo" src="/assets/logo/secundary_sm.png" alt="Logo" />
                </Link>

                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={handleToggle}
                    aria-controls="navbarNavDropdown"
                    aria-expanded={isMenuOpen}
                    aria-label="Toggle navigation"
                >
                    <span className={isMenuOpen ? "d-none" : "navbar-toggler-default"}>
                        <i className="bi-list"></i>
                    </span>
                    <span className={!isMenuOpen ? "d-none" : "navbar-toggler-toggled"}>
                        <i className="bi-x"></i>
                    </span>
                </button>

                <div
                    ref={collapseRef}
                    className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
                    id="navbarNavDropdown"
                >
                    <div className="navbar-absolute-top-scroller">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link
                                    to="/"
                                    className="nav-link active"
                                    onClick={(e) => handleLinkClick(e, '/')}
                                >
                                    {t('layout.header.menu.home')}
                                </Link>
                            </li>

                            <li className="hs-has-mega-menu nav-item position-static">
                                <a
                                    id="landingsMegaMenu"
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {t('layout.header.menu.services')}
                                </a>

                                <div
                                    className="dropdown-menu w-100 navbar-dropdown-menu-borderless"
                                    aria-labelledby="landingsMegaMenu"
                                    style={{
                                        minWidth: '30rem',
                                        left: '50%',
                                        transform: 'translateX(-50%)'
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-lg-3 d-none d-lg-block">
                                            <div
                                                className="navbar-dropdown-menu-banner"
                                                style={{ backgroundImage: 'url(/assets/svg/components/shape-3.svg)' }}
                                            >
                                                <div className="navbar-dropdown-menu-banner-content">
                                                    <div className="mb-4">
                                                        <span className="small d-block">
                                                            {t('components.widgets.contact.subtitle')}
                                                        </span>
                                                    </div>
                                                    <Link
                                                        to="/contact"
                                                        className="btn btn-primary btn-transition"
                                                        onClick={(e) => handleLinkClick(e, '/contact')}
                                                    >
                                                        {t('actions.contact')} <i className="bi-chevron-right small"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-9">
                                            <div className="navbar-dropdown-menu-inner">
                                                <div className="row">
                                                    <div className="col-sm mb-3 mb-sm-0">
                                                        <span className="dropdown-header">
                                                            {t('services.platforms.title')}
                                                        </span>
                                                        {services.map((service, index) => (
                                                            service.external ? (
                                                                <a
                                                                    key={index}
                                                                    className="dropdown-item"
                                                                    href={service.url}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleExternalLinkClick(e, service.url);
                                                                    }}
                                                                    {...(service.disabled ? { disabled: true } : {})}
                                                                >
                                                                    {service.title}
                                                                    {service.badge && (
                                                                        <span className="badge bg-primary rounded-pill ms-1">
                                                                            {service.badge}
                                                                        </span>
                                                                    )}
                                                                </a>
                                                            ) : (
                                                                <Link
                                                                    key={index}
                                                                    to={service.url}
                                                                    className="dropdown-item"
                                                                    onClick={(e) => handleLinkClick(e, service.url)}
                                                                    {...(service.disabled ? { disabled: true } : {})}
                                                                >
                                                                    {service.title}
                                                                    {service.badge && (
                                                                        <span className="badge bg-primary rounded-pill ms-1">
                                                                            {service.badge}
                                                                        </span>
                                                                    )}
                                                                </Link>
                                                            )
                                                        ))}
                                                    </div>

                                                    <div className="col-sm">
                                                        <div className="mb-3">
                                                            <span className="dropdown-header">
                                                                {t('pages.home.development_web.title')}
                                                            </span>
                                                            {developmentServices.map((service, index) => (
                                                                <Link
                                                                    key={index}
                                                                    to={service.url}
                                                                    className="dropdown-item"
                                                                    onClick={(e) => handleLinkClick(e, service.url)}
                                                                >
                                                                    {service.title}
                                                                    {service.badge && (
                                                                        <span className="badge bg-black">
                                                                            {service.badge}
                                                                        </span>
                                                                    )}
                                                                </Link>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/hosting"
                                    className="nav-link"
                                    onClick={(e) => handleLinkClick(e, '/hosting')}
                                >
                                    {t('layout.header.menu.hosting')}
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/store"
                                    className="nav-link"
                                    onClick={(e) => handleLinkClick(e, '/store')}
                                >
                                    {t('layout.header.menu.store')}
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/about-us"
                                    className="nav-link"
                                    onClick={(e) => handleLinkClick(e, '/about-us')}
                                >
                                    {t('layout.header.menu.about')}
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/blog"
                                    className="nav-link"
                                    onClick={(e) => handleLinkClick(e, '/blog')}
                                >
                                    {t('layout.header.menu.blog')}
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/contact"
                                    className="nav-link"
                                    onClick={(e) => handleLinkClick(e, '/contact')}
                                >
                                    {t('layout.header.menu.contact')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;