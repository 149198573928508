import React from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {ToastContainer} from 'react-toastify';
import {useSelector} from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import MainLayout from './layouts/MainLayout';
import AuthLayout from './layouts/AuthLayout';
import PanelLayout from './layouts/PanelLayout';

import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';


import Web from "./pages/services/Web";
import Api from "./pages/services/Api";
import Apps from "./pages/services/App";
import All from "./pages/services/All";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Home from "./pages/Home";
import Hosting from "./pages/Hosting";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/panel/Profile";
import Security from "./pages/panel/Security";

import {selectIsLoggedIn, selectToken} from './store/slice/authSlice';
import {selectUser} from './store/slice/userSlice';
import Notification from "./pages/panel/Notification";
import Purchases from "./pages/panel/Purchases";
import {AuthProvider, useAuth} from "./context/AuthContext";
import Clients from "./pages/panel/admin/Clients";
import Products from "./pages/panel/admin/Products";
import Technologies from "@pages/panel/admin/Technologies";
import Categories from "@pages/panel/admin/Categories";


import BlogPosts from "@pages/panel/admin/BlogPosts";


import ErrorPage from "@pages/ErrorPage";
import Store from "@pages/store/Store";
import StoreSearch from "@pages/store/Search";
import StoreCategory from "@pages/store/Category";
import StoreTechnology from "@pages/store/Technology";
import StoreItem from "@pages/store/Item";

import Blog from '@pages/blog/Blog';
import BlogPost from '@pages/blog/Post';
import BlogTechnology from "@pages/blog/Technology";
import BlogCategory from "@pages/blog/Category";
import BlogStoreSearch from "@pages/blog/Search";
import RegisterConfirm from "@pages/auth/Register/RegisterConfirm";



const AuthWrapper = ({children}) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);
    const location = useLocation();
    const {isReadyForRedirect} = useAuth();

    // Para rutas del panel, siempre verificamos auth inmediatamente
    if (location.pathname.startsWith('/panel')) {
        if (!isLoggedIn || !token || !user) {
            return <Navigate to="/login" replace/>;
        }
    }

    // Para rutas de auth, solo redirigimos si estamos listos
    if (isReadyForRedirect && (location.pathname.startsWith('/login') ||
        location.pathname.startsWith('/register') ||
        location.pathname.startsWith('/forgot-password'))) {
        if (isLoggedIn && token && user) {
            return <Navigate to="/panel/profile" replace/>;
        }
    }

    return children;
};


const ProtectedRoute = ({children}) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);

    if (!isLoggedIn || !token || !user) {
        return <Navigate to="/login" replace/>;
    }

    return children;
};

const mainRoutes = [
    {path: '/', element: <Home/>},
    {path: '/services/web-development', element: <Web/>},
    {path: '/services/api-development', element: <Api/>},
    {path: '/services/app-development', element: <Apps/>},
    {path: '/services/all-development', element: <All/>},
    {path: '/about-us', element: <AboutUs/>},
    {path: '/contact', element: <Contact/>},

    {path: '/blog', element: <Blog/>},
    {path: '/blog/technology/:slug', element: <BlogTechnology/>},
    {path: '/blog/category/:slug', element: <BlogCategory/>},

    {path: '/blog/tecnologia/:slug', element: <BlogTechnology/>},
    {path: '/blog/categoria/:slug', element: <BlogCategory/>},

    {path: '/blog/:slug_category/:slug_blog', element: <BlogPost/>},
    {path: '/blog/search/:query', element: <BlogStoreSearch/>},
    {path: '/blog/buscar/:query', element: <BlogStoreSearch/>},


    {path: '/store', element: <Store/>},
    {path: '/store/technology/:slug', element: <StoreTechnology/>},
    {path: '/store/category/:slug', element: <StoreCategory/>},

    {path: '/store/tecnologia/:slug', element: <StoreTechnology/>},
    {path: '/store/categoria/:slug', element: <StoreCategory/>},

    {path: '/store/item/:slug', element: <StoreItem/>}, // Mantenemos la ruta simple como fallback
    {path: '/store/search/:search', element: <StoreSearch/>}, // Mantenemos la ruta simple como fallback

    {path: '/store/articulo/:slug', element: <StoreItem/>}, // Mantenemos la ruta simple como fallback
    {path: '/store/buscar/:query', element: <StoreSearch/>}, // Mantenemos la ruta simple como fallback



    {path: '/hosting', element: <Hosting/>},
    {path: '/privacy-policy', element: <PrivacyPolicy/>},
    {path: '/terms-and-conditions', element: <TermsConditions/>},
    {path: '/404', element: <ErrorPage/>}
];

const authRoutes = [
    {path: '/login', element: <Login/>},
    {path: '/register', element: <Register/>},
    {path: '/register/confirm', element: <RegisterConfirm/>},
    {path: '/forgot-password', element: <ForgotPassword/>}
];

const panelRoutes = [
    {path: '/panel/profile', element: <Profile/>},
    {path: '/panel/security', element: <Security/>},
    {path: '/panel/notification', element: <Notification/>},
    {path: '/panel/purchases', element: <Purchases/>},
    {path: '/panel/clients', element: <Clients/>},
    {path: '/panel/products', element: <Products/>},
    {path: '/panel/technologies', element: <Technologies/>},
    {path: '/panel/categories', element: <Categories/>},
    {path: '/panel/blog', element: <BlogPosts/>}
];

function App() {



    const generateRoutes = (routes, Layout, isProtected = false) => {
        return routes.flatMap(route => {
            const wrappedElement = Layout ? (
                <Layout>
                    {isProtected ? (
                        <ProtectedRoute>{route.element}</ProtectedRoute>
                    ) : (
                        route.element
                    )}
                </Layout>
            ) : (
                isProtected ? (
                    <ProtectedRoute>{route.element}</ProtectedRoute>
                ) : (
                    route.element
                )
            );

            return [
                <Route
                    key={route.path}
                    path={route.path}
                    element={wrappedElement}
                />,
                <Route
                    key={`/:lang${route.path}`}
                    path={`/:lang${route.path}`}
                    element={wrappedElement}
                />
            ];
        });
    };

    return (
        <HelmetProvider>

            <AuthProvider>
                <AuthWrapper>


                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                        limit={3}
                    />
                    <Routes>
                        <Route element={<MainLayout/>}>
                            {generateRoutes(mainRoutes)}
                        </Route>
                        <Route element={<AuthLayout/>}>
                            {generateRoutes(authRoutes)}
                        </Route>
                        <Route element={<PanelLayout/>}>
                            {generateRoutes(panelRoutes, null, true)}
                        </Route>
                        <Route path="*" element={<ErrorPage/>}/>
                    </Routes>
                </AuthWrapper>
            </AuthProvider>

        </HelmetProvider>
    );
}

export default App;