import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isReadyForRedirect, setIsReadyForRedirect] = useState(true);

    return (
        <AuthContext.Provider value={{ isReadyForRedirect, setIsReadyForRedirect }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};