import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from "../../services/api";
import { setSessionUser } from "../../store/slice/userSlice";
import { setSessionAuth, selectIsLoggedIn } from "../../store/slice/authSlice";
import {useAuth} from "../../context/AuthContext";
import {setPermissions} from "../../store/slice/permissionSlice";


function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const { setIsReadyForRedirect } = useAuth();

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    useEffect(() => {
        setIsReadyForRedirect(false);
        return () => setIsReadyForRedirect(true);
    }, [setIsReadyForRedirect]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            setIsReadyForRedirect(false);

            const response = await api.postAwaiting('auth/login', formData, true);

            if (response.data.token) {
                await dispatch(setSessionAuth({ token: response.data.token, lang: 'es' }));


               let  sessionUser =  {
                    ...response.data.auth,
                    role: response.data.role.name
                }

                if (response.data.auth) {
                    await dispatch(setSessionUser(sessionUser));
                }


                const permissions = await api.postAwaiting('auth/permissions',{},false);
                dispatch(setPermissions(permissions.data.permissions));


                toast.success(t('pages.auth.login.success'));

                setIsReadyForRedirect(true);
            }
        } catch (error) {
            setIsReadyForRedirect(true);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <div className="text-center mb-5 mb-md-7 content-space-t-2">
                <h1 className="h2">{t('pages.auth.login.title')}</h1>
                <p>{t('pages.auth.login.description')}</p>
            </div>

            <form className="js-validate needs-validation" noValidate onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="form-label" htmlFor="signupModalFormLoginEmail">
                        {t('pages.auth.login.email.label')}
                    </label>
                    <input
                        type="email"
                        className="form-control form-control-lg"
                        name="email"
                        id="signupModalFormLoginEmail"
                        placeholder={t('pages.auth.login.email.placeholder')}
                        aria-label={t('pages.auth.login.email.placeholder')}
                        required
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <span className="invalid-feedback">{t('login.email.error')}</span>
                </div>

                <div className="mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <label className="form-label" htmlFor="signupModalFormLoginPassword">
                            {t('pages.auth.login.password.label')}
                        </label>
                        <a className="form-label-link" href="/forgot-password">
                            {t('pages.auth.login.password.forgot')}
                        </a>
                    </div>
                    <div className="input-group input-group-merge">
                        <input
                            type={showPassword ? "text" : "password"}
                            className="form-control form-control-lg"
                            name="password"
                            id="signupModalFormLoginPassword"
                            placeholder={t('pages.auth.login.password.placeholder')}
                            aria-label={t('pages.auth.login.password.placeholder')}
                            required
                            minLength="8"
                            value={formData.password}
                            onChange={handleChange}
                        />
                        <button
                            type="button"
                            className="input-group-append input-group-text"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <i className={`bi-${showPassword ? 'eye-slash' : 'eye'}`}></i>
                        </button>
                    </div>
                    <span className="invalid-feedback">{t('pages.auth.login.password.error')}</span>
                </div>

                <div className="d-grid mb-3">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        ) : null}
                        {t('pages.auth.login.submit')}
                    </button>
                </div>

                <div className="text-center">
                    <p>
                        {t('pages.auth.login.signupPrompt')} {'  '}
                        <a className="link" href="/register">
                            {t('pages.auth.login.signupLink')}
                        </a>
                    </p>
                </div>
            </form>

            <div className="w-md-85 text-lg-center mx-lg-auto mt-lg-10">
                <p className="text-primary small">
                    &copy; Wecodex Solutions | <Link to='/terms-and-conditions'>{t('layout.footer.copyright')}</Link>
                </p>
            </div>
        </div>
    );
}

export default Login;