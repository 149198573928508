import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import api from "@services/api";
import Head from "@components/layout/Head";
import Carusel from '@components/widgets/Carusel';
import NewsletterSubscribe from "@components/widgets/NewsletterSubscribe";
import Shuffle from 'shufflejs';


const Technology = () => {
    const {t, i18n} = useTranslation();
    const currentLocale = i18n.language;
    const [technologies, setTechnologies] = useState([]);
    const [technology, setTechnology] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('all');
    const shuffleInstance = useRef(null);
    const [posts, setPosts] = useState([]);

    const {slug} = useParams();

    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 5
    });

    // Estado para niveles filtrados
    const [levels, setLevels] = useState([]);

    const [heroSlides, setHeroSlides] = useState([
        {
            title: t('pages.blog.hero.slides.1.title'),
            subtitle: t('pages.blog.hero.slides.1.subtitle'),
            image: '/assets/img/banners/technology-3.jpg',
            imagePosition: 'center',
            brightness: 0.7
        },
        {
            title: t('pages.blog.hero.slides.2.title'),
            subtitle: t('pages.blog.hero.slides.2.subtitle'),
            image: '/assets/img/banners/technology-2.jpg',
            imagePosition: 'center',
            brightness: 0.6
        },
        {
            title: t('pages.blog.hero.slides.3.title'),
            subtitle: t('pages.blog.hero.slides.3.subtitle'),
            image: '/assets/img/banners/technology-3.jpg',
            imagePosition: 'center',
            brightness: 0.7
        },
        {
            title: t('pages.blog.hero.slides.4.title'),
            subtitle: t('pages.blog.hero.4.subtitle'),
            image: '/assets/img/banners/technology-4.jpg',
            imagePosition: 'top',
            brightness: 0.5
        },
        {
            title: t('pages.blog.hero.slides.5.title'),
            subtitle: t('pages.blog.hero.slides.5.subtitle'),
            image: '/assets/img/banners/technology-5.jpg',
            imagePosition: 'center',
            brightness: 0.6
        },
        {
            title: t('pages.blog.hero.slides.6.title'),
            subtitle: t('pages.blog.hero.slides.subtitle'),
            image: '/assets/img/banners/technology-6.jpg',
            imagePosition: 'center',
            brightness: 0.7
        },
        {
            title: t('pages.blog.hero.slides.title'),
            subtitle: t('pages.blog.hero.slides.subtitle'),
            image: '/assets/img/banners/technology-7.jpg',
            imagePosition: 'center',
            brightness: 0.6
        },
        {
            title: t('pages.blog.hero.slides.8.title'),
            subtitle: t('pages.blog.hero.slides.subtitle'),
            image: '/assets/img/banners/technology-8.jpg',
            imagePosition: 'center',
            brightness: 0.7
        },
        {
            title: t('pages.blog.hero.slides.9.title'),
            subtitle: t('pages.blog.hero.slides.9.subtitle'),
            image: '/assets/img/banners/technology-5.jpg',
            imagePosition: 'center',
            brightness: 0.6
        },
        {
            title: t('pages.blog.hero.slides.10.title'),
            subtitle: t('pages.blog.hero.slides.10.subtitle'),
            image: '/assets/img/banners/technology-8.jpg',
            imagePosition: 'center',
            brightness: 0.7
        }
    ]);

    const renderPaginationItems = () => {
        const items = [];
        const maxVisible = 5;
        let startPage = Math.max(1, pagination.currentPage - 2);
        let endPage = Math.min(pagination.lastPage, startPage + maxVisible - 1);

        if (endPage - startPage + 1 < maxVisible) {
            startPage = Math.max(1, endPage - maxVisible + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <li key={i} className={`page-item ${pagination.currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                        {pagination.currentPage === i && (
                            <span className="visually-hidden">{t('common.current')}</span>
                        )}
                    </button>
                </li>
            );
        }

        return items;
    };


    const getTranslation = (item, field) => {
        if (!item?.translations) return '';
        const translation = item.translations.find(t => t.locale === currentLocale)
            || item.translations[0];
        return translation?.[field] || '';
    };


    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.lastPage) {
            getPosts(newPage, technology?.id);
        }
    };


    const getPosts = async (page = 1, technologyId) => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting('blog/posts', {
                page,
                per_page: pagination.perPage,
                technology: technologyId,
                with: ['technologies', 'translations'],
                search: searchTerm,
            }, true);

            if (response && response.data) {
                setPosts(response.data);
                setPagination({
                    currentPage: response.current_page || 1,
                    lastPage: response.last_page || 1,
                    total: response.total || 0,
                    perPage: response.per_page || 5
                });
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
            setPosts([]);
        } finally {
            setIsLoading(false);
        }
    };

    const getTecnology = async () => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting('catalogs/technologies/' + slug, {
                filter: 'slug',
            }, true);
            if (response?.data) {

                setTechnology(response.data);
                getPosts(1,response.data.id);

            }
        } catch (error) {
            console.error('Error fetching technologies:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Obtener tecnologías
    const getTechnologies = async () => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting('catalogs/technologies', {
                filter: 'all',
            }, true);
            if (response?.data) {
                setTechnologies(response.data);
                // Extraer niveles únicos
                const uniqueLevels = [...new Set(response.data.map(tech => tech.level))];
                setLevels(uniqueLevels.sort((a, b) => a - b));
            }
        } catch (error) {
            console.error('Error fetching technologies:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Inicializar Shuffle.js
    useEffect(() => {
        let shuffleInstance = null;

        const initShuffle = () => {
            const gridElement = document.querySelector('.js-shuffle');
            if (gridElement && !isLoading && technologies.length > 0) {
                try {
                    shuffleInstance = new Shuffle(gridElement, {
                        itemSelector: '.js-shuffle-item',
                        sizer: null
                    });
                } catch (error) {
                    console.error('Error initializing Shuffle:', error);
                }
            }
        };






        initShuffle();

        return () => {
            if (shuffleInstance) {
                try {
                    shuffleInstance.destroy();
                } catch (error) {
                    console.error('Error destroying Shuffle:', error);
                }
            }
        };
    }, [isLoading, technologies]);

    const [selectedBackground, setSelectedBackground] = useState(heroSlides[0]);

    const selectRandomBackground = () => {
        const randomIndex = Math.floor(Math.random() * heroSlides.length);
        setSelectedBackground(heroSlides[randomIndex]);
    };
    useEffect(() => {
        getTecnology();
        getTechnologies();
        selectRandomBackground(); // Selecciona un fondo aleatorio cuando cambia la categoría

    }, [slug]);

    // Filtrar tecnologías por nivel y término de búsqueda
    const filteredTechnologies = useMemo(() => {
        return technologies.filter(tech => {
            const matchesLevel = selectedLevel === 'all' || tech.level.toString() === selectedLevel;
            const matchesSearch = searchTerm === '' ||
                getTranslation(tech, 'name').toLowerCase().includes(searchTerm.toLowerCase()) ||
                getTranslation(tech, 'description').toLowerCase().includes(searchTerm.toLowerCase());

            return matchesLevel && matchesSearch;
        });
    }, [technologies, selectedLevel, searchTerm]);



    return (
        <main id="content" role="main">
            <Head pagePath="technologies"/>

            {/* Hero Section */}
            <div className="bg-dark content-space-t-lg-2 position-relative overflow-hidden">
                {/* Background Image */}
                <div
                    className="position-absolute w-100 h-100"
                    style={{
                        backgroundImage: 'url('+selectedBackground.image+')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        opacity: selectedBackground.opacity,
                        top: 0,
                        left: 0
                    }}
                />

                {/* Pattern Overlay */}
                <div
                    className="position-absolute w-100 h-100"
                    style={{
                        backgroundImage: selectedBackground.pattern,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        opacity: 0.3,
                        top: 0,
                        left: 0
                    }}
                />

                {/* Dark Overlay */}
                <div
                    className="position-absolute w-100 h-100"
                    style={{
                        background: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.6) 100%)',
                        top: 0,
                        left: 0
                    }}
                />

                {/* Content */}
                <div className="container content-space-2 content-space-lg-3 position-relative">
                    <div className="w-lg-65 text-center mx-lg-auto">
                        <h1 className="text-white mb-3">{getTranslation(technology, 'name')}</h1>
                        <p className="text-white-70">{getTranslation(technology, 'description')}</p>
                    </div>
                </div>
            </div>
            <div className="container content-space-2 content-space-lg-3">

                <div className="row mb-5">
                    <div className="col">
                        <div className='divider divider-center f-1 p-5'><h2>{t('pages.technology.posts.title')}</h2></div>

                    </div>
                </div>
            <div className="js-shuffle row g-4">
                {isLoading ? (
                    <div className="col-12 text-center py-4">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : posts.length > 0 ? (
                    posts.map(post => (
                        <div className="js-shuffle-item col-md-4" key={post.id}>
                            <Link to={`/blog/${getTranslation(post?.default_technology, 'slug')}/${getTranslation(post, 'slug')}`}>
                                <div className="card h-100 shadow-sm hover-lift">
                                    {post.is_top && (
                                        <div className="position-absolute top-0 start-0 p-3">
                                                <span className="badge bg-primary">
                                                    <i className="bi-star-fill me-1"></i>
                                                    {t('pages.blog.post.featured')}
                                                </span>
                                        </div>
                                    )}

                                    <img
                                        className="card-img-top"
                                        src={post.preview_image}
                                        alt={getTranslation(post, 'title')}
                                        style={{height: '200px', objectFit: 'cover'}}
                                    />

                                    <div className="card-body">
                                        <div className="mb-3">
                                            {post.technologies?.data?.map(technology => (
                                                <span key={technology.id} className="badge bg-secondary me-1">
                                                        <i className={`${technology.icon} me-1`}></i>
                                                    {getTranslation(technology, 'name')}
                                                    </span>
                                            ))}
                                        </div>

                                        <h3 className="h5">
                                            {getTranslation(post, 'title')}
                                        </h3>

                                        <p className="card-text">
                                            {getTranslation(post, 'description_short')}
                                        </p>
                                    </div>

                                    <div className="card-footer bg-white">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex gap-2">
                                                {post.share_facebook && (
                                                    <i className="bi-facebook text-primary cursor-pointer"></i>
                                                )}
                                                {post.share_twitter && (
                                                    <i className="bi-twitter text-info cursor-pointer"></i>
                                                )}
                                                {post.share_linkedin && (
                                                    <i className="bi-linkedin text-primary cursor-pointer"></i>
                                                )}
                                            </div>
                                            <small className="text-muted">
                                                {new Date(post.created_at).toLocaleDateString(currentLocale)}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))
                ) : (
                    <div className="col-12">
                        <div className="text-center py-5">
                            <div className="mb-4">
                                <img
                                    src="/assets/svg/illustrations/oc-empty.svg"
                                    alt="No posts found"
                                    className="mb-4"
                                    style={{maxWidth: '15rem'}}
                                />
                                <h3>{t('pages.technology.posts.empty.title')}</h3>
                                <p className="text-muted">
                                    {t('pages.technology.posts.empty.message')}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* Pagination */}
            {posts.length > 0 && pagination.lastPage > 1 && (
                <nav className="mt-5" aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                        <li className={`page-item ${pagination.currentPage === 1 ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(pagination.currentPage - 1)}
                            >
                                <i className="bi-chevron-left small"></i>
                                <span className="ms-1">{t('common.previous')}</span>
                            </button>
                        </li>

                        {renderPaginationItems()}

                        <li className={`page-item ${pagination.currentPage === pagination.lastPage ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(pagination.currentPage + 1)}
                            >
                                <span className="me-1">{t('common.next')}</span>
                                <i className="bi-chevron-right small"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
            )}
            </div>


            {/* Featured Technologies */}

            <div className="container content-space-2 content-space-lg-3">
                {/* Featured Carousel */}
                <Carusel
                    items={technologies.filter(tech => tech.level === 0)}
                    title={t('pages.technologies.featured.title')}
                    itemType="technology"
                    showDescription={true}
                    cardHeight="20rem"
                />

                {/* Search and Filters */}
                <div className="mb-7">
                    <div className="row align-items-center mb-5">
                        <div className="col-sm mb-3 mb-sm-0">
                            <h2 className="h3 mb-0">{t('pages.technologies.all.title')}</h2>
                        </div>

                        <div className="col-sm-auto">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="tom-select-custom">
                                        <select
                                            className="form-select"
                                            value={selectedLevel}
                                            onChange={(e) => setSelectedLevel(e.target.value)}
                                        >
                                            <option value="all">{t('pages.technologies.filters.allLevels')}</option>
                                            {levels.map(level => (
                                                <option key={level} value={level.toString()}>
                                                    {t(`pages.technologies.filters.level${level}`)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col">
                                    <form>
                                        <div className="input-group input-group-merge">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('pages.technologies.search.placeholder')}
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <button type="button" className="btn btn-primary">
                                                <i className="bi-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Technologies Grid */}
                {isLoading ? (
                    <div className="text-center py-4">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                    </div>
                ) : (
                    <div className="js-shuffle row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                        {filteredTechnologies.map((tech) => (
                            <div key={tech.id} className="js-shuffle-item col mb-5">
                                <Link
                                    to={`/technology/${getTranslation(tech, 'slug')}`}
                                    className="card card-flush h-100"
                                >
                                    <div className="card-pinned">
                                        <div className="card-pinned-top-end">
                                            <span className="badge bg-dark rounded-pill">
                                                {t(`pages.technologies.filters.level${tech.level}`)}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="card-body text-center">
                                        <div className="mb-3">
                                            <i className={`${tech.icon} fs-1 text-primary`}></i>
                                        </div>
                                        <h4 className="card-title">{getTranslation(tech, 'name')}</h4>
                                        <p className="card-text text-body small">
                                            {getTranslation(tech, 'description')}
                                        </p>
                                    </div>

                                    <div className="card-footer pt-0">
                                        <span className="card-link">{t('common.learnMore')}</span>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Newsletter Section */}
            <div className="border-top">
                <div className="container content-space-2">
                    <NewsletterSubscribe/>
                </div>
            </div>
        </main>
    );
};

export default Technology;