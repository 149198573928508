import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import api from "@services/api";
import {useNavigate, useParams} from "react-router";
import Head from "@components/layout/Head";
import FacebookComments from "@components/widgets/FacebookComments";
import TypedText from "@components/elements/TypedText";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import NewsletterSubscribe from "@components/widgets/NewsletterSubscribe";

const Post = () => {
    const {t, i18n} = useTranslation();
    const [itemData, setItemData] = useState({name: null});
    const [isLoading, setIsLoading] = useState(false);
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [title, setTitle] = useState('... .. ');
    const {slug_blog} = useParams();
    const currentLocale = i18n.language;
    const navigate = useNavigate();

    const backgroundImages = [
        'url(/assets/img/400x500/img13.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(/assets/img/400x500/img14.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(/assets/img/400x500/img15.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(/assets/img/400x500/img16.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(/assets/img/400x500/img17.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales
        'url(/assets/img/400x500/img18.jpg)',  // Usaremos placeholders pero en producción serían las imágenes reales

    ];



    const getTranslation = (item, field) => {
        if (!item?.translations) return '';

        const translation = item?.translations?.find(
            (t) => t.locale === currentLocale
        );
        return translation?.[field] ||
            item.translations[0]?.[field] ||
            item?.[field] ||
            "";
    };

    const getItem = async () => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting("blog/posts/" + slug_blog, {
                filter: 'locale',
                with: ["translations", "technologies", "categories"],
            }, false);
            setItemData(response.data);
            // Actualizamos el título después de obtener los datos
            const newTitle = getTranslation(response.data, 'title');
            if (newTitle) {
                setTitle(newTitle);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching post:', error);
        }
    };

    const getPostUrl = () => {
        return `${window.location.origin}/blog/${slug_blog}`;
    };

    // Solo ejecutamos getItem cuando cambia el slug
    useEffect(() => {
        if (slug_blog) {
            getItem();
        }
    }, [slug_blog]);


    return (
        <>
            <Head
                pagePath="blog"
                postData={itemData?.translations ? itemData : null}
                baseUrl={window.location.origin}
            />
            {isLoading ? (
                <div className="container content-space-t-3 d-flex text-center align-content-center content-space-t-lg-4 content-space-b-2">
                    <div className="w-lg-100 mx-lg-auto">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            ) : (
                <main id="content" role="main">
                    {/* Hero Section */}
                    <div className="bg-dark position-relative overflow-hidden" style={{backgroundImage: `url(${itemData.slider_image})`, backgroundSize: 'cover'}}>
                        {/* Fade overlay - Mover antes del contenedor del texto y ajustar z-index */}
                        <div
                            className="position-absolute w-100 h-100"
                            style={{
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.65) 100%)',
                                zIndex: 1,
                                top: 0,
                                left: 0
                            }}
                        />

                        {/* Contenedor del texto - Aumentar z-index */}
                        <div className="container position-relative content-space-t-5 content-space-t-lg-5 content-space-b-2" style={{ zIndex: 2 }}>
                            <div className="w-md-80 w-lg-75 text-center mx-md-auto mb-1 mb-md-1">
                                <TypedText
                                    strings={[title]}
                                    className="display-4 text-white"
                                    typeSpeed={40}
                                    backSpeed={50}
                                    loop={false}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Article Content */}
                    <div className="container content-space-t-1 content-space-b-2">
                        <div className="w-lg-65 mx-lg-auto">
                            {/* Author and Share Section */}
                            <div className="row align-items-sm-center mb-5">
                                <div className="col-sm-7 mb-4 mb-sm-0">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <span className="d-block small">
                                                {new Date(itemData.created_at).toLocaleDateString()}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-5">
                                    <div className="d-flex justify-content-sm-end align-items-center">
                                        <span className="text-cap mb-0 me-2">{t('Share')}:</span>
                                        <div className="d-flex gap-2">
                                            {itemData.share_facebook && (
                                                <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle"
                                                   href={`https://www.facebook.com/sharer/sharer.php?u=${getPostUrl()}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <i className="bi-facebook"></i>
                                                </a>
                                            )}
                                            {itemData.share_twitter && (
                                                <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle"
                                                   href={`https://twitter.com/intent/tweet?url=${getPostUrl()}&text=${getTranslation(itemData, 'title')}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <i className="bi-twitter"></i>
                                                </a>
                                            )}
                                            {itemData.share_linkedin && (
                                                <a className="btn btn-soft-secondary btn-sm btn-icon rounded-circle"
                                                   href={`https://www.linkedin.com/sharing/share-offsite/?url=${getPostUrl()}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <i className="bi-linkedin"></i>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Description Short */}
                            <p className="lead">{getTranslation(itemData, 'description_short')}</p>

                            {/* Main Image */}
                            {itemData.preview_image && (
                                <div className="my-4 my-sm-8">
                                    <img className="img-fluid  img-thumbnail rounded-lg"
                                         src={itemData.preview_image}
                                         alt={getTranslation(itemData, 'title')}/>
                                </div>
                            )}

                            <figure className="bg-light text-center p-7 my-7">
                                <blockquote className="blockquote blockquote-lg">
                                    <p className="lead">{getTranslation(itemData, 'description_long')}</p>

                                </blockquote>

                            </figure>

                            {/* Main Content */}
                            <div className="mb-5">
                                <div dangerouslySetInnerHTML={{ __html: getTranslation(itemData, 'content') }} />
                            </div>

                            {/* Gallery */}
                            {itemData.images && typeof itemData.images === 'string' && (
                                <div className="my-4 my-sm-8">
                                    {JSON.parse(itemData.images).map((image, index) => (
                                        <img
                                            key={index}
                                            className="img-fluid rounded-lg mb-3"
                                            src={image}
                                            alt={`Gallery ${index + 1}`}
                                        />
                                    ))}
                                </div>
                            )}

                            {/* Tags Section */}
                            <div className="mt-5">
                                {/* Technologies */}
                                {itemData.technologies?.data.map((tech) => (
                                    <a key={tech.id} className="btn btn-soft-primary btn-xs m-1" href="#">
                                        <i className={`bi ${tech.icon} me-1`}></i>
                                        {getTranslation(tech, 'name')}
                                    </a>
                                ))}

                                {/* Categories */}
                                {itemData.categories?.data.map((category) => (
                                    <a key={category.id} className="btn btn-soft-secondary btn-xs m-1" href="#">
                                        <i className={`bi ${category.icon} me-1`}></i>
                                        {getTranslation(category, 'name')}
                                    </a>
                                ))}
                            </div>


                            {/* Colocar justo después del contenido principal y antes del slider de categorías */}

                            {/* Technologies Section */}
                            {itemData.technologies?.data?.length > 0 && (
                                <div className="content-space-1">
                                    <div className="row gx-3 mx-n2 ">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h4 className="mb-0"><i className="bi bi-cpu-fill"/> {t('Technologies')}</h4>
                                            <a className="link" href="/blog/technologies">
                                                {t('View all')} <i className="bi-chevron-right small ms-1"></i>
                                            </a>
                                        </div>

                                        {itemData.technologies.data.map((tech, index) => (
                                            <div key={tech.id} className="col-sm-3 col-lg-3 mb-4">
                                                <a
                                                    href={`/blog/technology/${getTranslation(tech,'slug')}`}
                                                    className="card card-sm card-stretched-vertical card-transition bg-img-start h-100"
                                                    style={{
                                                        backgroundImage: `url(${tech.image || backgroundImages[index % backgroundImages.length].replace('url(', '').replace(')', '')})`,
                                                        minHeight: '5rem',
                                                        position: 'relative',
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center'
                                                    }}
                                                >
                                                    {/* Overlay para mejorar la legibilidad del texto */}
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgb(0 0 0 / 0%));'
                                                    }}></div>

                                                    <div className="text-center position-relative pt-2">
                                                        {/* Icono */}
                                                        <div>
                                                            <i className={`bi ${tech.icon} text-white fs-1`}></i>
                                                        </div>
                                                        {/* Nombre */}
                                                        <h6 className="text-white small">
                                                            {getTranslation(tech, 'name')}
                                                        </h6>
                                                        {/* Descripción */}
                                                        <p className="text-white text-size-xs">
                                                            {getTranslation(tech, 'description')}
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}



                            {/* Bottom Share Section */}
                            <div className="row justify-content-sm-between align-items-sm-center mt-5">
                                <div className="col-sm mb-2 mb-sm-0">
                                    <div className="d-flex align-items-center">
                                        <span className="text-cap mb-0 me-2">{t('Share')}:</span>
                                        <div className="d-flex gap-2">
                                            {itemData.share_facebook && (
                                                <a className="btn btn-ghost-secondary btn-sm btn-icon rounded-circle me-2"
                                                   href={`https://www.facebook.com/sharer/sharer.php?u=${getPostUrl()}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <i className="bi-facebook"></i>
                                                </a>
                                            )}
                                            {itemData.share_twitter && (
                                                <a className="btn btn-ghost-secondary btn-sm btn-icon rounded-circle me-2"
                                                   href={`https://twitter.com/intent/tweet?url=${getPostUrl()}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <i className="bi-twitter"></i>
                                                </a>
                                            )}
                                            {itemData.share_linkedin && (
                                                <a className="btn btn-ghost-secondary btn-sm btn-icon rounded-circle me-2"
                                                   href={`https://www.linkedin.com/sharing/share-offsite/?url=${getPostUrl()}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <i className="bi-linkedin"></i>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Comments Section */}
                            {itemData.allow_comments && (
                                <div className="comments-section mt-5 pt-5 border-top">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10">
                                                <h4 className="mb-4">{t('Comments')}</h4>
                                                <FacebookComments url={getPostUrl()}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>



                    </div>


                    <NewsletterSubscribe/>
                </main>
            )}
        </>
    );
};

export default Post;