import React from 'react';
import PropTypes from 'prop-types';

const BoxIcon = ({
                     icon,
                     label,
                     checked,
                     onChange,
                     social = 'default',
                     disabled = false
                 }) => {
    const getColorClass = () => {
        if (social && social !== 'default') {
            return `color-${social}`;
        }
        return '';
    };

    return (
        <div
            className={`
                social-box 
                ${checked ? 'active' : ''} 
                ${getColorClass()}
                ${disabled ? 'opacity-50' : ''}
            `}
            onClick={() => !disabled && onChange(!checked)}
            role="button"
            tabIndex={0}
        >
            <div className="icon-container">
                <i className={`bi ${icon}`}></i>
            </div>
            <span className="fw-medium">
                {label}
            </span>
            <div className="toggle-circle">
                <i className="bi bi-check2"></i>
            </div>
        </div>
    );
};

BoxIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    social: PropTypes.oneOf(['default', 'facebook', 'instagram', 'twitter', 'linkedin', 'pinterest']),
    disabled: PropTypes.bool
};

export default BoxIcon;