import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from 'lucide-react';

const Table = ({
                   headers = [],
                   data = [],
                   columns = [],
                   isTheadLight = true,
                   isResponsive = true,
                   searchable = true,
                   searchKeys = [],
                   loading = false,
                   // Configuración de paginación
                   pagination = {
                       enabled: true,           // Habilitar/deshabilitar paginación
                       serverSide: false,       // true para API, false para local
                       currentPage: 1,          // Página actual
                       lastPage: 1,             // Última página (para API)
                       total: 0,                // Total de registros (para API)
                       perPage: 10,             // Registros por página
                       onChange: null,          // Callback para cambio de página (API)
                       onPerPageChange: null    // Callback para cambio de registros por página (API)
                   },
                   // Configuración de búsqueda
                   search = {
                       serverSide: false,       // true para API, false para local
                       onSearch: null          // Callback para búsqueda (API)
                   }
               }) => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(pagination.currentPage);
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(pagination.perPage);

    const rowsPerPageOptions = [5, 10, 25, 50, 100];

    // Búsqueda local
    const filteredData = useMemo(() => {
        if (!searchable || search.serverSide || !searchTerm) return data;

        return data.filter(item => {
            return searchKeys.some(key => {
                const value = item[key]?.toString().toLowerCase();
                return value?.includes(searchTerm.toLowerCase());
            });
        });
    }, [data, searchTerm, searchKeys, search.serverSide, searchable]);

    // Paginación local
    const paginatedData = useMemo(() => {
        if (!pagination.enabled || pagination.serverSide) return data;

        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredData.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredData, currentPage, itemsPerPage, pagination.enabled, pagination.serverSide]);

    // Cálculos de paginación
    const totalItems = pagination.serverSide ? pagination.total : filteredData.length;
    const totalPages = pagination.serverSide
        ? pagination.lastPage
        : Math.max(1, Math.ceil(totalItems / itemsPerPage));

    // Manejadores de eventos
    const handlePageChange = (page) => {
        if (pagination.serverSide && pagination.onChange) {
            pagination.onChange(page);
        } else {
            setCurrentPage(page);
        }
    };

    const handleItemsPerPageChange = (value) => {
        const newPerPage = Number(value);
        if (pagination.serverSide && pagination.onPerPageChange) {
            pagination.onPerPageChange(newPerPage);
        } else {
            setItemsPerPage(newPerPage);
            setCurrentPage(1);
        }
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
        if (search.serverSide && search.onSearch) {
            search.onSearch(value);
        }
    };

    const renderPaginationControls = () => {
        if (!pagination.enabled) return null;

        return (
            <div className="d-flex justify-content-between align-items-center">
                <nav aria-label="Page navigation">
                    <ul className="pagination pagination-sm mb-0">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <small>{t('components.elements.table.pagination.prev')}</small>
                            </button>
                        </li>
                        {[...Array(totalPages)].map((_, index) => (
                            <li
                                key={index + 1}
                                className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                            >
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    <small>{index + 1}</small>
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <small>{t('components.elements.table.pagination.next')}</small>
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="d-flex align-items-center">
                    <small className="text-muted me-2">
                        {t('components.elements.table.pagination.showing')} {' '}
                        {((currentPage - 1) * itemsPerPage) + 1} {' '}
                        {t('components.elements.table.pagination.to')} {' '}
                        {Math.min(currentPage * itemsPerPage, totalItems)} {' '}
                        {t('components.elements.table.pagination.of')} {totalItems} {' '}
                        {t('components.elements.table.pagination.entries')}
                    </small>
                    <select
                        className="form-select form-select-sm w-auto py-0"
                        style={{ fontSize: '0.875rem', height: '24px', color:'#367eff' }}
                        value={itemsPerPage}
                        onChange={(e) => handleItemsPerPageChange(e.target.value)}
                    >
                        {rowsPerPageOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
            </div>
        );
    };

    const renderTableContent = () => {
        if (loading) {
            return (
                <tr>
                    <td colSpan={headers.length} className="text-center py-5">
                        <div className="d-flex flex-column align-items-center">
                            <div className="spinner-border text-primary mb-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }

        const displayData = pagination.serverSide ? data : paginatedData;

        if (displayData.length === 0) {
            return (
                <tr>
                    <td colSpan={headers.length} className="text-center py-4">
                        {t('components.elements.table.search.noResults')}
                    </td>
                </tr>
            );
        }

        return displayData.map((item, rowIndex) => (
            <tr key={item.id || rowIndex}>
                {columns.map((column, colIndex) => (
                    <td key={`${rowIndex}-${colIndex}`}>
                        <small>{column.render ? column.render(item) : item[column.key]}</small>
                    </td>
                ))}
            </tr>
        ));
    };

    return (
        <div className="card">
            {searchable && (
                <div className="border-bottom">
                    <div className="input-group">
                        <span className="input-group-text bg-transparent border-0 pe-1">
                            <Search size={14} />
                        </span>
                        <input
                            type="search"
                            className="form-control border-0 small"
                            placeholder={t('components.elements.table.search.placeholder')}
                            value={searchTerm}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                </div>
            )}

            <div className={isResponsive ? 'table-responsive' : ''}>
                <table className="table small table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table mb-0">
                    <thead className={isTheadLight ? 'thead-light' : ''}>
                    <tr>
                        {headers.map((header, index) => (
                            <th
                                key={index}
                                {...(header.width ? { style: { width: header.width } } : {})}
                            >
                                {header.title}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {renderTableContent()}
                    </tbody>
                </table>
            </div>

            <div className="card-footer border-top pt-3">
                {renderPaginationControls()}
            </div>
        </div>
    );
};

export default Table;