import React, { useEffect } from 'react';
import Header from '../components/layout/Header';
import TopBar from '../components/layout/TopBar';
import Footer from '../components/layout/Footer';
import { useScroll } from '../hooks/useScroll';
import AOS from 'aos';
import {Outlet, useParams} from "react-router";
import {useTranslation} from "react-i18next";

const MainLayout = ({ children }) => {
    const isScrolled = useScroll(100);
    const { lang } = useParams();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (lang && ['es', 'en'].includes(lang)) {
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);


    useEffect(() => {
        // Inicializar AOS
        AOS.init({
            duration: 650,
            once: true,
            easing: 'ease-out-cubic',
            offset: 50
        });
    }, []);

    return (
        <>

        <header
                id="header"
                className={`navbar navbar-expand-lg navbar-end navbar-absolute-top${
                    isScrolled ? '' : ''
                }`}
            >
                <TopBar />
                <Header />
            </header>

            <main id="content" role="main">
                <Outlet/>
            </main>


            <Footer />


            {isScrolled && (
                <button
                    className="btn btn-primary btn-icon goto-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    style={{
                        position: 'fixed',
                        right: '2rem',
                        bottom: '2rem',
                        zIndex: 100
                    }}
                >
                    <i className="bi-chevron-up"></i>
                </button>
            )}
        </>
    );
};

export default MainLayout;