import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShoppingBasket, Truck, RotateCcw } from 'lucide-react';

const Purchases = () => {
    const { t } = useTranslation();
    const [selectedPeriod, setSelectedPeriod] = useState('past 6 months');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Array más grande de órdenes para probar la paginación
    const allOrders = [
        {
            discount: 0,
            total: 999.00,
            subtotal: 999.00,
            shipTo: 'Natalie Curtis',
            orderNo: '456853648',
            shippedDate: '30 April, 2020',
            image: '/assets/projects/web/inventory.jpg',
            productName: 'Premium Wireless Headphones'
        },
        {
            total: 125.00,
            subtotal: 999.00,

            shipTo: 'Natalie Curtis',
            orderNo: '428766351',
            shippedDate: '08 February, 2020',
            image: '/assets/img/380x360/img1.jpg',
            productName: 'Smart Watch Series 5'
        },
        {
            total: 57.00,
            subtotal: 999.00,

            shipTo: 'Natalie Curtis',
            orderNo: '415338178',
            shippedDate: '14 May, 2020',
            image: '/assets/img/380x360/img2.jpg',
            productName: 'Wireless Charger Pad'
        },
        {
            total: 199.00,
            subtotal: 999.00,

            discount: 0,
            shipTo: 'Natalie Curtis',
            orderNo: '415338179',
            shippedDate: '15 May, 2020',
            image: '/assets/img/380x360/img3.jpg',
            productName: 'Bluetooth Speaker'
        },
        {
            total: 299.00,
            subtotal: 999.00,

            discount: 0,
            shipTo: 'Natalie Curtis',
            orderNo: '415338180',
            shippedDate: '16 May, 2020',
            image: '/assets/img/380x360/img4.jpg',
            productName: 'Noise Cancelling Headphones'
        },
        {
            total: 89.00,
            subtotal: 999.00,

            discount: 0,
            shipTo: 'Natalie Curtis',
            orderNo: '415338181',
            shippedDate: '17 May, 2020',
            image: '/assets/img/380x360/img5.jpg',
            productName: 'Wireless Mouse'
        },
        {
            total: 159.00,
            subtotal: 999.00,

            discount: 0,
            shipTo: 'Natalie Curtis',
            orderNo: '415338182',
            shippedDate: '18 May, 2020',
            image: '/assets/img/380x360/img6.jpg',
            productName: 'Mechanical Keyboard'
        },
    ];

    // Cálculos para la paginación
    const totalPages = Math.ceil(allOrders.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOrders = allOrders.slice(indexOfFirstItem, indexOfLastItem);

    const OrderCard = ({ order }) => (
        <li className="card card-bordered shadow-none mb-3">
            <div className="card-header pb-0">
                <a href="#"> <h4 className="mb-0  text-primary">{order.productName}</h4></a>

            </div>
            <div className="card-body  pt-3">
                <div className="row">
                    <div className="col-6 col-md m mb-md-0">
                        <small className="card-subtitle mb-0">{t('pages.panel.purchases.subtotal')}</small>
                        <small className="text-dark fw-semibold">${order.subtotal}</small>
                    </div>

                    <div className="col-6 col-md mb-1 mb-md-0">
                        <small className="card-subtitle mb-0">{t('pages.panel.purchases.discount')}</small>
                        <small className="text-dark fw-semibold">${order.discount}</small>
                    </div>

                    <div className="col-6 col-md mb-1 mb-md-0">
                        <small className="card-subtitle mb-0">{t('pages.panel.purchases.total')}</small>
                        <small className="text-dark fw-semibold">${order.total}</small>
                    </div>

                    <div className="col-6 col-md mb-1 mb-md-0">
                        <small className="card-subtitle mb-0">{t('pages.panel.purchases.status')}</small>
                        <span className="badge bg-success">{t('pages.panel.purchases.buy')}</span>

                    </div>

                    <div className="col-6 col-md">
                        <small className="card-subtitle mb-0">{t('pages.panel.purchases.shippedDate')}</small>
                        <small className="text-dark fw-semibold">{order.shippedDate}</small>
                    </div>
                </div>

                <hr className="my-4" />

                <div className="row">
                    <div className="col-md-8">
                        <div className="d-flex align-items-center mb-1">
                         </div>
                        <div className="mb-1">
                            <img
                                className="img-fluid rounded img-thumbnail"
                                src={order.image}
                                alt={order.productName}
                                style={{ maxHeight: '170px', objectFit: 'contain' }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="d-grid ">
                            <button className="btn btn-white btn-sm d-flex  justify-content-center">
                                <i className="bi-eye me-2"></i> {t('pages.panel.purchases.view')}
                            </button>
                            <button className="btn btn-white btn-sm d-flex align-items-center justify-content-center">
                                <i className="bi-unlock me-2"></i> {t('pages.panel.purchases.getPassword')}
                            </button>

                            <button className="btn btn-primary btn-sm">
                                <i className="bi-download me-2"></i> {t('pages.panel.purchases.download')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );

    return (
        <div className="card">
            <div className="card-header border-bottom">
                <h5 className="card-header-title">  {t('pages.panel.purchases.ordersPlaced')}  <span className="bg badge-primary"> ({allOrders.length})</span></h5>
            </div>


            <div className="card-body">


                <ul className="list-unstyled mb-5">
                    {currentOrders.map((order, index) => (
                        <OrderCard key={order.orderNo} order={order} />
                    ))}
                </ul>

                {totalPages > 1 && (
                    <nav aria-label="Page navigation" className="d-flex justify-content-center">
                        <ul className="pagination">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button
                                    className="page-link"
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    {t('pages.panel.purchases.pagination.prev')}
                                </button>
                            </li>
                            {[...Array(totalPages)].map((_, index) => (
                                <li
                                    key={index + 1}
                                    className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                >
                                    <button
                                        className="page-link"
                                        onClick={() => setCurrentPage(index + 1)}
                                    >
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button
                                    className="page-link"
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    {t('pages.panel.purchases.pagination.next')}
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </div>
        </div>
    );
};

export default Purchases;