import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewsletterSubscribe from "../../components/widgets/NewsletterSubscribe";
import Head from "../../components/layout/Head";

const All = () => {
    const { t } = useTranslation();

    return (
        <>
            {/* Head Section */}
            <Head pagePath="services.all" />


            {/* Hero Section */}
            <div className="gradient-y-sm-primary position-relative overflow-hidden">
                {/* Contenedor principal con z-index más alto */}
                <div className="container position-relative content-space-t-4 content-space-t-lg-4 content-space-b-1" >
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <h3 className="display-6 mb-3">{t('pages.services.all.hero.title', 'Soluciones Digitales a Medida')}</h3>
                        <p className="lead text-muted">{t('pages.services.all.hero.description', 'Transformamos ideas en soluciones tecnológicas innovadoras para impulsar el crecimiento de tu negocio')}</p>
                    </div>
                </div>


            </div>

            {/* Main Services Grid */}
            <section className="main-container py-5">
                <div className="container">
                    <div className="row g-4">
                        {/* Software Development Card */}
                        <div className="col-lg-4 col-md-6">
                            <div className="card h-100 border-0 shadow-sm hover-translate-y text-center p-4">
                                <div className="text-primary mb-3">
                                    <i className="bi bi-pc-display-horizontal icon-large"></i>
                                </div>
                                <h3 className="h4 mb-3">{t('pages.services.all.cards.software.title', 'Desarrollo de Software')}</h3>
                                <div className="separator-center my-3"></div>
                                <p className="text-muted">{t('pages.services.all.cards.software.description', 'Creamos soluciones de software empresarial robustas y escalables, diseñadas específicamente para optimizar tus procesos de negocio. Nuestras aplicaciones destacan por su alta performance, seguridad y facilidad de uso.')}</p>
                            </div>
                        </div>

                        {/* Web Development Card */}
                        <div className="col-lg-4 col-md-6">
                            <div className="card h-100 border-0 shadow-sm hover-translate-y text-center p-4">
                                <div className="text-primary mb-3">
                                    <i className="bi bi-globe2 icon-large"></i>
                                </div>
                                <h3 className="h4 mb-3">{t('pages.services.all.cards.web.title', 'Desarrollo Web')}</h3>
                                <div className="separator-center my-3"></div>
                                <p className="text-muted">{t('pages.services.all.cards.web.description', 'Desarrollamos sitios web modernos y responsivos que cautivan a tu audiencia. Combinamos diseño atractivo con funcionalidad avanzada para crear experiencias web excepcionales que impulsan resultados.')}</p>
                            </div>
                        </div>

                        {/* Mobile Development Card */}
                        <div className="col-lg-4 col-md-6">
                            <div className="card h-100 border-0 shadow-sm hover-translate-y text-center p-4">
                                <div className="text-primary mb-3">
                                    <i className="bi bi-phone icon-large"></i>
                                </div>
                                <h3 className="h4 mb-3">{t('pages.services.all.cards.mobile.title', 'Aplicaciones Móviles')}</h3>
                                <div className="separator-center my-3"></div>
                                <p className="text-muted">{t('pages.services.all.cards.mobile.description', 'Creamos apps multiplataforma innovadoras que conectan con tus usuarios. Integramos perfectamente tus sistemas existentes para ofrecer una experiencia móvil fluida y potente.')}</p>
                            </div>
                        </div>

                        {/* Maintenance Card */}
                        <div className="col-lg-4 col-md-6">
                            <div className="card h-100 border-0 shadow-sm hover-translate-y text-center p-4">
                                <div className="text-primary mb-3">
                                    <i className="bi bi-gear-wide-connected icon-large"></i>
                                </div>
                                <h3 className="h4 mb-3">{t('pages.services.all.cards.maintenance.title', 'Mantenimiento y Soporte')}</h3>
                                <div className="separator-center my-3"></div>
                                <p className="text-muted">{t('pages.services.all.cards.maintenance.description', 'Brindamos soporte técnico continuo y mantenimiento preventivo para garantizar el óptimo funcionamiento de tus sistemas. Monitoreamos, actualizamos y optimizamos tu infraestructura digital.')}</p>
                            </div>
                        </div>

                        {/* Design Card */}
                        <div className="col-lg-4 col-md-6">
                            <div className="card h-100 border-0 shadow-sm hover-translate-y text-center p-4">
                                <div className="text-primary mb-3">
                                    <i className="bi bi-brush icon-large"></i>
                                </div>
                                <h3 className="h4 mb-3">{t('pages.services.all.cards.design.title', 'Diseño y Creatividad')}</h3>
                                <div className="separator-center my-3"></div>
                                <p className="text-muted">{t('pages.services.all.cards.design.description', 'Potenciamos tu marca con diseños únicos y creativos que destacan en el mercado digital. Nuestro equipo de diseñadores crea experiencias visuales memorables que conectan con tu audiencia.')}</p>
                            </div>
                        </div>

                        {/* Marketing Card */}
                        <div className="col-lg-4 col-md-6">
                            <div className="card h-100 border-0 shadow-sm hover-translate-y text-center p-4">
                                <div className="text-primary mb-3">
                                    <i className="bi bi-graph-up-arrow icon-large"></i>
                                </div>
                                <h3 className="h4 mb-3">{t('pages.services.all.cards.marketing.title', 'Marketing Digital')}</h3>
                                <div className="separator-center my-3"></div>
                                <p className="text-muted">{t('pages.services.all.cards.marketing.description', 'Desarrollamos estrategias digitales data-driven para maximizar tu ROI. Implementamos campañas personalizadas que generan leads cualificados y aumentan tus conversiones.')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="bg-light py-5">
                <div className="container">
                    <div className="row g-4">
                        {/* Technical Support */}
                        <div className="col-lg-6">
                            <div className="card border-0 shadow-sm p-4">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="text-primary me-3">
                                        <i className="bi bi-headset icon-medium"></i>
                                    </div>
                                    <h4 className="mb-0">{t('pages.services.all.features.support.title', 'Soporte Técnico Premium')}</h4>
                                </div>
                                <p className="text-muted">{t('pages.services.all.features.support.description', 'Nuestro equipo de expertos está disponible para resolver cualquier incidencia y garantizar el funcionamiento óptimo de tus sistemas.')}</p>
                                <div className="row mt-4">
                                    <div className="col-sm-6">
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                                {t('pages.services.all.features.support.benefits.priority', 'Soporte prioritario')}
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                                {t('pages.services.all.features.support.benefits.errors', 'Resolución de errores')}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                                {t('pages.services.all.features.support.benefits.updates', 'Actualizaciones')}
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                                {t('pages.services.all.features.support.benefits.monitoring', 'Monitoreo 24/7')}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Code Quality */}
                        <div className="col-lg-6">
                            <div className="card border-0 shadow-sm p-4">
                                <div className="d-flex align-items-center mb-4">
                                    <div className="text-primary me-3">
                                        <i className="bi bi-code-square icon-medium"></i>
                                    </div>
                                    <h4 className="mb-0">{t('pages.services.all.features.code.title', 'Código de Alta Calidad')}</h4>
                                </div>
                                <p className="text-muted">{t('pages.services.all.features.code.description', 'Desarrollamos código limpio, modular y bien documentado que facilita el mantenimiento y la escalabilidad de tus proyectos.')}</p>
                                <div className="row mt-4">
                                    <div className="col-sm-6">
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                                {t('pages.services.all.features.code.benefits.structured', 'Código estructurado')}
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                                {t('pages.services.all.features.code.benefits.documentation', 'Documentación completa')}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                                {t('pages.services.all.features.code.benefits.maintenance', 'Fácil mantenimiento')}
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                                {t('pages.services.all.features.code.benefits.testing', 'Pruebas automatizadas')}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <div className="container mt-5 content-space-t-2 content-space-lg-2">
                <div className="w-lg-75 mx-lg-auto">
                    <div className="card card-sm overflow-hidden">
                        <div className="card-body">
                            <div className="row justify-content-md-start align-items-md-center text-center text-md-start">
                                <div className="col-md offset-md-3 mb-3 mb-md-0">
                                    <h4 className="card-title">{t('pages.services.all.cta.question', '¿Tienes una idea o algo en mente?')}</h4>
                                </div>
                                <div className="col-md-auto">
                                    <Link className="btn btn-primary btn-transition" to="/contact">
                                        {t('pages.services.all.cta.button', '¡Contáctanos ahora!')}
                                    </Link>
                                </div>
                            </div>

                            <figure className="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
                                <img
                                    className="card-img"
                                    src="/assets/img/icons/idea.png"
                                    style={{width:'70px', textAlign: 'center'}}
                                    alt="Idea Icon"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>



            <NewsletterSubscribe />
        </>
    );
};

export default All;