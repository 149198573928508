import React from 'react';
import { useTranslation } from 'react-i18next';
import Head from "../components/layout/Head";

const TermsConditions = () => {
    const { t } = useTranslation();

    return (
        <>
            <Head pagePath="terms" />

            {/* Hero Section */}
            <div className="bg-img-start" style={{backgroundImage: "url(/assets/svg/components/card-11.svg)"}}>
                <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto">
                        <h1>{t('pages.terms.hero.title')}</h1>
                        <p>{t('pages.terms.hero.date')}</p>
                    </div>
                </div>
            </div>

            {/* Content */}
            <div className="container content-space-1 content-space-md-3">
                <div className="row">
                    {/* Sidebar Navigation */}
                    <div className="col-md-4 col-lg-3 mb-3 mb-md-0">
                        <div className="navbar-expand-md">
                            {/* Menu Toggle para Mobile */}
                            <div className="d-grid">
                                <button className="navbar-toggler btn btn-white mb-3"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarVerticalNavMenu">
                                    <span className="d-flex justify-content-between align-items-center">
                                        <span className="text-dark">{t('pages.terms.menu.title')}</span>
                                        <span className="navbar-toggler-default">
                                            <i className="bi-list"></i>
                                        </span>
                                        <span className="navbar-toggler-toggled">
                                            <i className="bi-x"></i>
                                        </span>
                                    </span>
                                </button>
                            </div>

                            {/* Menu Items */}
                            <div id="navbarVerticalNavMenu" className="collapse navbar-collapse">
                                <ul className="nav nav-tabs nav-link-gray nav-vertical">
                                    {Object.entries(t('pages.terms.sections', { returnObjects: true })).map(([key, section], index) => (
                                        <li key={key} className="nav-item">
                                            <a className={`nav-link ${index === 0 ? 'active' : ''}`}
                                               href={`#${key}`}>
                                                {section.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="col-md-8 col-lg-9">
                        <div className="mb-7">
                            {t('pages.terms.introduction', { returnObjects: true }).map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                        </div>

                        {/* Terms Sections */}
                        {Object.entries(t('pages.terms.sections', { returnObjects: true })).map(([key, section]) => (
                            <div key={key} id={key} className="mb-7">
                                <h4>{section.title}</h4>
                                {section.content.map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))}
                            </div>
                        ))}

                        {/* Copyright Notice */}
                        <div className="mt-7">
                            <p className="small text-muted">© {new Date().getFullYear()} Wecodex Solutions. Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermsConditions;