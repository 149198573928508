import { createSlice } from '@reduxjs/toolkit';

const permissionSlice = createSlice({
    name: 'permissions',
    initialState: {
        permissions: JSON.parse(localStorage.getItem('permissions')) || []
    },
    reducers: {
        setPermissions: (state, action) => {
            state.permissions = action.payload;
            localStorage.setItem('permissions', JSON.stringify(action.payload));
        },
        clearPermissions: (state) => {
            state.permissions = [];
            localStorage.removeItem('permissions');
        }
    }
});

export const { setPermissions, clearPermissions } = permissionSlice.actions;
export const selectPermissions = (state) => state.permissions.permissions;
export default permissionSlice.reducer;