import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from "@/services/api";
import { useAuth } from "@/context/AuthContext";
import {
    AccountStep,
    VerificationStep,
    SetPasswordStep,
    SuccessStep,
    ExpiredStep,
    VerifyStep,
    PasswordChangedStep
} from '@pages/auth/Register/Steps';

// Initial States
const initialRegisterState = {
    step: 'account',
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    code: '',
    token: '',
    type: ''
};

const initialPasswordValidationState = {
    contains_eight_characters: false,
    contains_number: false,
    contains_uppercase: false,
    contains_special_character: false,
    contains_equals_passwords: false,
    valid_password: false
};

const initialVerifyState = {
    response: '',
    status: ''
};

// Main Component
const RegisterConfirm = () => {
    // Hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setIsReadyForRedirect } = useAuth();

    // States
    const [register, setRegister] = useState({
        ...initialRegisterState,
        code: searchParams.get('code') || '',
        token: searchParams.get('token') || ''
    });
    const [passwordValidation, setPasswordValidation] = useState(initialPasswordValidationState);
    const [verify, setVerify] = useState({
        ...initialVerifyState,
        response: t('pages.auth.register_confirm.steps.verify.description')
    });
    const [countDown, setCountDown] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    // Effects
    useEffect(() => {
        if (register.token && register.code) {
            verifyToken();
        }
    }, [register.token, register.code]);

    useEffect(() => {
        let timer;
        if (countDown > 0) {
            timer = setTimeout(() => setCountDown(prev => prev - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [countDown]);

    useEffect(() => {
        validatePasswordStrong();
    }, [register.password, register.password_confirmation]);

    // Validation Functions
    const validatePasswordStrong = () => {
        const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/;
        const passwordLength = register.password.length;

        setPasswordValidation({
            contains_eight_characters: passwordLength > 8,
            contains_number: /\d/.test(register.password),
            contains_uppercase: /[A-Z]/.test(register.password),
            contains_special_character: format.test(register.password),
            contains_equals_passwords: register.password === register.password_confirmation,
            valid_password: passwordLength > 8 &&
                /\d/.test(register.password) &&
                /[A-Z]/.test(register.password) &&
                format.test(register.password) &&
                register.password === register.password_confirmation
        });
    };

    // API Functions
    const registerAccount = async () => {
        setLoading(true);
        try {
            const response = await api.postAwaiting('auth/register', register);
            toast.success(response.data.message);
            if (response.data.user) {
                setRegister(prev => ({ ...prev, step: 'verification' }));
                startVerificationCheck();
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const confirmPassword = async () => {
        if (!passwordValidation.valid_password) {
            toast.error(t('pages.auth.register_confirm.messages.validations.password_weak'));
            return;
        }

        setLoading(true);
        try {
            const response = await api.postAwaiting('auth/confirmation', register);
            toast.success(response.data.message);
            if (response.data) {
                setRegister(prev => ({ ...prev, step: 'password_changed' }));
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const resendConfirmation = async () => {
        if (countDown > 0) return;

        setLoading(true);
        try {
            const response = await api.postAwaiting('auth/register', register);
            toast.success(response.data.message);
            setCountDown(30);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const verifyToken = async () => {
        const step = searchParams.get('step');
        setRegister(prev => ({ ...prev, step: 'verify' }));

        try {
            const response = await api.postAwaiting('auth/check-token', {
                code: register.code,
                token: register.token
            });

            if (!response.data.expired) {
                if (['confirm', 'confirmation', 'reset'].includes(step)) {
                    setRegister(prev => ({
                        ...prev,
                        type: 'set_password',
                        step: 'set_password'
                    }));
                    return;
                }

                if (step === 'verification') {
                    try {
                        const verifyResponse = await api.postAwaiting('auth/verification', {
                            code: register.code,
                            token: register.token
                        });

                        if (verifyResponse.data.user) {
                            setRegister(prev => ({ ...prev, step: 'success' }));
                            setVerify({
                                response: verifyResponse.data.message,
                                status: true
                            });
                        }
                    } catch (error) {
                    }
                }
            } else {
                handleExpiredStep('error');
            }
        } catch (error) {
        }
    };

    // Helper Functions
    const handleExpiredStep = (message) => {
        setRegister(prev => ({
            ...prev,
            step: 'expired',
            type: 'expired'
        }));
        setVerify({
            response: message,
            status: false
        });
    };

    const startVerificationCheck = () => {
        const interval = setInterval(async () => {
            try {
                const response = await api.postAwaiting('auth/check-verification', {
                    email: register.email
                });
                if (response.data) {
                    clearInterval(interval);
                    setRegister(prev => ({ ...prev, step: 'success' }));
                }
            } catch (error) {
                console.error('Verification check error:', error);
            }
        }, 10000);

        return () => clearInterval(interval);
    };

    // Component Constants
    const passwordRequirements = [
        { key: 'length', condition: 'contains_eight_characters' },
        { key: 'numbers', condition: 'contains_number' },
        { key: 'uppercase', condition: 'contains_uppercase' },
        { key: 'special', condition: 'contains_special_character' },
        { key: 'match', condition: 'contains_equals_passwords' }
    ];

    // Render Functions
    const renderPasswordInput = (fieldName, placeholder) => (
        <div className="input-group input-group-merge">
            <input
                type={showPassword ? "text" : "password"}
                className="form-control form-control-lg"
                value={register[fieldName]}
                placeholder={placeholder}
                onChange={(e) => setRegister(prev => ({ ...prev, [fieldName]: e.target.value }))}
            />
            <button
                className="input-group-append input-group-text"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
            >
                <i className={`bi-${showPassword ? 'eye-slash' : 'eye'}`}></i>
            </button>
        </div>
    );

    const renderCurrentStep = () => {
        const commonProps = {
            t,
            register,
            loading,
            showPassword,
            setShowPassword,
            setRegister,
            renderPasswordInput,
            navigate
        };

        switch (register.step) {
            case 'account':
                return (
                    <AccountStep
                        {...commonProps}
                        registerAccount={registerAccount}
                    />
                );
            case 'verification':
                return (
                    <VerificationStep
                        {...commonProps}
                        countDown={countDown}
                        resendConfirmation={resendConfirmation}
                    />
                );
            case 'set_password':
                return (
                    <SetPasswordStep
                        {...commonProps}
                        passwordValidation={passwordValidation}
                        passwordRequirements={passwordRequirements}
                        confirmPassword={confirmPassword}
                    />
                );
            case 'success':
                return (
                    <SuccessStep
                        {...commonProps}
                        verify={verify}
                    />
                );
            case 'expired':
                return <ExpiredStep {...commonProps} />;
            case 'verify':
                return <VerifyStep {...commonProps} verify={verify} />;
            case 'password_changed':
                return <PasswordChangedStep {...commonProps} />;
            default:
                return null;
        }
    };

    // JSX
    return (
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-md-12 ">
                    <div className="card overflow-hidden">
                        <div className="card-header bg-soft p-2">
                            {register.step === 'account' && (
                                <div className="text-primary text-center">
                                    <h5 className="mb-2">{t('pages.auth.register_confirm.title')}</h5>
                                    <p className="text-secondary">{t('pages.auth.register_confirm.description')}</p>
                                </div>
                            )}
                        </div>
                        <div className="card-body p-4">
                            {renderCurrentStep()}
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-center mt-5">
                <p className="text-primary small">
                    &copy; Wecodex Solutions | <Link to='/terms-and-conditions'>{t('layout.footer.copyright')}</Link>
                </p>
            </div>
        </div>
    );
};

export default RegisterConfirm;