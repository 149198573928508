import React from 'react';
import { useTranslation } from 'react-i18next';
import Head from "../components/layout/Head";

const About = () => {
    const { t } = useTranslation();

    return (
        <>


            <Head pagePath="about" />


            {/* Hero Section */}
            <div className="gradient-y-sm-primary position-relative zi-2 overflow-hidden space-control-hero">
                <div className="container position-relative content-space-t-1 content-space-t-lg-4 content-space-b-2">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-1 mb-md-1">
                        <h3 className="display-6 mb-3">{t('pages.about.hero.title', 'Acerca de nosotros')}</h3>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container content-space-2 content-space-lg-3">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <h3 className="text-cap">{t('pages.about.intro.question', '¿Quiénes Somos?')}</h3>
                    <p>{t('pages.about.intro.description', 'Con más de 10 años de experiencia, transformamos ideas en soluciones digitales que impulsan el crecimiento empresarial')}</p>
                </div>

                <div className="row justify-content-lg-center">
                    {/* Technology Excellence */}
                    <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
                        <div className="d-flex pe-md-5">
                            <div className="flex-shrink-0">
                                <div className="svg-icon text-primary">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M20.335 15.537C21.725 14.425 21.57 12.812 21.553 11.224C21.4407 9.50899 20.742 7.88483 19.574 6.624C18.5503 5.40102 17.2668 4.4216 15.817 3.757C14.4297 3.26981 12.9703 3.01966 11.5 3.01701C8.79576 2.83108 6.11997 3.66483 4 5.35398C2.289 6.72498 1.23101 9.12497 2.68601 11.089C3.22897 11.6881 3.93029 12.1214 4.709 12.339C5.44803 12.6142 6.24681 12.6888 7.024 12.555C6.88513 12.9965 6.85078 13.4644 6.92367 13.9215C6.99656 14.3786 7.17469 14.8125 7.444 15.189C7.73891 15.5299 8.10631 15.8006 8.51931 15.9812C8.93232 16.1619 9.38047 16.2478 9.831 16.233C10.0739 16.2296 10.3141 16.1807 10.539 16.089C10.7371 15.9871 10.9288 15.8732 11.113 15.748C12.1594 15.2831 13.3275 15.1668 14.445 15.416C15.7795 15.7213 17.1299 15.952 18.49 16.107C18.7927 16.1438 19.0993 16.1313 19.398 16.07C19.7445 15.9606 20.0639 15.7789 20.335 15.537V15.537Z" fill="#035A4B"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h4>{t('pages.about.features.technology.title', 'Excelencia Tecnológica')}</h4>
                                <p>{t('pages.about.features.technology.description', 'Nuestro equipo de expertos combina las últimas tecnologías con las mejores prácticas de desarrollo para crear soluciones robustas y escalables.')}</p>
                            </div>
                        </div>
                    </div>

                    {/* Continuous Innovation */}
                    <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
                        <div className="d-flex ps-md-5">
                            <div className="flex-shrink-0">
                                <div className="svg-icon text-primary">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 18V16H10V18L9 20H15L14 18Z" fill="#035A4B"/>
                                        <path opacity="0.3" d="M20 4H17V3C17 2.4 16.6 2 16 2H8C7.4 2 7 2.4 7 3V4H4C3.4 4 3 4.4 3 5V9C3 11.2 4.8 13 7 13C8.2 14.2 8.8 14.8 10 16H14C15.2 14.8 15.8 14.2 17 13C19.2 13 21 11.2 21 9V5C21 4.4 20.6 4 20 4Z" fill="#035A4B"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h4>{t('pages.about.features.innovation.title', 'Innovación Constante')}</h4>
                                <p>{t('pages.about.features.innovation.description', 'Nos mantenemos a la vanguardia de las tendencias tecnológicas para ofrecer soluciones innovadoras que marcan la diferencia en el mercado.')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="w-100"></div>

                    {/* Results Commitment */}
                    <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
                        <div className="d-flex pe-md-5">
                            <div className="flex-shrink-0">
                                <div className="svg-icon text-primary">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="#035A4B"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h4>{t('pages.about.features.commitment.title', 'Compromiso con Resultados')}</h4>
                                <p>{t('pages.about.features.commitment.description', 'Nos enfocamos en generar valor real para nuestros clientes, con soluciones que impulsan su crecimiento y eficiencia operativa.')}</p>
                            </div>
                        </div>
                    </div>

                    {/* Specialized Team */}
                    <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
                        <div className="d-flex ps-md-5">
                            <div className="flex-shrink-0">
                                <div className="svg-icon text-primary">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z" fill="#035A4B"/>
                                        <path d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65Z" fill="#035A4B"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h4>{t('pages.about.features.team.title', 'Equipo Especializado')}</h4>
                                <p>{t('pages.about.features.team.description', 'Contamos con profesionales altamente capacitados y apasionados por crear soluciones tecnológicas que superan expectativas.')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;