import { useState } from 'react';
import { formatDateForServer } from '../utils/formatters';

export const useFormData = (initialData = {}) => {
    const [formData, setFormData] = useState(initialData);
    const [filePreview, setFilePreview] = useState({});

    const handleChange = (name, value, isFile = false) => {
        if (isFile) {
            handleFileChange(name, value);
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleFileChange = (name, files) => {
        if (!files || files.length === 0) return;

        const fileList = Array.from(files);
        setFormData(prev => ({
            ...prev,
            [name]: prev.multiple ? fileList : fileList[0],
        }));

        Promise.all(
            fileList.map(file => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = (e) => resolve(e.target.result);
                    reader.readAsDataURL(file);
                });
            })
        ).then(previews => {
            setFilePreview(prev => ({
                ...prev,
                [name]: prev.multiple ? previews : previews[0],
            }));
        });
    };

    const removeFile = (fieldName, index = null) => {
        if (index !== null) {
            setFormData(prev => {
                const newFiles = Array.from(prev[fieldName] || []);
                newFiles.splice(index, 1);
                return {
                    ...prev,
                    [fieldName]: newFiles,
                };
            });

            setFilePreview(prev => {
                const newPreviews = [...(prev[fieldName] || [])];
                newPreviews.splice(index, 1);
                return {
                    ...prev,
                    [fieldName]: newPreviews,
                };
            });
        } else {
            setFormData(prev => ({
                ...prev,
                [fieldName]: null,
            }));

            setFilePreview(prev => ({
                ...prev,
                [fieldName]: null,
            }));
        }
    };

    const formatDataForEdit = (record, fields) => {
        const formattedData = {};

        Object.keys(record).forEach(key => {
            if (key !== 'translations') {
                // Para campos que son relaciones pivot (technologies, categories)
                if (Array.isArray(record[key])) {
                    formattedData[key] = record[key].map(item => typeof item === 'object' ? item.id : item);
                } else {
                    formattedData[key] = record[key];
                }
            }
        });

        fields.forEach(field => {
            if (field.separator) return;

            // Manejo de campos multilocale
            if (field.multilocale) {
                const defaultLocale = field.defaultLocale || 'es';
                const translation = record.translations?.find(t => t.locale === defaultLocale)
                    || record.translations?.[0];

                if (translation) {
                    formattedData[field.name] = translation[field.name];
                }
            }

            // Manejo de fechas
            if ((field.type === 'date' || field.type === 'datetime-local') && record[field.name]) {
                formattedData[field.name] = formatDateForServer(record[field.name]);
            }

            // Manejo de archivos con preview
            if (field.type === 'file' && field.preview && record[field.name]) {
                if (field.multiple && Array.isArray(record[field.name])) {
                    setFilePreview(prev => ({
                        ...prev,
                        [field.name]: record[field.name]
                    }));
                } else {
                    setFilePreview(prev => ({
                        ...prev,
                        [field.name]: record[field.name]
                    }));
                }
                formattedData[field.name] = record[field.name];
            }
        });

        return formattedData;
    };
    const resetForm = (newData = {}) => {
        setFormData(newData);
        setFilePreview({});
    };

    return {
        formData,
        filePreview,
        handleChange,
        removeFile,
        formatDataForEdit,
        resetForm
    };
};
