import React from 'react';
import PropTypes from 'prop-types';

const FormActions = ({
    isWizard,
    isLoading,
    onClose,
    hasPrevStep,
    hasNextStep,
    onPrevStep,
    currentStep,
    stepsLength,
    translations,
    wizardValidation = false,
    isStepValid = true
}) => {
    const submitButtonContent = (
        <>
            {isLoading && (
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"/>
            )}
            {isLoading ? translations.saving : translations.save}
        </>
    );

    if (!isWizard) {
        return (
            <div className="d-flex justify-content-end gap-3">
                <button
                    type="button"
                    className="btn btn-white"
                    onClick={onClose}
                    disabled={isLoading}
                >
                    {translations.cancel}
                </button>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                >
                    {submitButtonContent}
                </button>
            </div>
        );
    }

    return (
        <div className="wizard-actions">
            <div className="d-flex justify-content-between align-items-center">
                <button
                    type="button"
                    className="btn btn-white"
                    onClick={onPrevStep}
                    disabled={!hasPrevStep || isLoading}
                >
                    <i className="bi-chevron-left me-2"/>
                    {translations.previous}
                </button>

                <div className="d-flex gap-2">
                    <button
                        type="button"
                        className="btn btn-white"
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        {translations.cancel}
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isLoading || (wizardValidation && !isStepValid)}
                    >
                        {hasNextStep ? (
                            <>
                                {isLoading && (
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"/>
                                )}
                                {isLoading ? translations.processing : translations.next}
                                <i className="bi-chevron-right ms-2"/>
                            </>
                        ) : (
                            submitButtonContent
                        )}
                    </button>
                </div>
            </div>
            <div className="text-center mt-3">
                <small className="text-muted">
                    Paso {currentStep + 1} de {stepsLength}
                </small>
            </div>
        </div>
    );
};

FormActions.propTypes = {
    isWizard: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    hasPrevStep: PropTypes.bool,
    hasNextStep: PropTypes.bool,
    onPrevStep: PropTypes.func,
    currentStep: PropTypes.number,
    stepsLength: PropTypes.number,
    translations: PropTypes.shape({
        save: PropTypes.string.isRequired,
        saving: PropTypes.string.isRequired,
        cancel: PropTypes.string.isRequired,
        next: PropTypes.string.isRequired,
        previous: PropTypes.string.isRequired,
        processing: PropTypes.string.isRequired
    }).isRequired,
    wizardValidation: PropTypes.bool,
    isStepValid: PropTypes.bool
};

export default FormActions;