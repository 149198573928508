// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Importar traducciones directamente
import translationES from './locales/es/translation.json';
import translationEN from './locales/en/translation.json';

const resources = {
    es: {
        translation: translationES
    },
    en: {
        translation: translationEN
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'es',
        supportedLngs: ['es', 'en'],

        detection: {
            order: ['path', 'localStorage', 'navigator'], // Orden de prioridad
            lookupFromPathIndex: 0,
            caches: ['localStorage'], // Guardar en localStorage
            checkWhitelist: true,
        },

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;