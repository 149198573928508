import React, { useState } from 'react';
import PropTypes from 'prop-types';

const VideoPlayer = ({ previewImage, videoUrl }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasError, setHasError] = useState(false);

    const handleVideoError = () => {
        setHasError(true);
        setIsPlaying(false);
    };

    if (hasError) {
        return (
            <div className="card card-bordered text-center p-5">
                <div className="mb-3">
                    <i className="bi-exclamation-circle text-danger" style={{ fontSize: '2rem' }}></i>
                </div>
                <h5>Error al reproducir video</h5>
                <p className="text-muted small">
                    Lo sentimos, no se pudo cargar el video. Por favor, intenta más tarde.
                </p>
            </div>
        );
    }

    return (
        <div id="html5VideoPlayer" className="video-player video-player-inline-btn">
            {/* Preview Image */}
            <img
                className="img-fluid video-player-preview"
                src={previewImage}
                alt="Video preview"
            />

            {/* Play Button */}
            {!isPlaying && (
                <a
                    className="js-inline-video-player video-player-btn video-player-centered"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsPlaying(true);
                    }}
                >
                    <span className="video-player-icon shadow-sm">
                        <i className="bi-play-fill"></i>
                    </span>
                </a>
            )}

            {/* Video Container */}
            <div className="ratio ratio-16x9">
                {isPlaying && (
                    <video
                        autoPlay
                        controls
                        className="w-100"
                        poster={previewImage}
                        onError={handleVideoError}
                    >
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                )}
            </div>
        </div>
    );
};

VideoPlayer.propTypes = {
    previewImage: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired
};

export default VideoPlayer;