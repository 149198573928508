import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from "../../services/api";

const SecuritySection = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordForm, setPasswordForm] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });
    const [connectedAccounts, setConnectedAccounts] = useState({
        google: false,
        dropbox: false,
        slack: true,
        mailchimp: true,
        jira: false
    });

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        if (passwordForm.newPassword !== passwordForm.confirmNewPassword) {
            // Mostrar error
            return;
        }

        try {
            setIsLoading(true);
            const response = await api.postAwaiting('user/password', {
                currentPassword: passwordForm.currentPassword,
                newPassword: passwordForm.newPassword
            }, true);

            if (response.success) {
                // Mostrar mensaje de éxito
                setPasswordForm({
                    currentPassword: '',
                    newPassword: '',
                    confirmNewPassword: ''
                });
            }
        } catch (error) {
            // Manejar error
        } finally {
            setIsLoading(false);
        }
    };

    const handleAccountToggle = (account) => {
        setConnectedAccounts(prev => ({
            ...prev,
            [account]: !prev[account]
        }));
    };

    return (
        <>
            <div className="card">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{t('pages.panel.profile.security.password.title')}</h5>
                </div>

                <div className="card-body">
                    <form onSubmit={handlePasswordChange}>
                        <div className="row mb-4">
                            <label htmlFor="currentPasswordLabel" className="col-sm-3 col-form-label form-label">
                                {t('pages.panel.profile.security.password.current')}
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="currentPasswordLabel"
                                    value={passwordForm.currentPassword}
                                    onChange={(e) => setPasswordForm({...passwordForm, currentPassword: e.target.value})}
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label htmlFor="newPassword" className="col-sm-3 col-form-label form-label">
                                {t('pages.panel.profile.security.password.new')}
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="newPassword"
                                    value={passwordForm.newPassword}
                                    onChange={(e) => setPasswordForm({...passwordForm, newPassword: e.target.value})}
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <label htmlFor="confirmNewPasswordLabel" className="col-sm-3 col-form-label form-label">
                                {t('pages.panel.profile.security.password.confirm')}
                            </label>
                            <div className="col-sm-9">
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmNewPasswordLabel"
                                        value={passwordForm.confirmNewPassword}
                                        onChange={(e) => setPasswordForm({...passwordForm, confirmNewPassword: e.target.value})}
                                    />
                                </div>

                                <h5>{t('pages.panel.profile.security.password.requirements.title')}</h5>
                                <p className="card-text small">{t('pages.panel.profile.security.password.requirements.subtitle')}</p>
                                <ul className="small">
                                    {t('pages.panel.profile.security.password.requirements.list', { returnObjects: true }).map((req, index) => (
                                        <li key={index}>{req}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end gap-3">
                            <button type="button" className="btn btn-white" disabled={isLoading}>
                                {t('pages.panel.profile.buttons.cancel')}
                            </button>
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? t('pages.panel.profile.buttons.updating') : t('pages.panel.profile.security.password.update')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card mt-4">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{t('pages.panel.profile.security.connected.title')}</h5>
                </div>

                <div className="card-body">
                    <p className="card-text">{t('pages.panel.profile.security.connected.description')}</p>

                    <div className="list-group list-group-flush list-group-no-gutters">
                        {[
                            { id: 'google', icon: 'google-icon.svg', title: 'Google', description: t('pages.panel.profile.security.connected.accounts.google') },
                         ].map((account) => (
                            <div key={account.id} className="list-group-item">
                                <div className="d-flex">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="avatar avatar-xs avatar-4x3"
                                            src={`/assets/svg/brands/${account.icon}`}
                                            alt={account.title}
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h6 className="mb-1">{account.title}</h6>
                                                <span className="d-block small text-body">{account.description}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`connected${account.id}`}
                                                        checked={connectedAccounts[account.id]}
                                                        onChange={() => handleAccountToggle(account.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SecuritySection;