import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Select from "@/components/elements/Select";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FilePreview from './FilePreview';
import { formatDateForServer } from '../utils/formatters';
import QuillEditor from "@components/constructors/ModuleConstructor/components/QuillEditor";
import BoxIcon from "@components/elements/BoxIcon";
const FormField = ({
                       field,
                       formData,
                       onChange,
                       isCreate,
                       filePreview,
                       onFileRemove,
                       t
                   }) => {
    const isMultilocale = field.multilocale;
    const currentLength = formData[field.name]?.length || 0;

    const inputWrapper = (children) => {
        const rightAddonsExist = isMultilocale || field.maxLength;

        return (
            <div className="input-group">
                {field.icon && (
                    <span className="input-group-text">
                        <i className={field.icon}/>
                    </span>
                )}
                <div className="position-relative flex-grow-1">
                    {React.cloneElement(children, {
                        className: `form-control ${rightAddonsExist ? 'pe-5' : ''}`
                    })}

                    {rightAddonsExist && (
                        <div className="position-absolute top-50 end-0 translate-middle-y d-flex align-items-center me-2 gap-2">
                            {field.maxLength && ['text', 'textarea'].includes(field.type) && (
                                <small className={`text-${
                                    currentLength >= field.maxLength ? 'danger' :
                                        currentLength >= field.maxLength * 0.8 ? 'warning' : 'success'
                                }`} style={{fontSize: '12px', fontWeight: '500'}}>
                                    {currentLength}/{field.maxLength}
                                </small>
                            )}
                            {isMultilocale && (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip>
                                            {t('common.form.multiLanguageField')}
                                        </Tooltip>
                                    }
                                >
                                    <i className="bi bi-translate text-primary"
                                       style={{fontSize: '14px', cursor: 'help'}}/>
                                </OverlayTrigger>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderFieldByType = () => {
        switch (field.type) {
            case 'date':
            case 'datetime-local':
                const isDatetime = field.type === 'datetime-local';
                return inputWrapper(
                    <DatePicker
                        selected={formData[field.name] ? new Date(formData[field.name]) : null}
                        onChange={(date) => {
                            const formattedDate = formatDateForServer(date);
                            onChange(field.name, formattedDate);
                        }}
                        showTimeSelect={isDatetime}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat={isDatetime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
                        placeholderText={field.placeholder}
                        isClearable
                        autoComplete="off"
                    />
                );

            case 'file':
                return (
                    <div>
                        <div className="dropzone-card">
                            <div className="dz-message">
                                <input
                                    type="file"
                                    name={field.name}
                                    id={field.name}
                                    onChange={e => onChange(field.name, e.target.files, true)}
                                    accept={field.accept}
                                    required={field.required && isCreate}
                                    disabled={field.disabled || (!isCreate && field.disabledOnEdit)}
                                    multiple={field.multiple}
                                    className="dropzone-input"
                                />
                                <div className="text-center">
                                    <i className={`${field.icon || 'bi-cloud-upload'} display-4 text-muted mb-2`}/>
                                    <h6>Arrastra y suelta archivos aquí</h6>
                                    <p className="mb-0 small text-muted">o haz clic para seleccionar archivos</p>
                                    {field.helper && <small className="text-muted">{field.helper}</small>}
                                </div>
                            </div>
                        </div>
                        {field.preview && (
                            <FilePreview
                                field={field}
                                filePreview={filePreview}
                                formData={formData}
                                onRemove={onFileRemove}
                            />
                        )}
                    </div>
                );


            case 'box_icon':
                return (
                    <BoxIcon
                        icon={field.icon}
                        label={field.label}
                        checked={formData[field.name] || false}
                        onChange={(checked) => onChange(field.name, checked)}
                        color={field.color || 'primary'}
                    />
                );



            case 'editor':
                return (
                    <div className={field.class || ''}>
                        <QuillEditor
                            value={formData[field.name] || ''}
                            onChange={(content) => onChange(field.name, content)}
                            placeholder={field.placeholder}
                            icon={field.icon}
                            maxLength={field.maxLength}
                            isMultilocale={field.multilocale}
                            t={t}
                        />
                    </div>
                );
            case 'textarea':
                return inputWrapper(
                    <textarea
                        name={field.name}
                        id={field.name}
                        value={formData[field.name] || ''}
                        onChange={e => onChange(field.name, e.target.value)}
                        placeholder={field.placeholder}
                        required={field.required}
                        disabled={field.disabled || (!isCreate && field.disabledOnEdit)}
                        rows={4}
                        maxLength={field.maxLength}
                    />
                );

            case 'select':
                if (field.mode === 'api') {
                    return inputWrapper(
                        <Select
                            reducer={field.reducer}
                            mode="api"
                            endpoint={field.endpoint}
                            filter={field.filter}
                            onChange={value => {
                                if (field.pivot) {
                                    onChange(field.name, {
                                        data: [],
                                        selected: value
                                    });
                                } else {
                                    onChange(field.name, value);
                                }
                            }}
                            disabled={field.disabled || (!isCreate && field.disabledOnEdit)}
                            multiple={field.multiple}
                            showPills={field.showPills}
                            maxPills={field.maxPills}
                            placeholder={field.placeholder}
                            selected={field.pivot ? formData[field.name]?.selected : formData[field.name]}
                        />
                    );
                }

                return inputWrapper(
                    <Select
                        reducer={field.reducer}
                        mode="standard"
                        options={field.options}
                        onChange={value => onChange(field.name, value)}
                        disabled={field.disabled || (!isCreate && field.disabledOnEdit)}
                        multiple={field.multiple}
                        showPills={field.showPills}
                        maxPills={field.maxPills}
                        placeholder={field.placeholder}
                        selected={formData[field.name]}
                    />
                );

            case 'checkbox':
            case 'switch':
                return (
                    <div className={`form-check ${field.type === 'switch' ? 'form-switch' : ''}`}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={field.name}
                            id={field.name}
                            checked={formData[field.name] || false}
                            onChange={e => onChange(field.name, e.target.checked)}
                            disabled={field.disabled || (!isCreate && field.disabledOnEdit)}
                        />
                    </div>
                );

            default:
                return inputWrapper(
                    <input
                        type={field.type}
                        name={field.name}
                        id={field.name}
                        value={formData[field.name] || ''}
                        onChange={e => onChange(field.name, e.target.value)}
                        placeholder={field.placeholder}
                        required={field.required}
                        disabled={field.disabled || (!isCreate && field.disabledOnEdit)}
                        min={field.min}
                        max={field.max}
                        step={field.step}
                        maxLength={field.maxLength}
                    />
                );
        }
    };

    return (
        <div className={field.class || "col-sm-6 mb-3"}>
            <label htmlFor={field.name} className="form-label">
                {field.label}
                {field.tooltip && (
                    <i className="bi-question-circle text-body ms-1"
                       data-bs-toggle="tooltip"
                       data-bs-placement="top"
                       title={field.tooltip}/>
                )}
                {field.required && <span className="text-danger">*</span>}
            </label>
            {renderFieldByType()}
        </div>
    );
};

FormField.propTypes = {
    field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        class: PropTypes.string,
        icon: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        disabledOnEdit: PropTypes.bool,
        multilocale: PropTypes.bool,
        maxLength: PropTypes.number,
        tooltip: PropTypes.string,
        helper: PropTypes.string,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        accept: PropTypes.string,
        multiple: PropTypes.bool,
        preview: PropTypes.bool,
        mode: PropTypes.oneOf(['api', 'standard']),
        reducer: PropTypes.string,
        endpoint: PropTypes.string,
        filter: PropTypes.object,
        options: PropTypes.array,
        showPills: PropTypes.bool,
        maxPills: PropTypes.number,
        pivot: PropTypes.shape({
            relation: PropTypes.string,
            field_value: PropTypes.string
        })
    }).isRequired,
    formData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isCreate: PropTypes.bool.isRequired,
    filePreview: PropTypes.object,
    onFileRemove: PropTypes.func,
    t: PropTypes.func.isRequired
};

export default FormField;