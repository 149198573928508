import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../widgets/LanguageSelector';

const Footer = () => {
    const {t} = useTranslation();

    const socialLinks = [
        { icon: 'bi-facebook', url: 'https://www.facebook.com/WecodexOficial', ariaLabel: 'Facebook' },
        { icon: 'bi-youtube', url: 'https://www.youtube.com/WecodexSolutions', ariaLabel: 'Youtube' },
        { icon: 'bi-twitter', url: 'https://x.com/Wecodex', ariaLabel: 'Twitter' },
        { icon: 'bi-github', url: 'https://github.com/wecodexoficial', ariaLabel: 'Github' }
    ];

    return (
        <footer className="bg-darks">
            <div className="container pb-1 pb-lg-5">
                <div className="row content-space-t-2">
                    {/* Logo y Contacto */}
                    <div className="col-lg-3 mb-7 mb-lg-0">
                        <div className="mb-5">
                            <Link className="navbar-brand" to="/">
                                <img className="navbar-brand-logo" src="/assets/logo/secundary_sm.png" alt="Logo" />
                            </Link>
                        </div>
                        <ul className="list-unstyled list-py-1">
                            <li>
                                <a className="link-sm link-light" href={`mailto:${t('layout.footer.contact.email')}`}>
                                    <i className="bi-geo-alt-fill me-1"></i> {t('layout.footer.contact.email')}
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Menú */}
                    <div className="col-sm mb-7 mb-sm-0">
                        <h5 className="text-white mb-3">        {t('layout.footer.menu.title')}</h5>
                        <ul className="list-unstyled list-py-1 mb-0">
                            <li>
                                <Link className="link-sm link-light" to="/">
                                    {t('layout.footer.menu.home')}
                                </Link>
                            </li>
                            <li>
                                <Link className="link-sm link-light" to="/services">
                                    {t('layout.footer.menu.services')} <span className="badge bg-warning text-dark rounded-pill ms-1">We're hiring</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="link-sm link-light" to="/about">
                                    {t('layout.footer.menu.about')}
                                </Link>
                            </li>
                            <li>
                                <Link className="link-sm link-light" to="/contact">
                                    {t('layout.footer.menu.contact')} <i className="bi-box-arrow-up-right small ms-1"></i>
                                </Link>
                            </li>
                            <li>
                                <Link className="link-sm link-light" to="/blog">
                                    {t('layout.footer.menu.blog')}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    {/* Plataformas */}
                    <div className="col-sm mb-7 mb-sm-0">
                        <h5 className="text-white mb-3">{t('layout.footer.services.title')}</h5>
                        <ul className="list-unstyled list-py-1 mb-0">
                            <li>
                                <a className="link-sm link-light" href="/service-develop-web" target="_blank" rel="noopener noreferrer">
                                    {t('layout.footer.services.development_web')}
                                </a>
                            </li>
                            <li>
                                <a className="link-sm link-light" href="/service-develop-app">
                                    {t('layout.footer.services.development_app')}
                                </a>
                            </li>

                            <li>
                                <a className="link-sm link-light" href="/service-develop-api">
                                    {t('layout.footer.services.development_api')}
                                </a>
                            </li>


                            <li>
                                <a className="link-sm link-light" href="/service-hosting">
                                    {t('layout.footer.services.hosting')}
                                </a>
                            </li>

                            <li>
                                <a className="link-sm link-light" href="/service-vps">
                                    {t('layout.footer.services.vps')}
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Redes Sociales */}
                    <div className="col-sm mb-7 mb-sm-0">
                        <h5 className="text-white mb-3">{t('layout.footer.follow_us')}</h5>
                        <div id="fb-root"></div>
                        <div className="fb-page"
                             data-href="https://www.facebook.com/WecodexOficial"
                             data-width="255"
                             data-height="700"
                             data-small-header="false"
                             data-adapt-container-width="false"
                             data-hide-cover="false"
                             data-show-facepile="true">
                            <blockquote cite="https://www.facebook.com/WecodexOficial" className="fb-xfbml-parse-ignore">
                                <a href="https://www.facebook.com/WecodexOficial">Wecodex</a>
                            </blockquote>
                        </div>
                    </div>
                </div>

                {/* Separador */}
                <div className="border-top border-white-10 my-7"></div>

                {/* Footer Bottom */}
                <div className="row mb-7">
                    {/* Enlaces de Política */}
                    <div className="col-sm mb-3 mb-sm-0">
                        <ul className="list-inline list-separator list-separator-light mb-0">
                            <li className="list-inline-item">
                                <a className="link-sm link-light" href="/privacy-policy">
                                    {t('layout.footer.legal.privacy')}
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="link-sm link-light" href="/terms-and-conditions">
                                    {t('layout.footer.legal.terms')}
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="link-sm link-light" href="#">
                                    {t('layout.footer.legal.sitemap')}
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Redes Sociales y Selector de Idioma */}
                    <div className="col-sm-auto">
                        <ul className="list-inline mb-0">
                            {socialLinks.map((link, index) => (
                                <li key={index} className="list-inline-item">
                                    <a className="btn btn-soft-light btn-xs btn-icon"
                                       href={link.url}
                                       aria-label={link.ariaLabel}>
                                        <i className={link.icon}></i>
                                    </a>
                                </li>
                            ))}
                            <li className="list-inline-item">
                                <LanguageSelector />
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Copyright */}
                <div className="w-md-85 text-lg-center mx-lg-auto">
                    <p className="text-white-50 small">
                        &copy; Wecodex Solutions | {t('layout.footer.copyright')}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;