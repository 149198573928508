import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

const TypedText = ({
                       strings = [],
                       typeSpeed = 60,
                       loop = true,
                       backSpeed = 25,
                       backDelay = 1500,
                       className = "text-primary fw-semibold",
                       placeholder = "..." // Placeholder por defecto
                   }) => {
    const elementRef = useRef(null);

    useEffect(() => {
        if (!elementRef.current) return;

        // Procesar y validar strings
        const processedStrings = Array.isArray(strings)
            ? strings
                .filter(str => str != null) // Filtrar undefined y null
                .map(str => String(str).trim()) // Convertir a string y remover espacios
                .filter(str => str.length > 0) // Filtrar strings vacíos
            : [String(strings).trim()]; // Si no es array, convertir a array con un solo elemento

        // Si no hay strings válidos, usar placeholder
        const finalStrings = processedStrings.length > 0 ? processedStrings : [placeholder];

        const options = {
            strings: finalStrings,
            typeSpeed,
            loop,
            backSpeed,
            backDelay,
            cursorChar: '|',
            showCursor: true,
        };

        const typed = new Typed(elementRef.current, options);

        return () => {
            typed?.destroy();
        };
    }, [strings, typeSpeed, loop, backSpeed, backDelay, placeholder]);

    return (
        <span className={className}>
            <span ref={elementRef}>{placeholder}</span>
            <style>{`
                .typed-cursor {
                    font-size: inherit;
                    color: inherit;
                }
            `}</style>
        </span>
    );
};

export default TypedText;