import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Head from "@/components/layout/Head";
import Shuffle from 'shufflejs';
import api from "@services/api";
import NewsletterSubscribe from "@components/widgets/NewsletterSubscribe";
import TypedText from "@components/elements/TypedText";

const Search = () => {
    const {t, i18n} = useTranslation();
    const currentLocale = i18n.language;
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [categories, setCategories] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const shuffleInstance = useRef(null);
    const filterOptionsRef = useRef(null);
    const [heroSlides, setHeroSlides] = useState([
        {
            title: t('pages.blog.hero.slides.1.title'),
            subtitle: t('pages.blog.hero.slides.1.subtitle'),
            image: '/assets/img/banners/technology-3.jpg',
            imagePosition: 'center',
            brightness: 0.7
        },
        {
            title: t('pages.blog.hero.slides.2.title'),
            subtitle: t('pages.blog.hero.slides.2.subtitle'),
            image: '/assets/img/banners/technology-2.jpg',
            imagePosition: 'center',
            brightness: 0.6
        },
        {
            title: t('pages.blog.hero.slides.3.title'),
            subtitle: t('pages.blog.hero.slides.3.subtitle'),
            image: '/assets/img/banners/technology-3.jpg',
            imagePosition: 'center',
            brightness: 0.7
        },
        {
            title: t('pages.blog.hero.slides.4.title'),
            subtitle: t('pages.blog.hero.4.subtitle'),
            image: '/assets/img/banners/technology-4.jpg',
            imagePosition: 'top',
            brightness: 0.5
        },
        {
            title: t('pages.blog.hero.slides.5.title'),
            subtitle: t('pages.blog.hero.slides.5.subtitle'),
            image: '/assets/img/banners/technology-5.jpg',
            imagePosition: 'center',
            brightness: 0.6
        },
        {
            title: t('pages.blog.hero.slides.6.title'),
            subtitle: t('pages.blog.hero.slides.subtitle'),
            image: '/assets/img/banners/technology-6.jpg',
            imagePosition: 'center',
            brightness: 0.7
        },
        {
            title: t('pages.blog.hero.slides.title'),
            subtitle: t('pages.blog.hero.slides.subtitle'),
            image: '/assets/img/banners/technology-7.jpg',
            imagePosition: 'center',
            brightness: 0.6
        },
        {
            title: t('pages.blog.hero.slides.8.title'),
            subtitle: t('pages.blog.hero.slides.subtitle'),
            image: '/assets/img/banners/technology-8.jpg',
            imagePosition: 'center',
            brightness: 0.7
        },
        {
            title: t('pages.blog.hero.slides.9.title'),
            subtitle: t('pages.blog.hero.slides.9.subtitle'),
            image: '/assets/img/banners/technology-5.jpg',
            imagePosition: 'center',
            brightness: 0.6
        },
        {
            title: t('pages.blog.hero.slides.10.title'),
            subtitle: t('pages.blog.hero.slides.10.subtitle'),
            image: '/assets/img/banners/technology-8.jpg',
            imagePosition: 'center',
            brightness: 0.7
        }
    ]);

    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 5
    });


    const getTranslation = (item, field) => {

        if (!item?.translations) return '';
        const translation = item.translations.find(t => t.locale === currentLocale) || item.translations[0];
        return translation?.[field] || '';
    };


    // Handlers
    const handleFilterClick = (evt) => {
        evt.preventDefault();
        const categoryId = evt.currentTarget.dataset.group;
        setSelectedCategory(categoryId);
        getPosts(1, {
            category: categoryId === 'all' ? undefined : categoryId
        });
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.lastPage) {
            getPosts(newPage);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        getPosts(1);
    };

    const renderPaginationItems = () => {
        const items = [];
        const maxVisible = 5;
        let startPage = Math.max(1, pagination.currentPage - 2);
        let endPage = Math.min(pagination.lastPage, startPage + maxVisible - 1);

        if (endPage - startPage + 1 < maxVisible) {
            startPage = Math.max(1, endPage - maxVisible + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <li key={i} className={`page-item ${pagination.currentPage === i ? 'active' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                        {pagination.currentPage === i && (
                            <span className="visually-hidden">{t('pages.blog.pagination.current')}</span>
                        )}
                    </button>
                </li>
            );
        }

        return items;
    };

    // API calls
    const getPosts = async (page = 1, filters = {}) => {
        try {
            setIsLoading(true);
            const response = await api.getAwaiting('blog/posts', {
                page,
                per_page: pagination.perPage,
                category: selectedCategory !== 'all' ? selectedCategory : undefined,
                with:['categories'],
                search: searchTerm,
                ...filters
            }, true);

            if (response && response.data) {
                setPosts(response.data);
                setPagination({
                    currentPage: response.current_page || 1,
                    lastPage: response.last_page || 1,
                    total: response.total || 0,
                    perPage: response.per_page || 5
                });
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
            setPosts([]);
        } finally {
            setIsLoading(false);
        }
    };

    const getCategories = async () => {
        try {
            const response = await api.getAwaiting('catalogs/categories', {
                filter: 'all',
            }, true);
            if (response?.data) {
                setCategories(response.data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategories([]);
        }
    };
    const updateHeroSlides = () => {
        const sliderPosts = posts.filter(post => post.is_slider === 1);
        const newSlides = sliderPosts.map(post => {

               return ({
                    title: getTranslation(post, 'title'),
                    subtitle: getTranslation(post, 'description_short'),
                    image: post.slider_image,
                    slug: getTranslation(post,'slug'),
                    slugCategory: getTranslation(post.default_category,'slug'),
                    imagePosition: 'center',
                    brightness: 0.6
                })
            }
        );

        setHeroSlides(newSlides);
    };

    useEffect(() => {
        updateHeroSlides();
    }, [posts]);


    useEffect(() => {
        getCategories();
        getPosts();

    }, []);

    useEffect(() => {
        if (heroSlides.length === 0) return;

        const getNextSlide = (currentSlide) => {
            // Avanza al siguiente slide o reinicia al primer slide
            return (currentSlide + 1) % heroSlides.length;
        };

        const timer = setInterval(() => {
            setCurrentSlide(prevSlide => getNextSlide(prevSlide));
        }, 8000);

        return () => clearInterval(timer);
    }, [heroSlides.length, posts, currentLocale]);


    // Initialize Shuffle.js
    useEffect(() => {
        let shuffleInstance = null;

        const initShuffle = () => {
            const gridElement = document.querySelector('.js-shuffle');
            if (gridElement && !isLoading && posts.length > 0) {
                try {
                    shuffleInstance = new Shuffle(gridElement, {
                        itemSelector: '.js-shuffle-item',
                        sizer: null
                    });
                } catch (error) {
                    console.error('Error initializing Shuffle:', error);
                }
            }
        };

        initShuffle();

        return () => {
            if (shuffleInstance) {
                try {
                    shuffleInstance.destroy();
                } catch (error) {
                    console.error('Error destroying Shuffle:', error);
                }
            }
        };
    }, [isLoading, posts]);


    return (
        <main id="content" role="main">
            <Head pagePath="blog"/>

            {/* Hero Section with Slider and Search */}
            <div className="position-relative">
                <div
                    className="position-absolute w-100 h-100"
                    style={{
                        background: `url("${heroSlides[currentSlide]?.image}") ${heroSlides[currentSlide]?.imagePosition}/cover no-repeat`,
                        filter: `brightness(${heroSlides[currentSlide]?.brightness})`,
                        transition: 'all 0.5s ease-in-out',
                        zIndex: 0
                    }}
                />


                {/* Gradient Overlay */}
                <div
                    className="position-absolute w-100 h-100"
                    style={{
                        background: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.6) 100%)',
                        zIndex: 1
                    }}
                />

                <div className="container position-relative content-space-t-lg-5 content-space-b-2" style={{zIndex: 2}}>
                    <div className="row justify-content-center">
                        <div className="text-center mb-5">
                            <Link
                                to={'/blog/' + heroSlides[currentSlide]?.slugCategory + '/' + heroSlides[currentSlide]?.slug}
                                className="btn-link small">
                                <h2 className="display-4 text-white mb-4">

                                    <TypedText
                                        strings={[heroSlides[currentSlide]?.title]}
                                        className="display-4 text-white"
                                        typeSpeed={40}
                                        backSpeed={50}
                                        loop={false}
                                    />
                                </h2>
                            </Link>
                            <p className="lead text-white mb-4">
                                {heroSlides[currentSlide]?.subtitle}
                            </p>


                        </div>


                        <div className="col-lg-8">
                            <div className="card bg-soft-dark shadow-lg ">
                                <div className="p-3 pb-0 pt-2">
                                    <form onSubmit={handleSearch}>
                                        <div className="input-group input-group-lg">
                                            <input
                                                type="text"
                                                className="form-control border-2"
                                                placeholder={t('pages.blog.hero.search.placeholder')}
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <button className="btn btn-primary m-0" type="submit">
                                                <i className="bi-search me-2"></i>
                                                {t('pages.blog.hero.search.button')}
                                            </button>
                                        </div>

                                        <div className="d-flex gap-4 pt-2 ">
                                            <span>Buscar por:</span>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="searchTitle"/>
                                                <label className="form-check-label text-white" htmlFor="searchTitle">
                                                    {t('pages.blog.hero.search.filters.title')}
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="searchContent"/>
                                                <label className="form-check-label text-white" htmlFor="searchContent">
                                                    {t('pages.blog.hero.search.filters.content')}
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="searchTags"/>
                                                <label className="form-check-label text-white" htmlFor="searchTags">
                                                    {t('pages.blog.hero.search.filters.tags')}
                                                </label>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Slider Navigation Dots */}
                <div className="position-absolute bottom-0 start-50 translate-middle-x mb-3" style={{zIndex: 2}}>
                    <div className="d-flex gap-2">
                        {heroSlides.map((_, index) => (
                            <button
                                key={index}
                                className={`btn btn-xs ${currentSlide === index ? 'btn-white' : 'btn-white-soft'}`}
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    padding: 0,
                                    borderRadius: '50%'
                                }}
                                onClick={() => setCurrentSlide(index)}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* Featured Posts */}
            <div className="container py-5">
                <div className="text-center mb-5">
                    <h2>{t('pages.blog.featured.title')}</h2>
                    <p className="lead">{t('pages.blog.featured.subtitle')}</p>
                </div>

                <div className="row g-4">
                    {posts.filter(post => post.is_top).slice(0, 4).map((post, index) => (
                        <div key={post.id} className={`col-md-${index === 0 ? '8' : '4'}`}>
                            <div className="card h-100 shadow-sm hover-lift">
                                <img
                                    src={post.preview_image}
                                    className="card-img-top"
                                    alt={getTranslation(post, 'title')}
                                    style={{height: '300px', objectFit: 'cover'}}
                                />
                                <div className="card-body">
                                    <h3 className="h5">{getTranslation(post, 'title')}</h3>
                                    <p className="card-text">{getTranslation(post, 'description_short')}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Categories and Posts Grid */}
            <div className="container py-5">
                {/* Categories Nav */}
                <div className="text-center">
                    <ul className="nav nav-segment nav-pills mb-7" role="tablist">
                        <li className="nav-item">
                            <button
                                className={`nav-link ${selectedCategory === 'all' ? 'active' : ''}`}
                                onClick={handleFilterClick}
                                data-group="all"
                                type="button"
                            >
                                {t('pages.blog.categories.all')}
                            </button>
                        </li>
                        {categories.map(category => (
                            <li className="nav-item" key={category.id}>
                                <button
                                    className={`nav-link ${selectedCategory === category.id.toString() ? 'active' : ''}`}
                                    onClick={handleFilterClick}
                                    data-group={category.id}
                                    type="button"
                                >
                                    {getTranslation(category, 'name')}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Posts Grid */}
                <div className="tab-content">
                    <div className="js-shuffle row g-4">
                        {isLoading ? (
                            <div className="col-12 text-center py-4">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : posts.length > 0 ? (
                            posts.map(post => (
                                <div
                                    className="js-shuffle-item col-md-4"
                                    key={post.id}
                                    data-groups={`[${post.categories?.data.map(c => `"${c.id}"`).join(',')}]`}
                                >
                                    <div className="card h-100 shadow-sm hover-lift">
                                        {post.is_top && (
                                            <div className="position-absolute top-0 start-0 p-3">
                                                <span className="badge bg-primary">
                                                    <i className="bi-star-fill me-1"></i>
                                                    {t('pages.blog.post.featured')}
                                                </span>
                                            </div>
                                        )}

                                        <img
                                            className="card-img-top"
                                            src={post.preview_image}
                                            alt={getTranslation(post, 'title')}
                                            style={{height: '200px', objectFit: 'cover'}}
                                        />

                                        <div className="card-body">
                                            <div className="mb-3">
                                                {post.categories?.data?.map(category => (
                                                    <span key={category.id} className="badge bg-secondary me-1">
                                                        <i className={`${category.icon} me-1`}></i>
                                                        {getTranslation(category, 'name')}
                                                    </span>
                                                ))}
                                            </div>

                                            <h3 className="h5">
                                                <Link to={`/blog/${post.slug}`}
                                                      className="text-decoration-none text-dark">
                                                    {getTranslation(post, 'title')}
                                                </Link>
                                            </h3>

                                            <p className="card-text">
                                                {getTranslation(post, 'description_short')}
                                            </p>
                                        </div>

                                        <div className="card-footer bg-white">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex gap-2">
                                                    {post.share_facebook && (
                                                        <i className="bi-facebook text-primary cursor-pointer"
                                                           title={`${t('pages.blog.post.shareOn')} Facebook`}></i>
                                                    )}
                                                    {post.share_twitter && (
                                                        <i className="bi-twitter text-info cursor-pointer"
                                                           title={`${t('pages.blog.post.shareOn')} Twitter`}></i>
                                                    )}
                                                    {post.share_instagram && (
                                                        <i className="bi-instagram text-danger cursor-pointer"
                                                           title={`${t('pages.blog.post.shareOn')} Instagram`}></i>
                                                    )}
                                                    {post.share_linkedin && (
                                                        <i className="bi-linkedin text-primary cursor-pointer"
                                                           title={`${t('pages.blog.post.shareOn')} LinkedIn`}></i>
                                                    )}
                                                </div>
                                                <small className="text-muted">
                                                    {new Date(post.created_at).toLocaleDateString(currentLocale)}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-12">
                                <div className="text-center py-5">
                                    <div className="mb-4">
                                        <img
                                            src="/assets/svg/illustrations/oc-empty.svg"
                                            alt="Empty state"
                                            className="mb-4"
                                            style={{maxWidth: '15rem'}}
                                        />
                                        <h3>{t('pages.blog.categories.empty.title')}</h3>
                                        <p className="text-muted">
                                            {t('pages.blog.categories.empty.message')}
                                        </p>
                                    </div>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setSearchTerm('');
                                            setSelectedCategory('all');
                                            getPosts(1);
                                        }}
                                    >
                                        {t('pages.blog.categories.empty.button')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Pagination */}
                {posts.length > 0 && pagination.lastPage > 1 && (
                    <nav className="mt-5" aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                            <li className={`page-item ${pagination.currentPage === 1 ? 'disabled' : ''}`}>
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                                    disabled={pagination.currentPage === 1}
                                >
                                    <i className="bi-chevron-left small"></i>
                                    <span className="ms-1">{t('pages.blog.pagination.previous')}</span>
                                </button>
                            </li>

                            {renderPaginationItems()}

                            <li className={`page-item ${pagination.currentPage === pagination.lastPage ? 'disabled' : ''}`}>
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                                    disabled={pagination.currentPage === pagination.lastPage}
                                >
                                    <span className="me-1">{t('pages.blog.pagination.next')}</span>
                                    <i className="bi-chevron-right small"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}

                <div className="divider divider-center pt-4"/>

                {/* Newsletter Section */}
                <div className="pb-4 content-space-t-2">
                    <NewsletterSubscribe/>
                </div>
            </div>
        </main>
    );
};

export default Search;