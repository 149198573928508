import React from 'react';
import {useTranslation} from 'react-i18next';
import Head from "../components/layout/Head";

const HostingCard = ({plan, isPopular}) => {
    const {t} = useTranslation();

    return (
        <div className={`card h-100 ${isPopular ? 'shadow-lg' : 'shadow-sm'}`}>
            {isPopular && (
                <div className="position-absolute top-0 start-50 translate-middle">
                    <span className="badge bg-primary rounded-pill py-2 px-3">
                        {t('pages.hosting.plans.business.badge')}
                    </span>
                </div>
            )}

            <div className="card-body p-5">
                {/* Header */}
                <div className="mb-5">
                    <h3 className="card-title">{plan.name}</h3>
                    <p className="card-text text-muted">{plan.description}</p>
                </div>

                {/* Pricing */}
                <div className="mb-5">
                    <div className="d-flex align-items-center mb-2">
                        <span className="display-7 fw-bold">{plan.price.currency}</span>
                        <span className="display-6 fw-bold">{plan.price.amount}</span>
                        <span className="text-muted fs-5">{plan.price.period}</span>
                    </div>
                </div>

                {/* Features */}
                <ul className="list-unstyled mb-5">
                    {Object.entries(plan.features).map(([key, value]) => (
                        <li key={key} className="mb-3 d-flex align-items-center">
                            <i className="bi-check-circle-fill text-success me-2"></i>
                            <span>{value}</span>
                        </li>
                    ))}
                </ul>


                {/* Action Button */}
                <a href={plan.reference_url} className={`btn w-100 ${isPopular ? 'btn-primary' : 'btn-outline-primary'}`}>
                    {t('pages.hosting.pricing.shareButton')}
                </a>
            </div>
        </div>
    );
};

const Hosting = () => {
    const {t} = useTranslation();

    const plans = [
        {
            name: "Premium Web Hosting",
            description: "Todo lo que necesitas para crear tu sitio web",
            price: {
                amount: "59.99",
                currency: "MX$",
                period: "/mes"
            },
            features: {
                websites: "100 sitios web",
                visits: "~25,000 Visitas Mensuales",
                storage: "100 GB de almacenamiento NVMe",
                files: "400,000 archivos y directorios (inodos)"
            },
            reference_url:"https://cart.hostinger.com/pay/2f1bded4-77a4-4c03-88c6-432d12614450?_ga=GA1.3.942352702.1711283207",
        },
        {
            name: "Business Web Hosting",
            description: "Sube de nivel con más potencia y funciones mejoradas.",
            price: {
                amount: "84.99",
                currency: "MX$",
                period: "/mes"
            },
            features: {
                websites: "100 sitios web",
                visits: "~100,000 Visitas Mensuales",
                storage: "200 GB de almacenamiento NVMe",
                files: "600,000 archivos y directorios (inodos)"
            },
            reference_url:"https://cart.hostinger.com/pay/5d952406-c77f-4352-a15a-5b1512aad6f4?_ga=GA1.3.942352702.1711283207",
        },
        {
            name: "Cloud Startup",
            description: "Optimizado para empresas y tiendas online",
            price: {
                amount: "176.99",
                currency: "MX$",
                period: "/mes"
            },
            features: {
                websites: "300 sitios web",
                visits: "~200,000 Visitas Mensuales",
                storage: "200 GB de almacenamiento NVMe",
                files: "2,000,000 archivos y directorios (inodos)"
            },
            reference_url:"https://cart.hostinger.com/pay/5d952406-c77f-4352-a15a-5b1512aad6f4?_ga=GA1.3.942352702.1711283207",
        }
    ];

    return (

        <>

            <Head pagePath="hosting" />

            <div className="gradient-y-sm-primary position-relative zi-2 overflow-hidden">
                <div className="container position-relative content-space-t-4 content-space-t-lg-4 content-space-b-1">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-1 mb-md-1">
                        <h3 className="display-6 mb-3">{t('pages.hosting.hero.title', 'Hosting')}</h3>
                        <p >{t('pages.hosting.hero.description')}</p>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="row g-4">
                    {plans.map((plan, index) => (
                        <div key={index} className="col-md-4">
                            <HostingCard
                                plan={plan}
                                isPopular={index === 1}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Hosting;