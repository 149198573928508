import React, { useState, useEffect } from 'react';

const VideoThumbnail = ({ videoUrl, onLoad }) => {
    // Extraer el ID del video de la URL de YouTube
    const getVideoId = (url) => {
        const match = url.match(/embed\/([^?]+)/);
        return match ? match[1] : '';
    };

    const videoId = getVideoId(videoUrl);
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

    return (
        <div className="position-relative w-100 h-100 cursor-pointer">
            <img
                src={thumbnailUrl}
                alt="Video thumbnail"
                className="w-100 h-100 object-fit-cover rounded"
                onLoad={onLoad}
            />
            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-25 opacity-0 hover-opacity-100">
                <i className="bi bi-play-circle-fill text-white fs-1"></i>
            </div>
        </div>
    );
};

const VideoGrid = ({ videoUrls }) => {
    const [loadedVideos, setLoadedVideos] = useState([]);
    const [observer, setObserver] = useState(null);

    useEffect(() => {
        // Crear el Intersection Observer
        const obs = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const videoIndex = parseInt(entry.target.dataset.index);
                        setLoadedVideos(prev => [...prev, videoIndex]);
                        obs.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        setObserver(obs);

        return () => obs.disconnect();
    }, []);

    const handleVideoContainerRef = (element, index) => {
        if (element && observer) {
            observer.observe(element);
            element.dataset.index = index;
        }
    };

    const handleThumbnailLoad = (index) => {
        // Opcional: Puedes implementar alguna lógica adicional cuando la thumbnail se carga
    };

    return (
        <div className="container my-5">
            <div className="row g-4">
                {videoUrls.map((url, index) => (
                    <div
                        key={index}
                        className="col-12 col-md-6 col-lg-4"
                        ref={(el) => handleVideoContainerRef(el, index)}
                    >
                        <div className="ratio ratio-16x9 rounded overflow-hidden shadow">
                            {loadedVideos.includes(index) ? (
                                <iframe
                                    src={`${url}?autoplay=0&rel=0`}
                                    title={`YouTube video player ${index + 1}`}
                                    className="w-100 h-100"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            ) : (
                                <VideoThumbnail
                                    videoUrl={url}
                                    onLoad={() => handleThumbnailLoad(index)}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VideoGrid;